import React, {
  FC,
  createContext,
  useContext,
  ReactNode,
  useReducer,
} from "react";
import { CscPaymentsJSClient } from "@ftdr/cscpayments-js-client";
import { useValidationRoutingNumber, ValidationRoutingNumber } from "./hooks";
import {
  initialState,
  cscPaymentClientSlice,
  CscPaymentClientState,
} from "./state";

export type CscPaymentsClientContextProps = {
  client?: CscPaymentsJSClient | undefined;
  children: ReactNode;
};

export type CscPaymentsClientContextValue = {
  client?: CscPaymentsJSClient;
  validationRoutingNumber: ValidationRoutingNumber;
  state: CscPaymentClientState;
};

export const CscPaymentsContextInitialState: CscPaymentsClientContextValue = {
  validationRoutingNumber: () => Promise.resolve(undefined),
  state: initialState,
};

const CscPaymentsContext = createContext<CscPaymentsClientContextValue>(
  CscPaymentsContextInitialState
);

export const {
  Provider: CscPaymentsClientContextProvider,
} = CscPaymentsContext;

export const CscPaymentsClientContext: FC<CscPaymentsClientContextProps> = ({
  client,
  children,
}) => {
  const [state, dispatch] = useReducer(
    cscPaymentClientSlice.reducer,
    initialState
  );

  const { validationRoutingNumber } = useValidationRoutingNumber(
    dispatch,
    client
  );

  return (
    <CscPaymentsClientContextProvider
      value={{ validationRoutingNumber, state }}
    >
      {children}
    </CscPaymentsClientContextProvider>
  );
};

export const useCscPaymentClientContext = (): CscPaymentsClientContextValue =>
  useContext<CscPaymentsClientContextValue>(CscPaymentsContext);
