import React, { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { useCscPaymentClientContext } from "../../../../../contexts/csc-payments-client-context";
import {
  AgregatedProcessPaymentResponse,
  SuccessTypeValue,
  PaymentProcessorClientInternalRef,
  FailureTypeValue,
  IFormInput,
  ProcessPaymentACHRequestData,
} from "../../../types";
import { AchBankProcessorProps } from "../../bank-proccessor";
import {
  AchAccountNumberInput,
  AchRoutingNumberInput,
  AchAccountNumberConfirmationInput,
} from "./components";
import { ProgressIndicator } from "@ftdr/blueprint-components-react";
import { usePaymentContext } from "../../../../../contexts/payment-context";

export const achError: Record<string, string> = {
  validate: "ACH_ROUTING_NUMBER_ERROR",
  required: "ACH_REQUIRED_FILED_ERROR",
  confirm: "ACH_CONFIRM_FILED_ERROR",
  minLength: "ACH_ROUTING_NUMBER_LENGTH_ERROR",
  maxLength: "ACH_ROUTING_NUMBER_LENGTH_ERROR",
};

export type OnSubmitProperties = {
  hostSubmitCallback: (result: AgregatedProcessPaymentResponse) => void;
};

export type OnSubmit = ({
  hostSubmitCallback,
}: OnSubmitProperties) => SubmitHandler<IFormInput>;

export const AchForm = forwardRef<
  Pick<
    PaymentProcessorClientInternalRef<ProcessPaymentACHRequestData>,
    "processPayment"
  >,
  AchBankProcessorProps
>((_props, ref) => {
  const { state } = useCscPaymentClientContext();
  const { onSuccess, onFailure } = usePaymentContext();

  const methods = useForm<IFormInput>({
    reValidateMode: "onBlur",
    defaultValues: {
      bankRoutingNumber: "",
      bankAccountNumber: "",
      bankAccountNumberConfirmation: "",
    },
  });
  const { handleSubmit, reset } = methods;

  const onSubmit: OnSubmit = ({
    hostSubmitCallback,
  }: OnSubmitProperties) => async (formData) => {
    const { bankRoutingNumber, bankAccountNumber } = formData;
    const resultObject = {
      type: SuccessTypeValue.ACH_SUCCESS,
      achSuccessResponse: { ...{ bankRoutingNumber, bankAccountNumber } },
    };

    reset({
      bankRoutingNumber: "",
      bankAccountNumber: "",
      bankAccountNumberConfirmation: "",
    });

    onSuccess(resultObject);
    return hostSubmitCallback({
      ach: {
        achSuccessResponse: {
          ...{ bankRoutingNumber, bankAccountNumber },
        },
        type: SuccessTypeValue.ACH_SUCCESS,
      },
    });
  };

  useImperativeHandle(ref, () => ({
    processPayment: async () => {
      return new Promise<AgregatedProcessPaymentResponse>(
        (resolveAchPayment) => {
          handleSubmit(
            onSubmit({ hostSubmitCallback: resolveAchPayment }),
            (err) => {
              onFailure({
                type: FailureTypeValue.NOT_VALID_ACH_FORM,
                achFormErrors: err,
              });
              resolveAchPayment({
                ach: {
                  type: FailureTypeValue.NOT_VALID_ACH_FORM,
                  achFormErrors: err,
                },
              });
            }
          )();
        }
      );
    },
  }));

  return (
    <div className="flex flex-col relative">
      <FormProvider {...methods}>
        <form data-testid={"Payment__Type__Ach_Form"}>
          <AchRoutingNumberInput />
          <AchAccountNumberInput />
          <AchAccountNumberConfirmationInput />
        </form>
      </FormProvider>
      {state.isLoading && (
        <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
          <ProgressIndicator size="medium" color="primary" />
        </div>
      )}
    </div>
  );
});

AchForm.displayName = "AchForm";
