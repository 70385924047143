import { useAppContext } from "@ftdr/blueprint-components-react";
import {
  payment3_commonpb,
  payment3_paymentmethodpb,
} from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { Dispatch, SetStateAction, useCallback } from "react";
import { usePaymentContext } from "../../../../../../contexts/payment-context";
import {
  CreatePaymentMethodResultType,
  useStripePaymentsClientContext,
  useStripePaymentsStateContext,
} from "../../../../../../contexts/stripe-payments-client-context";
import {
  ProcessPaymentStripeRequestData,
  FailureTypeValue,
  OnResponseTypeValue,
  SuccessTypeValue,
  AgregatedProcessPaymentResponse,
} from "../../../../types";

import { setError } from "../../../../../../contexts/stripe-payments-client-context/state";

export type UseRetrieveSetupIntentProps = {
  setError: Dispatch<SetStateAction<Partial<StripeError>>>;
};

export type UseRetrieveSetupIntentReturnType = {
  handleSetupIntent: (
    clientSecret: string,
    requestData?: ProcessPaymentStripeRequestData
  ) => Promise<AgregatedProcessPaymentResponse>;
};

export const useRetrieveSetupIntent = (): UseRetrieveSetupIntentReturnType => {
  const { createPaymentMethod, dispatch } = useStripePaymentsClientContext();
  const { state } = useStripePaymentsStateContext();
  const { onFailure, onResponse } = usePaymentContext();
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const stripe = useStripe();

  const returnResultObject = useCallback(
    (
      resultObject: CreatePaymentMethodResultType
    ): AgregatedProcessPaymentResponse => {
      if (resultObject?.error) {
        return { stripe: { ...resultObject.error } };
      }

      if (resultObject?.result?.paymentMethod?.details?.cc?.brand) {
        const brandNumber = resultObject.result.paymentMethod.details.cc.brand;
        resultObject.result.paymentMethod.details.cc.brandName =
          payment3_paymentmethodpb.CreditCardBrand[brandNumber];
      }

      return {
        stripe: {
          type: SuccessTypeValue.STRIPE_SUCCESS,
          stripeSuccessResponse: resultObject?.result,
        },
      };
    },
    []
  );

  const handleSetupIntent = useCallback(
    async (
      clientSecret: string,
      requestData?: ProcessPaymentStripeRequestData
    ): Promise<AgregatedProcessPaymentResponse> => {
      try {
        const setupIntentResult = await stripe?.retrieveSetupIntent(
          clientSecret
        );
        if (setupIntentResult?.error) {
          dispatch(setError({ message: setupIntentResult.error.message }));
          const errorResult = {
            type: FailureTypeValue.STRIPE_FAILURE,
            stripeFailureResponse: {
              message: setupIntentResult.error.message,
              rawError: setupIntentResult,
            },
          };
          onFailure(errorResult);
          return { stripe: { ...errorResult } };
        } else {
          onResponse({
            type: OnResponseTypeValue.SETUP_CARD_SUCCESS,
            setupCardSuccessResponse: setupIntentResult,
          });
          const result = await createPaymentMethod({
            registrationID: state?.clientSetup?.registration?.registrationID,
            paymentMethod: {
              owner: requestData?.stripe?.owner,
              includedInWallet: requestData?.stripe?.includedInWallet,
              processor: {
                processor: payment3_commonpb.PaymentProcessor.Stripe,
                stripe: {
                  paymentMethodID:
                    setupIntentResult?.setupIntent?.payment_method,
                },
              },
              details: {
                type: payment3_paymentmethodpb.PaymentMethodType.CreditCard,
              },
            },
          });
          return returnResultObject(result);
        }
      } catch (ex) {
        dispatch(setError({ message: localizedText(`STRIPE_INTENT_ERROR`) }));
        const errorResult = {
          type: FailureTypeValue.STRIPE_FAILURE,
          stripeFailureResponse: {
            message: "STRIPE_INTENT_ERROR",
            rawError: ex,
          },
        };
        onFailure(errorResult);
        return { stripe: { ...errorResult } };
      }
    },
    [
      stripe,
      onFailure,
      onResponse,
      createPaymentMethod,
      localizedText,
      returnResultObject,
      state?.clientSetup?.registration?.registrationID,
      dispatch,
    ]
  );
  return { handleSetupIntent };
};
