import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { PaymentProcessorResponse } from "../../components/payment-processors/types";
import {
  initialState,
  paymentSlice,
  PaymentState,
  setInternalContextState,
} from "./state";
import { PaymentComponentState } from "../../components";

import {
  DefaultSetup,
  StripeSetupType,
} from "../../components/payment-processors/stripe-card-processor/components/stripe-elements/consts";

import {
  AvailablePaymentMethods,
  PaymentMethods,
  defaultAvailablePaymentMethods,
} from "../../const";

export type PaymentContextValue = PaymentProcessorResponse &
  Pick<
    PaymentContextValueProps,
    "applicationName" | "stripeSetup" | "availablePaymentMethods"
  > & {
    state: PaymentState;
    dispatch: Dispatch<AnyAction>;
  };

export type PaymentContextValueProps = {
  // -------------------- Component State Related Props --------------------
  /**
   * When using this component in the un-controlled mode, this prop can be used
   * to set the initial state for this component.
   */
  initialState?: PaymentComponentState;

  /**
   * When using this component in the controlled mode, this prop can be used
   * to set the state for this component.
   */
  state?: PaymentComponentState;

  /**
   * When using this component in the controlled mode, this prop can be used
   * to attach an event handler to this component, that will be called whenever
   * the internal state is updated. The updated state object is passed as an
   * argument to the handler function.
   */
  onStateChange?: (state: PaymentComponentState | undefined) => void;
  /**
   * Application name
   */
  applicationName: string;
  /**
   * Payments input layout settings
   */
  stripeSetup?: StripeSetupType;

  defaultOpenPaymentMethod?: PaymentMethods;
  /**
   * When only one payment method (credit card or bank account) needs to be displayed
   */
  availablePaymentMethods?: AvailablePaymentMethods;
};

export type PaymentContextProps = {
  value: PaymentContextValueProps & PaymentProcessorResponse;
  children: ReactNode;
  providerState?: PaymentContextValue;
};

export const PaymentContextInitialState: PaymentContextValue = {
  applicationName: "",
  onSuccess: () => undefined,
  onFailure: () => undefined,
  onResponse: () => undefined,
  state: initialState,
  dispatch: () => undefined,
  stripeSetup: DefaultSetup,
  availablePaymentMethods: defaultAvailablePaymentMethods,
};

const PaymentContext = createContext<PaymentContextValue>(
  PaymentContextInitialState
);

export const { Provider: PaymentContextProvider } = PaymentContext;

export const PaymentsContext: FC<PaymentContextProps> = ({
  value,
  children,
  providerState,
}) => {
  const {
    applicationName,
    onSuccess,
    onFailure,
    onResponse,
    onStateChange,
    initialState: propsInitialState,
    state: propsState,
    stripeSetup = DefaultSetup,
    defaultOpenPaymentMethod,
    availablePaymentMethods,
  } = value;
  const [state, dispatch] = useReducer(paymentSlice.reducer, {
    ...initialState,
    internalState: propsInitialState || initialState.internalState,
  });

  useEffect(() => {
    if (propsState) {
      state.internalState !== propsState &&
        dispatch(setInternalContextState(propsState));
    }
  }, [propsState, state]);

  useEffect(() => {
    onStateChange?.(state.internalState);
  }, [state.internalState, onStateChange]);

  useEffect(() => {
    if (defaultOpenPaymentMethod) {
      dispatch(
        setInternalContextState({
          ...{ activePaymentMethod: defaultOpenPaymentMethod },
        })
      );
    }
  }, [defaultOpenPaymentMethod]);

  return (
    <PaymentContextProvider
      value={
        providerState
          ? providerState
          : {
              applicationName,
              state,
              dispatch,
              onSuccess,
              onFailure,
              onResponse,
              stripeSetup,
              availablePaymentMethods,
            }
      }
    >
      {children}
    </PaymentContextProvider>
  );
};

export const usePaymentContext = (): PaymentContextValue =>
  useContext<PaymentContextValue>(PaymentContext);
