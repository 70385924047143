import { Elements } from "@stripe/react-stripe-js";
import {
  loadStripe,
  Stripe,
  PaymentMethodCreateParams,
} from "@stripe/stripe-js";
import React, { forwardRef, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStripePaymentsStateContext } from "../../../../../contexts/stripe-payments-client-context";
import {
  PaymentProcessorClientInternalRef,
  ProcessPaymentStripeRequestData,
} from "../../../types";
import { StripeElements } from "../stripe-elements";
import { DefaultSetup } from "../stripe-elements/consts";
import { usePaymentContext } from "../../../../../contexts/payment-context";
import { generateDefaultValueObject } from "../stripe-elements/utils";

export const stripeError: Record<string, string> = {
  validate: "STRIPE_VALIDATION_ERROR",
  required: "STRIPE_REQUIRED_FILED_ERROR",
};

export type StripeFormDetails = {
  cardNumberErrorInput?: string;
  cardDateErrorInput?: string;
  cvcErrorInput?: string;
  saveInWallet?: boolean;
} & Pick<PaymentMethodCreateParams.BillingDetails, "name" | "email" | "phone"> &
  Pick<
    PaymentMethodCreateParams.BillingDetails.Address,
    "postal_code" | "city" | "state" | "line1" | "line2"
  >;

export const StripeForm = forwardRef<
  PaymentProcessorClientInternalRef<
    ProcessPaymentStripeRequestData,
    Stripe | null
  >
>((_props, ref) => {
  const { state } = useStripePaymentsStateContext();
  const { stripeSetup } = usePaymentContext();

  const defaultValues = useMemo(() => {
    try {
      return generateDefaultValueObject(stripeSetup);
    } catch (e) {
      console.error(
        "Wrong Config provided, using default one for Stripe Elements."
      );
      return generateDefaultValueObject(DefaultSetup);
    }
  }, [stripeSetup]);

  const form = useForm<StripeFormDetails>({ defaultValues });
  // Only wrapper to use hooks for get context of the elements
  const stripePromise = useMemo(
    () => loadStripe(state.clientSetup.registration?.stripe?.publicKey || ""),
    [state.clientSetup.registration?.stripe?.publicKey]
  );

  return (
    <FormProvider {...form}>
      <Elements stripe={stripePromise}>
        <StripeElements ref={ref} />
      </Elements>
    </FormProvider>
  );
});
StripeForm.displayName = "StripeForm";
