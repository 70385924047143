/* eslint-disable */
/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots.payment3_paymentmethod_coordinator || ($protobuf.roots.payment3_paymentmethod_coordinator = {});

export const payment3_paymentmethod_coordinatorpb = $root.payment3_paymentmethod_coordinatorpb = (() => {

    /**
     * Namespace payment3_paymentmethod_coordinatorpb.
     * @exports payment3_paymentmethod_coordinatorpb
     * @namespace
     */
    const payment3_paymentmethod_coordinatorpb = {};

    payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest = (function() {

        /**
         * Properties of a CreatePaymentMethodRegistrationRequest.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface ICreatePaymentMethodRegistrationRequest
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] CreatePaymentMethodRegistrationRequest paymentMethod
         */

        /**
         * Constructs a new CreatePaymentMethodRegistrationRequest.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a CreatePaymentMethodRegistrationRequest.
         * @implements ICreatePaymentMethodRegistrationRequest
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest=} [p] Properties to set
         */
        function CreatePaymentMethodRegistrationRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreatePaymentMethodRegistrationRequest paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest
         * @instance
         */
        CreatePaymentMethodRegistrationRequest.prototype.paymentMethod = null;

        /**
         * Creates a new CreatePaymentMethodRegistrationRequest instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest} CreatePaymentMethodRegistrationRequest instance
         */
        CreatePaymentMethodRegistrationRequest.create = function create(properties) {
            return new CreatePaymentMethodRegistrationRequest(properties);
        };

        /**
         * Encodes the specified CreatePaymentMethodRegistrationRequest message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest} m CreatePaymentMethodRegistrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePaymentMethodRegistrationRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CreatePaymentMethodRegistrationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest} CreatePaymentMethodRegistrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePaymentMethodRegistrationRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreatePaymentMethodRegistrationRequest;
    })();

    payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse = (function() {

        /**
         * Properties of a CreatePaymentMethodRegistrationResponse.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface ICreatePaymentMethodRegistrationResponse
         * @property {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistration|null} [registration] CreatePaymentMethodRegistrationResponse registration
         */

        /**
         * Constructs a new CreatePaymentMethodRegistrationResponse.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a CreatePaymentMethodRegistrationResponse.
         * @implements ICreatePaymentMethodRegistrationResponse
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationResponse=} [p] Properties to set
         */
        function CreatePaymentMethodRegistrationResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreatePaymentMethodRegistrationResponse registration.
         * @member {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistration|null|undefined} registration
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse
         * @instance
         */
        CreatePaymentMethodRegistrationResponse.prototype.registration = null;

        /**
         * Creates a new CreatePaymentMethodRegistrationResponse instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationResponse=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse} CreatePaymentMethodRegistrationResponse instance
         */
        CreatePaymentMethodRegistrationResponse.create = function create(properties) {
            return new CreatePaymentMethodRegistrationResponse(properties);
        };

        /**
         * Encodes the specified CreatePaymentMethodRegistrationResponse message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationResponse} m CreatePaymentMethodRegistrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePaymentMethodRegistrationResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.registration != null && Object.hasOwnProperty.call(m, "registration"))
                $root.payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration.encode(m.registration, w.uint32(802).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CreatePaymentMethodRegistrationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse} CreatePaymentMethodRegistrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePaymentMethodRegistrationResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.registration = $root.payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreatePaymentMethodRegistrationResponse;
    })();

    payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration = (function() {

        /**
         * Properties of a PaymentMethodRegistration.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface IPaymentMethodRegistration
         * @property {string|null} [registrationID] PaymentMethodRegistration registrationID
         * @property {payment3_commonpb.PaymentProcessor|null} [processor] PaymentMethodRegistration processor
         * @property {payment3_stripe_gatewaypb.IStripeRegistration|null} [stripe] PaymentMethodRegistration stripe
         */

        /**
         * Constructs a new PaymentMethodRegistration.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a PaymentMethodRegistration.
         * @implements IPaymentMethodRegistration
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistration=} [p] Properties to set
         */
        function PaymentMethodRegistration(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentMethodRegistration registrationID.
         * @member {string} registrationID
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @instance
         */
        PaymentMethodRegistration.prototype.registrationID = "";

        /**
         * PaymentMethodRegistration processor.
         * @member {payment3_commonpb.PaymentProcessor} processor
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @instance
         */
        PaymentMethodRegistration.prototype.processor = 0;

        /**
         * PaymentMethodRegistration stripe.
         * @member {payment3_stripe_gatewaypb.IStripeRegistration|null|undefined} stripe
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @instance
         */
        PaymentMethodRegistration.prototype.stripe = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PaymentMethodRegistration processorRegistration.
         * @member {"stripe"|undefined} processorRegistration
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @instance
         */
        Object.defineProperty(PaymentMethodRegistration.prototype, "processorRegistration", {
            get: $util.oneOfGetter($oneOfFields = ["stripe"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PaymentMethodRegistration instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistration=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration} PaymentMethodRegistration instance
         */
        PaymentMethodRegistration.create = function create(properties) {
            return new PaymentMethodRegistration(properties);
        };

        /**
         * Encodes the specified PaymentMethodRegistration message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistration} m PaymentMethodRegistration message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentMethodRegistration.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.registrationID != null && Object.hasOwnProperty.call(m, "registrationID"))
                w.uint32(10).string(m.registrationID);
            if (m.processor != null && Object.hasOwnProperty.call(m, "processor"))
                w.uint32(800).int32(m.processor);
            if (m.stripe != null && Object.hasOwnProperty.call(m, "stripe"))
                $root.payment3_stripe_gatewaypb.StripeRegistration.encode(m.stripe, w.uint32(810).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a PaymentMethodRegistration message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration} PaymentMethodRegistration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentMethodRegistration.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.PaymentMethodRegistration();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.registrationID = r.string();
                    break;
                case 100:
                    m.processor = r.int32();
                    break;
                case 101:
                    m.stripe = $root.payment3_stripe_gatewaypb.StripeRegistration.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return PaymentMethodRegistration;
    })();

    payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest = (function() {

        /**
         * Properties of a CreatePaymentMethodRequest.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface ICreatePaymentMethodRequest
         * @property {string|null} [registrationID] CreatePaymentMethodRequest registrationID
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] CreatePaymentMethodRequest paymentMethod
         */

        /**
         * Constructs a new CreatePaymentMethodRequest.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a CreatePaymentMethodRequest.
         * @implements ICreatePaymentMethodRequest
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest=} [p] Properties to set
         */
        function CreatePaymentMethodRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreatePaymentMethodRequest registrationID.
         * @member {string} registrationID
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest
         * @instance
         */
        CreatePaymentMethodRequest.prototype.registrationID = "";

        /**
         * CreatePaymentMethodRequest paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest
         * @instance
         */
        CreatePaymentMethodRequest.prototype.paymentMethod = null;

        /**
         * Creates a new CreatePaymentMethodRequest instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest} CreatePaymentMethodRequest instance
         */
        CreatePaymentMethodRequest.create = function create(properties) {
            return new CreatePaymentMethodRequest(properties);
        };

        /**
         * Encodes the specified CreatePaymentMethodRequest message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest} m CreatePaymentMethodRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePaymentMethodRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.registrationID != null && Object.hasOwnProperty.call(m, "registrationID"))
                w.uint32(10).string(m.registrationID);
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CreatePaymentMethodRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest} CreatePaymentMethodRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePaymentMethodRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.registrationID = r.string();
                    break;
                case 2:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreatePaymentMethodRequest;
    })();

    payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse = (function() {

        /**
         * Properties of a CreatePaymentMethodResponse.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface ICreatePaymentMethodResponse
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] CreatePaymentMethodResponse paymentMethod
         */

        /**
         * Constructs a new CreatePaymentMethodResponse.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a CreatePaymentMethodResponse.
         * @implements ICreatePaymentMethodResponse
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodResponse=} [p] Properties to set
         */
        function CreatePaymentMethodResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreatePaymentMethodResponse paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse
         * @instance
         */
        CreatePaymentMethodResponse.prototype.paymentMethod = null;

        /**
         * Creates a new CreatePaymentMethodResponse instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodResponse=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse} CreatePaymentMethodResponse instance
         */
        CreatePaymentMethodResponse.create = function create(properties) {
            return new CreatePaymentMethodResponse(properties);
        };

        /**
         * Encodes the specified CreatePaymentMethodResponse message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodResponse} m CreatePaymentMethodResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePaymentMethodResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CreatePaymentMethodResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse} CreatePaymentMethodResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePaymentMethodResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreatePaymentMethodResponse;
    })();

    payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration = (function() {

        /**
         * Constructs a new CreatePaymentMethodRegistration service.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a CreatePaymentMethodRegistration
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CreatePaymentMethodRegistration(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CreatePaymentMethodRegistration.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CreatePaymentMethodRegistration;

        /**
         * Creates new CreatePaymentMethodRegistration service using the specified rpc implementation.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {CreatePaymentMethodRegistration} RPC service. Useful where requests and/or responses are streamed.
         */
        CreatePaymentMethodRegistration.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration#post}.
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration
         * @typedef PostCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse} [response] CreatePaymentMethodRegistrationResponse
         */

        /**
         * Calls Post.
         * @function post
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration
         * @instance
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest} request CreatePaymentMethodRegistrationRequest message or plain object
         * @param {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration.PostCallback} callback Node-style callback called with the error, if any, and CreatePaymentMethodRegistrationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreatePaymentMethodRegistration.prototype.post = function post(request, callback) {
            return this.rpcCall(post, $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest, $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse, request, callback);
        }, "name", { value: "Post" });

        /**
         * Calls Post.
         * @function post
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistration
         * @instance
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest} request CreatePaymentMethodRegistrationRequest message or plain object
         * @returns {Promise<payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse>} Promise
         * @variation 2
         */

        return CreatePaymentMethodRegistration;
    })();

    payment3_paymentmethod_coordinatorpb.CreatePaymentMethod = (function() {

        /**
         * Constructs a new CreatePaymentMethod service.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a CreatePaymentMethod
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CreatePaymentMethod(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CreatePaymentMethod.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CreatePaymentMethod;

        /**
         * Creates new CreatePaymentMethod service using the specified rpc implementation.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethod
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {CreatePaymentMethod} RPC service. Useful where requests and/or responses are streamed.
         */
        CreatePaymentMethod.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link payment3_paymentmethod_coordinatorpb.CreatePaymentMethod#post}.
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethod
         * @typedef PostCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse} [response] CreatePaymentMethodResponse
         */

        /**
         * Calls Post.
         * @function post
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethod
         * @instance
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest} request CreatePaymentMethodRequest message or plain object
         * @param {payment3_paymentmethod_coordinatorpb.CreatePaymentMethod.PostCallback} callback Node-style callback called with the error, if any, and CreatePaymentMethodResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreatePaymentMethod.prototype.post = function post(request, callback) {
            return this.rpcCall(post, $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest, $root.payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse, request, callback);
        }, "name", { value: "Post" });

        /**
         * Calls Post.
         * @function post
         * @memberof payment3_paymentmethod_coordinatorpb.CreatePaymentMethod
         * @instance
         * @param {payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest} request CreatePaymentMethodRequest message or plain object
         * @returns {Promise<payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse>} Promise
         * @variation 2
         */

        return CreatePaymentMethod;
    })();

    payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal = (function() {

        /**
         * Properties of a PaymentMethodRegistrationInternal.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface IPaymentMethodRegistrationInternal
         * @property {payment3_commonpb.PaymentProcessor|null} [processor] PaymentMethodRegistrationInternal processor
         * @property {payment3_stripe_gatewaypb.IStripeRegistration|null} [stripe] PaymentMethodRegistrationInternal stripe
         * @property {payment3_stripe_gatewaypb.IStripeRegistrationInternal|null} [stripeInternal] PaymentMethodRegistrationInternal stripeInternal
         */

        /**
         * Constructs a new PaymentMethodRegistrationInternal.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a PaymentMethodRegistrationInternal.
         * @implements IPaymentMethodRegistrationInternal
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistrationInternal=} [p] Properties to set
         */
        function PaymentMethodRegistrationInternal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentMethodRegistrationInternal processor.
         * @member {payment3_commonpb.PaymentProcessor} processor
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @instance
         */
        PaymentMethodRegistrationInternal.prototype.processor = 0;

        /**
         * PaymentMethodRegistrationInternal stripe.
         * @member {payment3_stripe_gatewaypb.IStripeRegistration|null|undefined} stripe
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @instance
         */
        PaymentMethodRegistrationInternal.prototype.stripe = null;

        /**
         * PaymentMethodRegistrationInternal stripeInternal.
         * @member {payment3_stripe_gatewaypb.IStripeRegistrationInternal|null|undefined} stripeInternal
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @instance
         */
        PaymentMethodRegistrationInternal.prototype.stripeInternal = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PaymentMethodRegistrationInternal processorRegistration.
         * @member {"stripe"|undefined} processorRegistration
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @instance
         */
        Object.defineProperty(PaymentMethodRegistrationInternal.prototype, "processorRegistration", {
            get: $util.oneOfGetter($oneOfFields = ["stripe"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentMethodRegistrationInternal processorRegistrationInternal.
         * @member {"stripeInternal"|undefined} processorRegistrationInternal
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @instance
         */
        Object.defineProperty(PaymentMethodRegistrationInternal.prototype, "processorRegistrationInternal", {
            get: $util.oneOfGetter($oneOfFields = ["stripeInternal"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PaymentMethodRegistrationInternal instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistrationInternal=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal} PaymentMethodRegistrationInternal instance
         */
        PaymentMethodRegistrationInternal.create = function create(properties) {
            return new PaymentMethodRegistrationInternal(properties);
        };

        /**
         * Encodes the specified PaymentMethodRegistrationInternal message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistrationInternal} m PaymentMethodRegistrationInternal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentMethodRegistrationInternal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.processor != null && Object.hasOwnProperty.call(m, "processor"))
                w.uint32(800).int32(m.processor);
            if (m.stripe != null && Object.hasOwnProperty.call(m, "stripe"))
                $root.payment3_stripe_gatewaypb.StripeRegistration.encode(m.stripe, w.uint32(810).fork()).ldelim();
            if (m.stripeInternal != null && Object.hasOwnProperty.call(m, "stripeInternal"))
                $root.payment3_stripe_gatewaypb.StripeRegistrationInternal.encode(m.stripeInternal, w.uint32(1610).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a PaymentMethodRegistrationInternal message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal} PaymentMethodRegistrationInternal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentMethodRegistrationInternal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.processor = r.int32();
                    break;
                case 101:
                    m.stripe = $root.payment3_stripe_gatewaypb.StripeRegistration.decode(r, r.uint32());
                    break;
                case 201:
                    m.stripeInternal = $root.payment3_stripe_gatewaypb.StripeRegistrationInternal.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return PaymentMethodRegistrationInternal;
    })();

    payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest = (function() {

        /**
         * Properties of a FinishPaymentMethodRequest.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface IFinishPaymentMethodRequest
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] FinishPaymentMethodRequest paymentMethod
         * @property {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistrationInternal|null} [paymentMethodInternal] FinishPaymentMethodRequest paymentMethodInternal
         */

        /**
         * Constructs a new FinishPaymentMethodRequest.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a FinishPaymentMethodRequest.
         * @implements IFinishPaymentMethodRequest
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.IFinishPaymentMethodRequest=} [p] Properties to set
         */
        function FinishPaymentMethodRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FinishPaymentMethodRequest paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest
         * @instance
         */
        FinishPaymentMethodRequest.prototype.paymentMethod = null;

        /**
         * FinishPaymentMethodRequest paymentMethodInternal.
         * @member {payment3_paymentmethod_coordinatorpb.IPaymentMethodRegistrationInternal|null|undefined} paymentMethodInternal
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest
         * @instance
         */
        FinishPaymentMethodRequest.prototype.paymentMethodInternal = null;

        /**
         * Creates a new FinishPaymentMethodRequest instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IFinishPaymentMethodRequest=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest} FinishPaymentMethodRequest instance
         */
        FinishPaymentMethodRequest.create = function create(properties) {
            return new FinishPaymentMethodRequest(properties);
        };

        /**
         * Encodes the specified FinishPaymentMethodRequest message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IFinishPaymentMethodRequest} m FinishPaymentMethodRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FinishPaymentMethodRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(802).fork()).ldelim();
            if (m.paymentMethodInternal != null && Object.hasOwnProperty.call(m, "paymentMethodInternal"))
                $root.payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal.encode(m.paymentMethodInternal, w.uint32(1602).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a FinishPaymentMethodRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest} FinishPaymentMethodRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FinishPaymentMethodRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.FinishPaymentMethodRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                case 200:
                    m.paymentMethodInternal = $root.payment3_paymentmethod_coordinatorpb.PaymentMethodRegistrationInternal.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return FinishPaymentMethodRequest;
    })();

    payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse = (function() {

        /**
         * Properties of a FinishPaymentMethodResponse.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @interface IFinishPaymentMethodResponse
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] FinishPaymentMethodResponse paymentMethod
         */

        /**
         * Constructs a new FinishPaymentMethodResponse.
         * @memberof payment3_paymentmethod_coordinatorpb
         * @classdesc Represents a FinishPaymentMethodResponse.
         * @implements IFinishPaymentMethodResponse
         * @constructor
         * @param {payment3_paymentmethod_coordinatorpb.IFinishPaymentMethodResponse=} [p] Properties to set
         */
        function FinishPaymentMethodResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FinishPaymentMethodResponse paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse
         * @instance
         */
        FinishPaymentMethodResponse.prototype.paymentMethod = null;

        /**
         * Creates a new FinishPaymentMethodResponse instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IFinishPaymentMethodResponse=} [properties] Properties to set
         * @returns {payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse} FinishPaymentMethodResponse instance
         */
        FinishPaymentMethodResponse.create = function create(properties) {
            return new FinishPaymentMethodResponse(properties);
        };

        /**
         * Encodes the specified FinishPaymentMethodResponse message. Does not implicitly {@link payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse
         * @static
         * @param {payment3_paymentmethod_coordinatorpb.IFinishPaymentMethodResponse} m FinishPaymentMethodResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FinishPaymentMethodResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(802).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a FinishPaymentMethodResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse} FinishPaymentMethodResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FinishPaymentMethodResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethod_coordinatorpb.FinishPaymentMethodResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return FinishPaymentMethodResponse;
    })();

    return payment3_paymentmethod_coordinatorpb;
})();

export const common = $root.common = (() => {

    /**
     * Namespace common.
     * @exports common
     * @namespace
     */
    const common = {};

    /**
     * Tenant enum.
     * @name common.Tenant
     * @enum {number}
     * @property {number} TENANT_UNDEFINED=0 TENANT_UNDEFINED value
     * @property {number} TEST=1 TEST value
     * @property {number} AHS=2 AHS value
     * @property {number} CD=3 CD value
     * @property {number} HSA=4 HSA value
     * @property {number} OG=5 OG value
     * @property {number} LM=6 LM value
     * @property {number} FRONTDOOR=7 FRONTDOOR value
     * @property {number} AHSPC=8 AHSPC value
     * @property {number} HSAPC=9 HSAPC value
     */
    common.Tenant = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TENANT_UNDEFINED"] = 0;
        values[valuesById[1] = "TEST"] = 1;
        values[valuesById[2] = "AHS"] = 2;
        values[valuesById[3] = "CD"] = 3;
        values[valuesById[4] = "HSA"] = 4;
        values[valuesById[5] = "OG"] = 5;
        values[valuesById[6] = "LM"] = 6;
        values[valuesById[7] = "FRONTDOOR"] = 7;
        values[valuesById[8] = "AHSPC"] = 8;
        values[valuesById[9] = "HSAPC"] = 9;
        return values;
    })();

    /**
     * Alert enum.
     * @name common.Alert
     * @enum {number}
     * @property {number} ALERTS_UNDEFINED=0 ALERTS_UNDEFINED value
     * @property {number} ALERTS_UNSCHEDULED=1 ALERTS_UNSCHEDULED value
     * @property {number} ALERTS_NEED_STATUS_UPDATE=2 ALERTS_NEED_STATUS_UPDATE value
     * @property {number} ALERTS_OPEN_LONGER_THAN_AVERAGE=3 ALERTS_OPEN_LONGER_THAN_AVERAGE value
     * @property {number} ALERTS_SCHEDULE_NEXT_APPOINTMENT=4 ALERTS_SCHEDULE_NEXT_APPOINTMENT value
     * @property {number} ALERTS_RESUBMIT_REQUEST=5 ALERTS_RESUBMIT_REQUEST value
     */
    common.Alert = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ALERTS_UNDEFINED"] = 0;
        values[valuesById[1] = "ALERTS_UNSCHEDULED"] = 1;
        values[valuesById[2] = "ALERTS_NEED_STATUS_UPDATE"] = 2;
        values[valuesById[3] = "ALERTS_OPEN_LONGER_THAN_AVERAGE"] = 3;
        values[valuesById[4] = "ALERTS_SCHEDULE_NEXT_APPOINTMENT"] = 4;
        values[valuesById[5] = "ALERTS_RESUBMIT_REQUEST"] = 5;
        return values;
    })();

    /**
     * Status enum.
     * @name common.Status
     * @enum {number}
     * @property {number} STATUS_UNDEFINED=0 STATUS_UNDEFINED value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} FAIL=2 FAIL value
     */
    common.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATUS_UNDEFINED"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "FAIL"] = 2;
        return values;
    })();

    /**
     * UserRole enum.
     * @name common.UserRole
     * @enum {number}
     * @property {number} USER_ROLE_UNDEFINED=0 USER_ROLE_UNDEFINED value
     * @property {number} CONTRACTOR=1 CONTRACTOR value
     * @property {number} AGENT=2 AGENT value
     * @property {number} ADMIN=3 ADMIN value
     * @property {number} TECHNICIAN=4 TECHNICIAN value
     * @property {number} PARTNER=5 PARTNER value
     * @property {number} CUSTOMER=6 CUSTOMER value
     * @property {number} DEVELOPER=7 DEVELOPER value
     */
    common.UserRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_ROLE_UNDEFINED"] = 0;
        values[valuesById[1] = "CONTRACTOR"] = 1;
        values[valuesById[2] = "AGENT"] = 2;
        values[valuesById[3] = "ADMIN"] = 3;
        values[valuesById[4] = "TECHNICIAN"] = 4;
        values[valuesById[5] = "PARTNER"] = 5;
        values[valuesById[6] = "CUSTOMER"] = 6;
        values[valuesById[7] = "DEVELOPER"] = 7;
        return values;
    })();

    common.HealthCheckDetail = (function() {

        /**
         * Properties of a HealthCheckDetail.
         * @memberof common
         * @interface IHealthCheckDetail
         * @property {string|null} [componentID] HealthCheckDetail componentID
         * @property {string|null} [type] HealthCheckDetail type
         * @property {number|null} [observedValue] HealthCheckDetail observedValue
         * @property {string|null} [observedUnit] HealthCheckDetail observedUnit
         * @property {string|null} [status] HealthCheckDetail status
         * @property {google.protobuf.ITimestamp|null} [time] HealthCheckDetail time
         * @property {string|null} [output] HealthCheckDetail output
         */

        /**
         * Constructs a new HealthCheckDetail.
         * @memberof common
         * @classdesc Represents a HealthCheckDetail.
         * @implements IHealthCheckDetail
         * @constructor
         * @param {common.IHealthCheckDetail=} [p] Properties to set
         */
        function HealthCheckDetail(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HealthCheckDetail componentID.
         * @member {string} componentID
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.componentID = "";

        /**
         * HealthCheckDetail type.
         * @member {string} type
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.type = "";

        /**
         * HealthCheckDetail observedValue.
         * @member {number} observedValue
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.observedValue = 0;

        /**
         * HealthCheckDetail observedUnit.
         * @member {string} observedUnit
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.observedUnit = "";

        /**
         * HealthCheckDetail status.
         * @member {string} status
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.status = "";

        /**
         * HealthCheckDetail time.
         * @member {google.protobuf.ITimestamp|null|undefined} time
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.time = null;

        /**
         * HealthCheckDetail output.
         * @member {string} output
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.output = "";

        /**
         * Creates a new HealthCheckDetail instance using the specified properties.
         * @function create
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail=} [properties] Properties to set
         * @returns {common.HealthCheckDetail} HealthCheckDetail instance
         */
        HealthCheckDetail.create = function create(properties) {
            return new HealthCheckDetail(properties);
        };

        /**
         * Encodes the specified HealthCheckDetail message. Does not implicitly {@link common.HealthCheckDetail.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail} m HealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckDetail.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.componentID != null && Object.hasOwnProperty.call(m, "componentID"))
                w.uint32(10).string(m.componentID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(18).string(m.type);
            if (m.observedValue != null && Object.hasOwnProperty.call(m, "observedValue"))
                w.uint32(25).double(m.observedValue);
            if (m.observedUnit != null && Object.hasOwnProperty.call(m, "observedUnit"))
                w.uint32(34).string(m.observedUnit);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(42).string(m.status);
            if (m.time != null && Object.hasOwnProperty.call(m, "time"))
                $root.google.protobuf.Timestamp.encode(m.time, w.uint32(50).fork()).ldelim();
            if (m.output != null && Object.hasOwnProperty.call(m, "output"))
                w.uint32(58).string(m.output);
            return w;
        };

        /**
         * Decodes a HealthCheckDetail message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.HealthCheckDetail} HealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckDetail.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.HealthCheckDetail();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.componentID = r.string();
                    break;
                case 2:
                    m.type = r.string();
                    break;
                case 3:
                    m.observedValue = r.double();
                    break;
                case 4:
                    m.observedUnit = r.string();
                    break;
                case 5:
                    m.status = r.string();
                    break;
                case 6:
                    m.time = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                case 7:
                    m.output = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HealthCheckDetail;
    })();

    common.NamedHealthCheckDetail = (function() {

        /**
         * Properties of a NamedHealthCheckDetail.
         * @memberof common
         * @interface INamedHealthCheckDetail
         * @property {string|null} [name] NamedHealthCheckDetail name
         * @property {Array.<common.IHealthCheckDetail>|null} [details] NamedHealthCheckDetail details
         */

        /**
         * Constructs a new NamedHealthCheckDetail.
         * @memberof common
         * @classdesc Represents a NamedHealthCheckDetail.
         * @implements INamedHealthCheckDetail
         * @constructor
         * @param {common.INamedHealthCheckDetail=} [p] Properties to set
         */
        function NamedHealthCheckDetail(p) {
            this.details = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NamedHealthCheckDetail name.
         * @member {string} name
         * @memberof common.NamedHealthCheckDetail
         * @instance
         */
        NamedHealthCheckDetail.prototype.name = "";

        /**
         * NamedHealthCheckDetail details.
         * @member {Array.<common.IHealthCheckDetail>} details
         * @memberof common.NamedHealthCheckDetail
         * @instance
         */
        NamedHealthCheckDetail.prototype.details = $util.emptyArray;

        /**
         * Creates a new NamedHealthCheckDetail instance using the specified properties.
         * @function create
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail=} [properties] Properties to set
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail instance
         */
        NamedHealthCheckDetail.create = function create(properties) {
            return new NamedHealthCheckDetail(properties);
        };

        /**
         * Encodes the specified NamedHealthCheckDetail message. Does not implicitly {@link common.NamedHealthCheckDetail.verify|verify} messages.
         * @function encode
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail} m NamedHealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedHealthCheckDetail.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.details != null && m.details.length) {
                for (var i = 0; i < m.details.length; ++i)
                    $root.common.HealthCheckDetail.encode(m.details[i], w.uint32(18).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a NamedHealthCheckDetail message from the specified reader or buffer.
         * @function decode
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedHealthCheckDetail.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.NamedHealthCheckDetail();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                case 2:
                    if (!(m.details && m.details.length))
                        m.details = [];
                    m.details.push($root.common.HealthCheckDetail.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return NamedHealthCheckDetail;
    })();

    common.HealthCheck = (function() {

        /**
         * Properties of a HealthCheck.
         * @memberof common
         * @interface IHealthCheck
         * @property {string|null} [status] HealthCheck status
         * @property {string|null} [version] HealthCheck version
         * @property {string|null} [releaseID] HealthCheck releaseID
         * @property {Array.<string>|null} [notes] HealthCheck notes
         * @property {string|null} [output] HealthCheck output
         * @property {Array.<common.INamedHealthCheckDetail>|null} [details] HealthCheck details
         */

        /**
         * Constructs a new HealthCheck.
         * @memberof common
         * @classdesc Represents a HealthCheck.
         * @implements IHealthCheck
         * @constructor
         * @param {common.IHealthCheck=} [p] Properties to set
         */
        function HealthCheck(p) {
            this.notes = [];
            this.details = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HealthCheck status.
         * @member {string} status
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.status = "";

        /**
         * HealthCheck version.
         * @member {string} version
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.version = "";

        /**
         * HealthCheck releaseID.
         * @member {string} releaseID
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.releaseID = "";

        /**
         * HealthCheck notes.
         * @member {Array.<string>} notes
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.notes = $util.emptyArray;

        /**
         * HealthCheck output.
         * @member {string} output
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.output = "";

        /**
         * HealthCheck details.
         * @member {Array.<common.INamedHealthCheckDetail>} details
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.details = $util.emptyArray;

        /**
         * Creates a new HealthCheck instance using the specified properties.
         * @function create
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck=} [properties] Properties to set
         * @returns {common.HealthCheck} HealthCheck instance
         */
        HealthCheck.create = function create(properties) {
            return new HealthCheck(properties);
        };

        /**
         * Encodes the specified HealthCheck message. Does not implicitly {@link common.HealthCheck.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck} m HealthCheck message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheck.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(10).string(m.status);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(18).string(m.version);
            if (m.releaseID != null && Object.hasOwnProperty.call(m, "releaseID"))
                w.uint32(26).string(m.releaseID);
            if (m.notes != null && m.notes.length) {
                for (var i = 0; i < m.notes.length; ++i)
                    w.uint32(34).string(m.notes[i]);
            }
            if (m.output != null && Object.hasOwnProperty.call(m, "output"))
                w.uint32(42).string(m.output);
            if (m.details != null && m.details.length) {
                for (var i = 0; i < m.details.length; ++i)
                    $root.common.NamedHealthCheckDetail.encode(m.details[i], w.uint32(50).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a HealthCheck message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.HealthCheck} HealthCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheck.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.HealthCheck();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.status = r.string();
                    break;
                case 2:
                    m.version = r.string();
                    break;
                case 3:
                    m.releaseID = r.string();
                    break;
                case 4:
                    if (!(m.notes && m.notes.length))
                        m.notes = [];
                    m.notes.push(r.string());
                    break;
                case 5:
                    m.output = r.string();
                    break;
                case 6:
                    if (!(m.details && m.details.length))
                        m.details = [];
                    m.details.push($root.common.NamedHealthCheckDetail.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HealthCheck;
    })();

    common.HealthCheckResponse = (function() {

        /**
         * Properties of a HealthCheckResponse.
         * @memberof common
         * @interface IHealthCheckResponse
         * @property {common.IHealthCheck|null} [health] HealthCheckResponse health
         */

        /**
         * Constructs a new HealthCheckResponse.
         * @memberof common
         * @classdesc Represents a HealthCheckResponse.
         * @implements IHealthCheckResponse
         * @constructor
         * @param {common.IHealthCheckResponse=} [p] Properties to set
         */
        function HealthCheckResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HealthCheckResponse health.
         * @member {common.IHealthCheck|null|undefined} health
         * @memberof common.HealthCheckResponse
         * @instance
         */
        HealthCheckResponse.prototype.health = null;

        /**
         * Creates a new HealthCheckResponse instance using the specified properties.
         * @function create
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse=} [properties] Properties to set
         * @returns {common.HealthCheckResponse} HealthCheckResponse instance
         */
        HealthCheckResponse.create = function create(properties) {
            return new HealthCheckResponse(properties);
        };

        /**
         * Encodes the specified HealthCheckResponse message. Does not implicitly {@link common.HealthCheckResponse.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse} m HealthCheckResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.health != null && Object.hasOwnProperty.call(m, "health"))
                $root.common.HealthCheck.encode(m.health, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a HealthCheckResponse message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheckResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.HealthCheckResponse} HealthCheckResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.HealthCheckResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.health = $root.common.HealthCheck.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HealthCheckResponse;
    })();

    common.Timeslot = (function() {

        /**
         * Properties of a Timeslot.
         * @memberof common
         * @interface ITimeslot
         * @property {google.protobuf.ITimestamp|null} [start] Timeslot start
         * @property {google.protobuf.ITimestamp|null} [end] Timeslot end
         */

        /**
         * Constructs a new Timeslot.
         * @memberof common
         * @classdesc Represents a Timeslot.
         * @implements ITimeslot
         * @constructor
         * @param {common.ITimeslot=} [p] Properties to set
         */
        function Timeslot(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Timeslot start.
         * @member {google.protobuf.ITimestamp|null|undefined} start
         * @memberof common.Timeslot
         * @instance
         */
        Timeslot.prototype.start = null;

        /**
         * Timeslot end.
         * @member {google.protobuf.ITimestamp|null|undefined} end
         * @memberof common.Timeslot
         * @instance
         */
        Timeslot.prototype.end = null;

        /**
         * Creates a new Timeslot instance using the specified properties.
         * @function create
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot=} [properties] Properties to set
         * @returns {common.Timeslot} Timeslot instance
         */
        Timeslot.create = function create(properties) {
            return new Timeslot(properties);
        };

        /**
         * Encodes the specified Timeslot message. Does not implicitly {@link common.Timeslot.verify|verify} messages.
         * @function encode
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot} m Timeslot message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Timeslot.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                $root.google.protobuf.Timestamp.encode(m.start, w.uint32(10).fork()).ldelim();
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                $root.google.protobuf.Timestamp.encode(m.end, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Timeslot message from the specified reader or buffer.
         * @function decode
         * @memberof common.Timeslot
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Timeslot} Timeslot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Timeslot.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Timeslot();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.start = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                case 2:
                    m.end = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Timeslot;
    })();

    common.ErrorResponse = (function() {

        /**
         * Properties of an ErrorResponse.
         * @memberof common
         * @interface IErrorResponse
         * @property {Array.<common.IError>|null} [errors] ErrorResponse errors
         */

        /**
         * Constructs a new ErrorResponse.
         * @memberof common
         * @classdesc Represents an ErrorResponse.
         * @implements IErrorResponse
         * @constructor
         * @param {common.IErrorResponse=} [p] Properties to set
         */
        function ErrorResponse(p) {
            this.errors = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ErrorResponse errors.
         * @member {Array.<common.IError>} errors
         * @memberof common.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.errors = $util.emptyArray;

        /**
         * Creates a new ErrorResponse instance using the specified properties.
         * @function create
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse=} [properties] Properties to set
         * @returns {common.ErrorResponse} ErrorResponse instance
         */
        ErrorResponse.create = function create(properties) {
            return new ErrorResponse(properties);
        };

        /**
         * Encodes the specified ErrorResponse message. Does not implicitly {@link common.ErrorResponse.verify|verify} messages.
         * @function encode
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse} m ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.errors != null && m.errors.length) {
                for (var i = 0; i < m.errors.length; ++i)
                    $root.common.Error.encode(m.errors[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof common.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.ErrorResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.errors && m.errors.length))
                        m.errors = [];
                    m.errors.push($root.common.Error.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ErrorResponse;
    })();

    common.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof common
         * @interface IError
         * @property {string|null} [message] Error message
         * @property {string|null} [code] Error code
         */

        /**
         * Constructs a new Error.
         * @memberof common
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {common.IError=} [p] Properties to set
         */
        function Error(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Error message.
         * @member {string} message
         * @memberof common.Error
         * @instance
         */
        Error.prototype.message = "";

        /**
         * Error code.
         * @member {string} code
         * @memberof common.Error
         * @instance
         */
        Error.prototype.code = "";

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof common.Error
         * @static
         * @param {common.IError=} [properties] Properties to set
         * @returns {common.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link common.Error.verify|verify} messages.
         * @function encode
         * @memberof common.Error
         * @static
         * @param {common.IError} m Error message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                w.uint32(10).string(m.message);
            if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                w.uint32(18).string(m.code);
            return w;
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof common.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Error();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.message = r.string();
                    break;
                case 2:
                    m.code = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Error;
    })();

    common.Money = (function() {

        /**
         * Properties of a Money.
         * @memberof common
         * @interface IMoney
         * @property {string|null} [currencyCode] Money currencyCode
         * @property {number|Long|null} [units] Money units
         * @property {number|null} [nanos] Money nanos
         */

        /**
         * Constructs a new Money.
         * @memberof common
         * @classdesc Represents a Money.
         * @implements IMoney
         * @constructor
         * @param {common.IMoney=} [p] Properties to set
         */
        function Money(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Money currencyCode.
         * @member {string} currencyCode
         * @memberof common.Money
         * @instance
         */
        Money.prototype.currencyCode = "";

        /**
         * Money units.
         * @member {number|Long} units
         * @memberof common.Money
         * @instance
         */
        Money.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Money nanos.
         * @member {number} nanos
         * @memberof common.Money
         * @instance
         */
        Money.prototype.nanos = 0;

        /**
         * Creates a new Money instance using the specified properties.
         * @function create
         * @memberof common.Money
         * @static
         * @param {common.IMoney=} [properties] Properties to set
         * @returns {common.Money} Money instance
         */
        Money.create = function create(properties) {
            return new Money(properties);
        };

        /**
         * Encodes the specified Money message. Does not implicitly {@link common.Money.verify|verify} messages.
         * @function encode
         * @memberof common.Money
         * @static
         * @param {common.IMoney} m Money message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Money.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.currencyCode != null && Object.hasOwnProperty.call(m, "currencyCode"))
                w.uint32(10).string(m.currencyCode);
            if (m.units != null && Object.hasOwnProperty.call(m, "units"))
                w.uint32(16).int64(m.units);
            if (m.nanos != null && Object.hasOwnProperty.call(m, "nanos"))
                w.uint32(24).int32(m.nanos);
            return w;
        };

        /**
         * Decodes a Money message from the specified reader or buffer.
         * @function decode
         * @memberof common.Money
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Money} Money
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Money.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Money();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.currencyCode = r.string();
                    break;
                case 2:
                    m.units = r.int64();
                    break;
                case 3:
                    m.nanos = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Money;
    })();

    common.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof common
         * @interface IDate
         * @property {number|null} [year] Date year
         * @property {number|null} [month] Date month
         * @property {number|null} [day] Date day
         */

        /**
         * Constructs a new Date.
         * @memberof common
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {common.IDate=} [p] Properties to set
         */
        function Date(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Date year.
         * @member {number} year
         * @memberof common.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof common.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof common.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof common.Date
         * @static
         * @param {common.IDate=} [properties] Properties to set
         * @returns {common.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link common.Date.verify|verify} messages.
         * @function encode
         * @memberof common.Date
         * @static
         * @param {common.IDate} m Date message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.year != null && Object.hasOwnProperty.call(m, "year"))
                w.uint32(8).int32(m.year);
            if (m.month != null && Object.hasOwnProperty.call(m, "month"))
                w.uint32(16).int32(m.month);
            if (m.day != null && Object.hasOwnProperty.call(m, "day"))
                w.uint32(24).int32(m.day);
            return w;
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof common.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Date();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.year = r.int32();
                    break;
                case 2:
                    m.month = r.int32();
                    break;
                case 3:
                    m.day = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Date;
    })();

    common.GeoLocation = (function() {

        /**
         * Properties of a GeoLocation.
         * @memberof common
         * @interface IGeoLocation
         * @property {number|null} [latitude] GeoLocation latitude
         * @property {number|null} [longitude] GeoLocation longitude
         */

        /**
         * Constructs a new GeoLocation.
         * @memberof common
         * @classdesc Represents a GeoLocation.
         * @implements IGeoLocation
         * @constructor
         * @param {common.IGeoLocation=} [p] Properties to set
         */
        function GeoLocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GeoLocation latitude.
         * @member {number} latitude
         * @memberof common.GeoLocation
         * @instance
         */
        GeoLocation.prototype.latitude = 0;

        /**
         * GeoLocation longitude.
         * @member {number} longitude
         * @memberof common.GeoLocation
         * @instance
         */
        GeoLocation.prototype.longitude = 0;

        /**
         * Creates a new GeoLocation instance using the specified properties.
         * @function create
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation=} [properties] Properties to set
         * @returns {common.GeoLocation} GeoLocation instance
         */
        GeoLocation.create = function create(properties) {
            return new GeoLocation(properties);
        };

        /**
         * Encodes the specified GeoLocation message. Does not implicitly {@link common.GeoLocation.verify|verify} messages.
         * @function encode
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation} m GeoLocation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeoLocation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.latitude != null && Object.hasOwnProperty.call(m, "latitude"))
                w.uint32(13).float(m.latitude);
            if (m.longitude != null && Object.hasOwnProperty.call(m, "longitude"))
                w.uint32(21).float(m.longitude);
            return w;
        };

        /**
         * Decodes a GeoLocation message from the specified reader or buffer.
         * @function decode
         * @memberof common.GeoLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.GeoLocation} GeoLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeoLocation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.GeoLocation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.latitude = r.float();
                    break;
                case 2:
                    m.longitude = r.float();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return GeoLocation;
    })();

    /**
     * Locale enum.
     * @name common.Locale
     * @enum {number}
     * @property {number} EN=0 EN value
     * @property {number} ES=1 ES value
     */
    common.Locale = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EN"] = 0;
        values[valuesById[1] = "ES"] = 1;
        return values;
    })();

    common.Range_U32 = (function() {

        /**
         * Properties of a Range_U32.
         * @memberof common
         * @interface IRange_U32
         * @property {number|null} [min] Range_U32 min
         * @property {number|null} [max] Range_U32 max
         */

        /**
         * Constructs a new Range_U32.
         * @memberof common
         * @classdesc Represents a Range_U32.
         * @implements IRange_U32
         * @constructor
         * @param {common.IRange_U32=} [p] Properties to set
         */
        function Range_U32(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Range_U32 min.
         * @member {number} min
         * @memberof common.Range_U32
         * @instance
         */
        Range_U32.prototype.min = 0;

        /**
         * Range_U32 max.
         * @member {number} max
         * @memberof common.Range_U32
         * @instance
         */
        Range_U32.prototype.max = 0;

        /**
         * Creates a new Range_U32 instance using the specified properties.
         * @function create
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32=} [properties] Properties to set
         * @returns {common.Range_U32} Range_U32 instance
         */
        Range_U32.create = function create(properties) {
            return new Range_U32(properties);
        };

        /**
         * Encodes the specified Range_U32 message. Does not implicitly {@link common.Range_U32.verify|verify} messages.
         * @function encode
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32} m Range_U32 message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Range_U32.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.min != null && Object.hasOwnProperty.call(m, "min"))
                w.uint32(8).uint32(m.min);
            if (m.max != null && Object.hasOwnProperty.call(m, "max"))
                w.uint32(16).uint32(m.max);
            return w;
        };

        /**
         * Decodes a Range_U32 message from the specified reader or buffer.
         * @function decode
         * @memberof common.Range_U32
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Range_U32} Range_U32
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Range_U32.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Range_U32();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.min = r.uint32();
                    break;
                case 2:
                    m.max = r.uint32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Range_U32;
    })();

    common.ApplicationSource = (function() {

        /**
         * Properties of an ApplicationSource.
         * @memberof common
         * @interface IApplicationSource
         * @property {string|null} [name] ApplicationSource name
         */

        /**
         * Constructs a new ApplicationSource.
         * @memberof common
         * @classdesc Represents an ApplicationSource.
         * @implements IApplicationSource
         * @constructor
         * @param {common.IApplicationSource=} [p] Properties to set
         */
        function ApplicationSource(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ApplicationSource name.
         * @member {string} name
         * @memberof common.ApplicationSource
         * @instance
         */
        ApplicationSource.prototype.name = "";

        /**
         * Creates a new ApplicationSource instance using the specified properties.
         * @function create
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource=} [properties] Properties to set
         * @returns {common.ApplicationSource} ApplicationSource instance
         */
        ApplicationSource.create = function create(properties) {
            return new ApplicationSource(properties);
        };

        /**
         * Encodes the specified ApplicationSource message. Does not implicitly {@link common.ApplicationSource.verify|verify} messages.
         * @function encode
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource} m ApplicationSource message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplicationSource.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            return w;
        };

        /**
         * Decodes an ApplicationSource message from the specified reader or buffer.
         * @function decode
         * @memberof common.ApplicationSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.ApplicationSource} ApplicationSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplicationSource.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.ApplicationSource();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ApplicationSource;
    })();

    return common;
})();

export const payment3_commonpb = $root.payment3_commonpb = (() => {

    /**
     * Namespace payment3_commonpb.
     * @exports payment3_commonpb
     * @namespace
     */
    const payment3_commonpb = {};

    /**
     * PaymentProcessor enum.
     * @name payment3_commonpb.PaymentProcessor
     * @enum {number}
     * @property {number} Undefined_PaymentProcessor=0 Undefined_PaymentProcessor value
     * @property {number} Stripe=1 Stripe value
     * @property {number} Paymentus=2 Paymentus value
     */
    payment3_commonpb.PaymentProcessor = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined_PaymentProcessor"] = 0;
        values[valuesById[1] = "Stripe"] = 1;
        values[valuesById[2] = "Paymentus"] = 2;
        return values;
    })();

    /**
     * Brand enum.
     * @name payment3_commonpb.Brand
     * @enum {number}
     * @property {number} Undefined_Brand=0 Undefined_Brand value
     * @property {number} HSA=1 HSA value
     * @property {number} AHS=2 AHS value
     * @property {number} ProConnect=3 ProConnect value
     * @property {number} HomeServicePlan=4 HomeServicePlan value
     */
    payment3_commonpb.Brand = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined_Brand"] = 0;
        values[valuesById[1] = "HSA"] = 1;
        values[valuesById[2] = "AHS"] = 2;
        values[valuesById[3] = "ProConnect"] = 3;
        values[valuesById[4] = "HomeServicePlan"] = 4;
        return values;
    })();

    payment3_commonpb.DatabaseRecord = (function() {

        /**
         * Properties of a DatabaseRecord.
         * @memberof payment3_commonpb
         * @interface IDatabaseRecord
         * @property {google.protobuf.ITimestamp|null} [deletedAt] DatabaseRecord deletedAt
         * @property {google.protobuf.ITimestamp|null} [createdAt] DatabaseRecord createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] DatabaseRecord updatedAt
         */

        /**
         * Constructs a new DatabaseRecord.
         * @memberof payment3_commonpb
         * @classdesc Represents a DatabaseRecord.
         * @implements IDatabaseRecord
         * @constructor
         * @param {payment3_commonpb.IDatabaseRecord=} [p] Properties to set
         */
        function DatabaseRecord(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DatabaseRecord deletedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} deletedAt
         * @memberof payment3_commonpb.DatabaseRecord
         * @instance
         */
        DatabaseRecord.prototype.deletedAt = null;

        /**
         * DatabaseRecord createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof payment3_commonpb.DatabaseRecord
         * @instance
         */
        DatabaseRecord.prototype.createdAt = null;

        /**
         * DatabaseRecord updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof payment3_commonpb.DatabaseRecord
         * @instance
         */
        DatabaseRecord.prototype.updatedAt = null;

        /**
         * Creates a new DatabaseRecord instance using the specified properties.
         * @function create
         * @memberof payment3_commonpb.DatabaseRecord
         * @static
         * @param {payment3_commonpb.IDatabaseRecord=} [properties] Properties to set
         * @returns {payment3_commonpb.DatabaseRecord} DatabaseRecord instance
         */
        DatabaseRecord.create = function create(properties) {
            return new DatabaseRecord(properties);
        };

        /**
         * Encodes the specified DatabaseRecord message. Does not implicitly {@link payment3_commonpb.DatabaseRecord.verify|verify} messages.
         * @function encode
         * @memberof payment3_commonpb.DatabaseRecord
         * @static
         * @param {payment3_commonpb.IDatabaseRecord} m DatabaseRecord message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DatabaseRecord.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.deletedAt != null && Object.hasOwnProperty.call(m, "deletedAt"))
                $root.google.protobuf.Timestamp.encode(m.deletedAt, w.uint32(10).fork()).ldelim();
            if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(18).fork()).ldelim();
            if (m.updatedAt != null && Object.hasOwnProperty.call(m, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(m.updatedAt, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a DatabaseRecord message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_commonpb.DatabaseRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_commonpb.DatabaseRecord} DatabaseRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DatabaseRecord.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_commonpb.DatabaseRecord();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.deletedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                case 2:
                    m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                case 3:
                    m.updatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return DatabaseRecord;
    })();

    return payment3_commonpb;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [p] Properties to set
             */
            function FileDescriptorSet(p) {
                this.file = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} m FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.file != null && m.file.length) {
                    for (var i = 0; i < m.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(m.file[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileDescriptorSet();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.file && m.file.length))
                            m.file = [];
                        m.file.push($root.google.protobuf.FileDescriptorProto.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [p] Properties to set
             */
            function FileDescriptorProto(p) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} m FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m["package"] != null && Object.hasOwnProperty.call(m, "package"))
                    w.uint32(18).string(m["package"]);
                if (m.dependency != null && m.dependency.length) {
                    for (var i = 0; i < m.dependency.length; ++i)
                        w.uint32(26).string(m.dependency[i]);
                }
                if (m.messageType != null && m.messageType.length) {
                    for (var i = 0; i < m.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(m.messageType[i], w.uint32(34).fork()).ldelim();
                }
                if (m.enumType != null && m.enumType.length) {
                    for (var i = 0; i < m.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(m.enumType[i], w.uint32(42).fork()).ldelim();
                }
                if (m.service != null && m.service.length) {
                    for (var i = 0; i < m.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(m.service[i], w.uint32(50).fork()).ldelim();
                }
                if (m.extension != null && m.extension.length) {
                    for (var i = 0; i < m.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.extension[i], w.uint32(58).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.FileOptions.encode(m.options, w.uint32(66).fork()).ldelim();
                if (m.sourceCodeInfo != null && Object.hasOwnProperty.call(m, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(m.sourceCodeInfo, w.uint32(74).fork()).ldelim();
                if (m.publicDependency != null && m.publicDependency.length) {
                    for (var i = 0; i < m.publicDependency.length; ++i)
                        w.uint32(80).int32(m.publicDependency[i]);
                }
                if (m.weakDependency != null && m.weakDependency.length) {
                    for (var i = 0; i < m.weakDependency.length; ++i)
                        w.uint32(88).int32(m.weakDependency[i]);
                }
                if (m.syntax != null && Object.hasOwnProperty.call(m, "syntax"))
                    w.uint32(98).string(m.syntax);
                return w;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m["package"] = r.string();
                        break;
                    case 3:
                        if (!(m.dependency && m.dependency.length))
                            m.dependency = [];
                        m.dependency.push(r.string());
                        break;
                    case 10:
                        if (!(m.publicDependency && m.publicDependency.length))
                            m.publicDependency = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.publicDependency.push(r.int32());
                        } else
                            m.publicDependency.push(r.int32());
                        break;
                    case 11:
                        if (!(m.weakDependency && m.weakDependency.length))
                            m.weakDependency = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.weakDependency.push(r.int32());
                        } else
                            m.weakDependency.push(r.int32());
                        break;
                    case 4:
                        if (!(m.messageType && m.messageType.length))
                            m.messageType = [];
                        m.messageType.push($root.google.protobuf.DescriptorProto.decode(r, r.uint32()));
                        break;
                    case 5:
                        if (!(m.enumType && m.enumType.length))
                            m.enumType = [];
                        m.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 6:
                        if (!(m.service && m.service.length))
                            m.service = [];
                        m.service.push($root.google.protobuf.ServiceDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 7:
                        if (!(m.extension && m.extension.length))
                            m.extension = [];
                        m.extension.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 8:
                        m.options = $root.google.protobuf.FileOptions.decode(r, r.uint32());
                        break;
                    case 9:
                        m.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(r, r.uint32());
                        break;
                    case 12:
                        m.syntax = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [p] Properties to set
             */
            function DescriptorProto(p) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} m DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.field != null && m.field.length) {
                    for (var i = 0; i < m.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.field[i], w.uint32(18).fork()).ldelim();
                }
                if (m.nestedType != null && m.nestedType.length) {
                    for (var i = 0; i < m.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(m.nestedType[i], w.uint32(26).fork()).ldelim();
                }
                if (m.enumType != null && m.enumType.length) {
                    for (var i = 0; i < m.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(m.enumType[i], w.uint32(34).fork()).ldelim();
                }
                if (m.extensionRange != null && m.extensionRange.length) {
                    for (var i = 0; i < m.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(m.extensionRange[i], w.uint32(42).fork()).ldelim();
                }
                if (m.extension != null && m.extension.length) {
                    for (var i = 0; i < m.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.extension[i], w.uint32(50).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.MessageOptions.encode(m.options, w.uint32(58).fork()).ldelim();
                if (m.oneofDecl != null && m.oneofDecl.length) {
                    for (var i = 0; i < m.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(m.oneofDecl[i], w.uint32(66).fork()).ldelim();
                }
                if (m.reservedRange != null && m.reservedRange.length) {
                    for (var i = 0; i < m.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(m.reservedRange[i], w.uint32(74).fork()).ldelim();
                }
                if (m.reservedName != null && m.reservedName.length) {
                    for (var i = 0; i < m.reservedName.length; ++i)
                        w.uint32(82).string(m.reservedName[i]);
                }
                return w;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        if (!(m.field && m.field.length))
                            m.field = [];
                        m.field.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 6:
                        if (!(m.extension && m.extension.length))
                            m.extension = [];
                        m.extension.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 3:
                        if (!(m.nestedType && m.nestedType.length))
                            m.nestedType = [];
                        m.nestedType.push($root.google.protobuf.DescriptorProto.decode(r, r.uint32()));
                        break;
                    case 4:
                        if (!(m.enumType && m.enumType.length))
                            m.enumType = [];
                        m.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 5:
                        if (!(m.extensionRange && m.extensionRange.length))
                            m.extensionRange = [];
                        m.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(r, r.uint32()));
                        break;
                    case 8:
                        if (!(m.oneofDecl && m.oneofDecl.length))
                            m.oneofDecl = [];
                        m.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 7:
                        m.options = $root.google.protobuf.MessageOptions.decode(r, r.uint32());
                        break;
                    case 9:
                        if (!(m.reservedRange && m.reservedRange.length))
                            m.reservedRange = [];
                        m.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(r, r.uint32()));
                        break;
                    case 10:
                        if (!(m.reservedName && m.reservedName.length))
                            m.reservedName = [];
                        m.reservedName.push(r.string());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [p] Properties to set
                 */
                function ExtensionRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} m ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                        w.uint32(8).int32(m.start);
                    if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                        w.uint32(16).int32(m.end);
                    return w;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.start = r.int32();
                            break;
                        case 2:
                            m.end = r.int32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [p] Properties to set
                 */
                function ReservedRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} m ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                        w.uint32(8).int32(m.start);
                    if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                        w.uint32(16).int32(m.end);
                    return w;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.start = r.int32();
                            break;
                        case 2:
                            m.end = r.int32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [p] Properties to set
             */
            function FieldDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} m FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.extendee != null && Object.hasOwnProperty.call(m, "extendee"))
                    w.uint32(18).string(m.extendee);
                if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                    w.uint32(24).int32(m.number);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(32).int32(m.label);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(40).int32(m.type);
                if (m.typeName != null && Object.hasOwnProperty.call(m, "typeName"))
                    w.uint32(50).string(m.typeName);
                if (m.defaultValue != null && Object.hasOwnProperty.call(m, "defaultValue"))
                    w.uint32(58).string(m.defaultValue);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.FieldOptions.encode(m.options, w.uint32(66).fork()).ldelim();
                if (m.oneofIndex != null && Object.hasOwnProperty.call(m, "oneofIndex"))
                    w.uint32(72).int32(m.oneofIndex);
                if (m.jsonName != null && Object.hasOwnProperty.call(m, "jsonName"))
                    w.uint32(82).string(m.jsonName);
                return w;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FieldDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 3:
                        m.number = r.int32();
                        break;
                    case 4:
                        m.label = r.int32();
                        break;
                    case 5:
                        m.type = r.int32();
                        break;
                    case 6:
                        m.typeName = r.string();
                        break;
                    case 2:
                        m.extendee = r.string();
                        break;
                    case 7:
                        m.defaultValue = r.string();
                        break;
                    case 9:
                        m.oneofIndex = r.int32();
                        break;
                    case 10:
                        m.jsonName = r.string();
                        break;
                    case 8:
                        m.options = $root.google.protobuf.FieldOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [p] Properties to set
             */
            function OneofDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} m OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.OneofOptions.encode(m.options, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.OneofDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.options = $root.google.protobuf.OneofOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [p] Properties to set
             */
            function EnumDescriptorProto(p) {
                this.value = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} m EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.value != null && m.value.length) {
                    for (var i = 0; i < m.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(m.value[i], w.uint32(18).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.EnumOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        if (!(m.value && m.value.length))
                            m.value = [];
                        m.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 3:
                        m.options = $root.google.protobuf.EnumOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [p] Properties to set
             */
            function EnumValueDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} m EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                    w.uint32(16).int32(m.number);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumValueDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.number = r.int32();
                        break;
                    case 3:
                        m.options = $root.google.protobuf.EnumValueOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [p] Properties to set
             */
            function ServiceDescriptorProto(p) {
                this.method = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} m ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.method != null && m.method.length) {
                    for (var i = 0; i < m.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(m.method[i], w.uint32(18).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.ServiceOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ServiceDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        if (!(m.method && m.method.length))
                            m.method = [];
                        m.method.push($root.google.protobuf.MethodDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 3:
                        m.options = $root.google.protobuf.ServiceOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [p] Properties to set
             */
            function MethodDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} m MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.inputType != null && Object.hasOwnProperty.call(m, "inputType"))
                    w.uint32(18).string(m.inputType);
                if (m.outputType != null && Object.hasOwnProperty.call(m, "outputType"))
                    w.uint32(26).string(m.outputType);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.MethodOptions.encode(m.options, w.uint32(34).fork()).ldelim();
                if (m.clientStreaming != null && Object.hasOwnProperty.call(m, "clientStreaming"))
                    w.uint32(40).bool(m.clientStreaming);
                if (m.serverStreaming != null && Object.hasOwnProperty.call(m, "serverStreaming"))
                    w.uint32(48).bool(m.serverStreaming);
                return w;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MethodDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.inputType = r.string();
                        break;
                    case 3:
                        m.outputType = r.string();
                        break;
                    case 4:
                        m.options = $root.google.protobuf.MethodOptions.decode(r, r.uint32());
                        break;
                    case 5:
                        m.clientStreaming = r.bool();
                        break;
                    case 6:
                        m.serverStreaming = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             * @property {string|null} [".common.serviceCalls"] FileOptions .common.serviceCalls
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [p] Properties to set
             */
            function FileOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FileOptions .common.serviceCalls.
             * @member {string} .common.serviceCalls
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype[".common.serviceCalls"] = "";

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} m FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.javaPackage != null && Object.hasOwnProperty.call(m, "javaPackage"))
                    w.uint32(10).string(m.javaPackage);
                if (m.javaOuterClassname != null && Object.hasOwnProperty.call(m, "javaOuterClassname"))
                    w.uint32(66).string(m.javaOuterClassname);
                if (m.optimizeFor != null && Object.hasOwnProperty.call(m, "optimizeFor"))
                    w.uint32(72).int32(m.optimizeFor);
                if (m.javaMultipleFiles != null && Object.hasOwnProperty.call(m, "javaMultipleFiles"))
                    w.uint32(80).bool(m.javaMultipleFiles);
                if (m.goPackage != null && Object.hasOwnProperty.call(m, "goPackage"))
                    w.uint32(90).string(m.goPackage);
                if (m.ccGenericServices != null && Object.hasOwnProperty.call(m, "ccGenericServices"))
                    w.uint32(128).bool(m.ccGenericServices);
                if (m.javaGenericServices != null && Object.hasOwnProperty.call(m, "javaGenericServices"))
                    w.uint32(136).bool(m.javaGenericServices);
                if (m.pyGenericServices != null && Object.hasOwnProperty.call(m, "pyGenericServices"))
                    w.uint32(144).bool(m.pyGenericServices);
                if (m.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(m, "javaGenerateEqualsAndHash"))
                    w.uint32(160).bool(m.javaGenerateEqualsAndHash);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(184).bool(m.deprecated);
                if (m.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(m, "javaStringCheckUtf8"))
                    w.uint32(216).bool(m.javaStringCheckUtf8);
                if (m.ccEnableArenas != null && Object.hasOwnProperty.call(m, "ccEnableArenas"))
                    w.uint32(248).bool(m.ccEnableArenas);
                if (m.objcClassPrefix != null && Object.hasOwnProperty.call(m, "objcClassPrefix"))
                    w.uint32(290).string(m.objcClassPrefix);
                if (m.csharpNamespace != null && Object.hasOwnProperty.call(m, "csharpNamespace"))
                    w.uint32(298).string(m.csharpNamespace);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                if (m[".common.serviceCalls"] != null && Object.hasOwnProperty.call(m, ".common.serviceCalls"))
                    w.uint32(400034).string(m[".common.serviceCalls"]);
                return w;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.javaPackage = r.string();
                        break;
                    case 8:
                        m.javaOuterClassname = r.string();
                        break;
                    case 10:
                        m.javaMultipleFiles = r.bool();
                        break;
                    case 20:
                        m.javaGenerateEqualsAndHash = r.bool();
                        break;
                    case 27:
                        m.javaStringCheckUtf8 = r.bool();
                        break;
                    case 9:
                        m.optimizeFor = r.int32();
                        break;
                    case 11:
                        m.goPackage = r.string();
                        break;
                    case 16:
                        m.ccGenericServices = r.bool();
                        break;
                    case 17:
                        m.javaGenericServices = r.bool();
                        break;
                    case 18:
                        m.pyGenericServices = r.bool();
                        break;
                    case 23:
                        m.deprecated = r.bool();
                        break;
                    case 31:
                        m.ccEnableArenas = r.bool();
                        break;
                    case 36:
                        m.objcClassPrefix = r.string();
                        break;
                    case 37:
                        m.csharpNamespace = r.string();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    case 50004:
                        m[".common.serviceCalls"] = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [p] Properties to set
             */
            function MessageOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} m MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.messageSetWireFormat != null && Object.hasOwnProperty.call(m, "messageSetWireFormat"))
                    w.uint32(8).bool(m.messageSetWireFormat);
                if (m.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(m, "noStandardDescriptorAccessor"))
                    w.uint32(16).bool(m.noStandardDescriptorAccessor);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.mapEntry != null && Object.hasOwnProperty.call(m, "mapEntry"))
                    w.uint32(56).bool(m.mapEntry);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MessageOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.messageSetWireFormat = r.bool();
                        break;
                    case 2:
                        m.noStandardDescriptorAccessor = r.bool();
                        break;
                    case 3:
                        m.deprecated = r.bool();
                        break;
                    case 7:
                        m.mapEntry = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [p] Properties to set
             */
            function FieldOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} m FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ctype != null && Object.hasOwnProperty.call(m, "ctype"))
                    w.uint32(8).int32(m.ctype);
                if (m.packed != null && Object.hasOwnProperty.call(m, "packed"))
                    w.uint32(16).bool(m.packed);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.lazy != null && Object.hasOwnProperty.call(m, "lazy"))
                    w.uint32(40).bool(m.lazy);
                if (m.jstype != null && Object.hasOwnProperty.call(m, "jstype"))
                    w.uint32(48).int32(m.jstype);
                if (m.weak != null && Object.hasOwnProperty.call(m, "weak"))
                    w.uint32(80).bool(m.weak);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FieldOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.ctype = r.int32();
                        break;
                    case 2:
                        m.packed = r.bool();
                        break;
                    case 6:
                        m.jstype = r.int32();
                        break;
                    case 5:
                        m.lazy = r.bool();
                        break;
                    case 3:
                        m.deprecated = r.bool();
                        break;
                    case 10:
                        m.weak = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [p] Properties to set
             */
            function OneofOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} m OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.OneofOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [p] Properties to set
             */
            function EnumOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} m EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.allowAlias != null && Object.hasOwnProperty.call(m, "allowAlias"))
                    w.uint32(16).bool(m.allowAlias);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2:
                        m.allowAlias = r.bool();
                        break;
                    case 3:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [p] Properties to set
             */
            function EnumValueOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} m EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(8).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumValueOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             * @property {string|null} [".common.URI"] ServiceOptions .common.URI
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [p] Properties to set
             */
            function ServiceOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * ServiceOptions .common.URI.
             * @member {string} .common.URI
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype[".common.URI"] = "";

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} m ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(264).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                if (m[".common.URI"] != null && Object.hasOwnProperty.call(m, ".common.URI"))
                    w.uint32(400010).string(m[".common.URI"]);
                return w;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ServiceOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 33:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    case 50001:
                        m[".common.URI"] = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             * @property {boolean|null} [".common.allowJson"] MethodOptions .common.allowJson
             * @property {string|null} [".common.methodCalls"] MethodOptions .common.methodCalls
             * @property {string|null} [".common.httpMethod"] MethodOptions .common.httpMethod
             * @property {string|null} [".common.methodURI"] MethodOptions .common.methodURI
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [p] Properties to set
             */
            function MethodOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MethodOptions .common.allowJson.
             * @member {boolean} .common.allowJson
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.allowJson"] = false;

            /**
             * MethodOptions .common.methodCalls.
             * @member {string} .common.methodCalls
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.methodCalls"] = "";

            /**
             * MethodOptions .common.httpMethod.
             * @member {string} .common.httpMethod
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.httpMethod"] = "";

            /**
             * MethodOptions .common.methodURI.
             * @member {string} .common.methodURI
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.methodURI"] = "";

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} m MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(264).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                if (m[".common.allowJson"] != null && Object.hasOwnProperty.call(m, ".common.allowJson"))
                    w.uint32(400000).bool(m[".common.allowJson"]);
                if (m[".common.methodCalls"] != null && Object.hasOwnProperty.call(m, ".common.methodCalls"))
                    w.uint32(400018).string(m[".common.methodCalls"]);
                if (m[".common.httpMethod"] != null && Object.hasOwnProperty.call(m, ".common.httpMethod"))
                    w.uint32(400026).string(m[".common.httpMethod"]);
                if (m[".common.methodURI"] != null && Object.hasOwnProperty.call(m, ".common.methodURI"))
                    w.uint32(400042).string(m[".common.methodURI"]);
                return w;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MethodOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 33:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    case 50000:
                        m[".common.allowJson"] = r.bool();
                        break;
                    case 50002:
                        m[".common.methodCalls"] = r.string();
                        break;
                    case 50003:
                        m[".common.httpMethod"] = r.string();
                        break;
                    case 50005:
                        m[".common.methodURI"] = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [p] Properties to set
             */
            function UninterpretedOption(p) {
                this.name = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} m UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.name.length) {
                    for (var i = 0; i < m.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(m.name[i], w.uint32(18).fork()).ldelim();
                }
                if (m.identifierValue != null && Object.hasOwnProperty.call(m, "identifierValue"))
                    w.uint32(26).string(m.identifierValue);
                if (m.positiveIntValue != null && Object.hasOwnProperty.call(m, "positiveIntValue"))
                    w.uint32(32).uint64(m.positiveIntValue);
                if (m.negativeIntValue != null && Object.hasOwnProperty.call(m, "negativeIntValue"))
                    w.uint32(40).int64(m.negativeIntValue);
                if (m.doubleValue != null && Object.hasOwnProperty.call(m, "doubleValue"))
                    w.uint32(49).double(m.doubleValue);
                if (m.stringValue != null && Object.hasOwnProperty.call(m, "stringValue"))
                    w.uint32(58).bytes(m.stringValue);
                if (m.aggregateValue != null && Object.hasOwnProperty.call(m, "aggregateValue"))
                    w.uint32(66).string(m.aggregateValue);
                return w;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UninterpretedOption();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2:
                        if (!(m.name && m.name.length))
                            m.name = [];
                        m.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(r, r.uint32()));
                        break;
                    case 3:
                        m.identifierValue = r.string();
                        break;
                    case 4:
                        m.positiveIntValue = r.uint64();
                        break;
                    case 5:
                        m.negativeIntValue = r.int64();
                        break;
                    case 6:
                        m.doubleValue = r.double();
                        break;
                    case 7:
                        m.stringValue = r.bytes();
                        break;
                    case 8:
                        m.aggregateValue = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [p] Properties to set
                 */
                function NamePart(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} m NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    w.uint32(10).string(m.namePart);
                    w.uint32(16).bool(m.isExtension);
                    return w;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.namePart = r.string();
                            break;
                        case 2:
                            m.isExtension = r.bool();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    if (!m.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: m });
                    if (!m.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: m });
                    return m;
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [p] Properties to set
             */
            function SourceCodeInfo(p) {
                this.location = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} m SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.location != null && m.location.length) {
                    for (var i = 0; i < m.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(m.location[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.SourceCodeInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.location && m.location.length))
                            m.location = [];
                        m.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [p] Properties to set
                 */
                function Location(p) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} m Location message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.path != null && m.path.length) {
                        w.uint32(10).fork();
                        for (var i = 0; i < m.path.length; ++i)
                            w.int32(m.path[i]);
                        w.ldelim();
                    }
                    if (m.span != null && m.span.length) {
                        w.uint32(18).fork();
                        for (var i = 0; i < m.span.length; ++i)
                            w.int32(m.span[i]);
                        w.ldelim();
                    }
                    if (m.leadingComments != null && Object.hasOwnProperty.call(m, "leadingComments"))
                        w.uint32(26).string(m.leadingComments);
                    if (m.trailingComments != null && Object.hasOwnProperty.call(m, "trailingComments"))
                        w.uint32(34).string(m.trailingComments);
                    if (m.leadingDetachedComments != null && m.leadingDetachedComments.length) {
                        for (var i = 0; i < m.leadingDetachedComments.length; ++i)
                            w.uint32(50).string(m.leadingDetachedComments[i]);
                    }
                    return w;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            if (!(m.path && m.path.length))
                                m.path = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.path.push(r.int32());
                            } else
                                m.path.push(r.int32());
                            break;
                        case 2:
                            if (!(m.span && m.span.length))
                                m.span = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.span.push(r.int32());
                            } else
                                m.span.push(r.int32());
                            break;
                        case 3:
                            m.leadingComments = r.string();
                            break;
                        case 4:
                            m.trailingComments = r.string();
                            break;
                        case 6:
                            if (!(m.leadingDetachedComments && m.leadingDetachedComments.length))
                                m.leadingDetachedComments = [];
                            m.leadingDetachedComments.push(r.string());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [p] Properties to set
             */
            function GeneratedCodeInfo(p) {
                this.annotation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} m GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.annotation != null && m.annotation.length) {
                    for (var i = 0; i < m.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(m.annotation[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.GeneratedCodeInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.annotation && m.annotation.length))
                            m.annotation = [];
                        m.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [p] Properties to set
                 */
                function Annotation(p) {
                    this.path = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} m Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.path != null && m.path.length) {
                        w.uint32(10).fork();
                        for (var i = 0; i < m.path.length; ++i)
                            w.int32(m.path[i]);
                        w.ldelim();
                    }
                    if (m.sourceFile != null && Object.hasOwnProperty.call(m, "sourceFile"))
                        w.uint32(18).string(m.sourceFile);
                    if (m.begin != null && Object.hasOwnProperty.call(m, "begin"))
                        w.uint32(24).int32(m.begin);
                    if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                        w.uint32(32).int32(m.end);
                    return w;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            if (!(m.path && m.path.length))
                                m.path = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.path.push(r.int32());
                            } else
                                m.path.push(r.int32());
                            break;
                        case 2:
                            m.sourceFile = r.string();
                            break;
                        case 3:
                            m.begin = r.int32();
                            break;
                        case 4:
                            m.end = r.int32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [p] Properties to set
             */
            function Timestamp(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} m Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.seconds != null && Object.hasOwnProperty.call(m, "seconds"))
                    w.uint32(8).int64(m.seconds);
                if (m.nanos != null && Object.hasOwnProperty.call(m, "nanos"))
                    w.uint32(16).int32(m.nanos);
                return w;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Timestamp();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.seconds = r.int64();
                        break;
                    case 2:
                        m.nanos = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export const payment3_paymentmethodpb = $root.payment3_paymentmethodpb = (() => {

    /**
     * Namespace payment3_paymentmethodpb.
     * @exports payment3_paymentmethodpb
     * @namespace
     */
    const payment3_paymentmethodpb = {};

    payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest = (function() {

        /**
         * Properties of a CreateUpdatePaymentMethodRequest.
         * @memberof payment3_paymentmethodpb
         * @interface ICreateUpdatePaymentMethodRequest
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] CreateUpdatePaymentMethodRequest paymentMethod
         */

        /**
         * Constructs a new CreateUpdatePaymentMethodRequest.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a CreateUpdatePaymentMethodRequest.
         * @implements ICreateUpdatePaymentMethodRequest
         * @constructor
         * @param {payment3_paymentmethodpb.ICreateUpdatePaymentMethodRequest=} [p] Properties to set
         */
        function CreateUpdatePaymentMethodRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreateUpdatePaymentMethodRequest paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest
         * @instance
         */
        CreateUpdatePaymentMethodRequest.prototype.paymentMethod = null;

        /**
         * Creates a new CreateUpdatePaymentMethodRequest instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest
         * @static
         * @param {payment3_paymentmethodpb.ICreateUpdatePaymentMethodRequest=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest} CreateUpdatePaymentMethodRequest instance
         */
        CreateUpdatePaymentMethodRequest.create = function create(properties) {
            return new CreateUpdatePaymentMethodRequest(properties);
        };

        /**
         * Encodes the specified CreateUpdatePaymentMethodRequest message. Does not implicitly {@link payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest
         * @static
         * @param {payment3_paymentmethodpb.ICreateUpdatePaymentMethodRequest} m CreateUpdatePaymentMethodRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUpdatePaymentMethodRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CreateUpdatePaymentMethodRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest} CreateUpdatePaymentMethodRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUpdatePaymentMethodRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.CreateUpdatePaymentMethodRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreateUpdatePaymentMethodRequest;
    })();

    payment3_paymentmethodpb.CreatePaymentMethodResponse = (function() {

        /**
         * Properties of a CreatePaymentMethodResponse.
         * @memberof payment3_paymentmethodpb
         * @interface ICreatePaymentMethodResponse
         * @property {string|null} [paymentMethodID] CreatePaymentMethodResponse paymentMethodID
         */

        /**
         * Constructs a new CreatePaymentMethodResponse.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a CreatePaymentMethodResponse.
         * @implements ICreatePaymentMethodResponse
         * @constructor
         * @param {payment3_paymentmethodpb.ICreatePaymentMethodResponse=} [p] Properties to set
         */
        function CreatePaymentMethodResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreatePaymentMethodResponse paymentMethodID.
         * @member {string} paymentMethodID
         * @memberof payment3_paymentmethodpb.CreatePaymentMethodResponse
         * @instance
         */
        CreatePaymentMethodResponse.prototype.paymentMethodID = "";

        /**
         * Creates a new CreatePaymentMethodResponse instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.CreatePaymentMethodResponse
         * @static
         * @param {payment3_paymentmethodpb.ICreatePaymentMethodResponse=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.CreatePaymentMethodResponse} CreatePaymentMethodResponse instance
         */
        CreatePaymentMethodResponse.create = function create(properties) {
            return new CreatePaymentMethodResponse(properties);
        };

        /**
         * Encodes the specified CreatePaymentMethodResponse message. Does not implicitly {@link payment3_paymentmethodpb.CreatePaymentMethodResponse.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.CreatePaymentMethodResponse
         * @static
         * @param {payment3_paymentmethodpb.ICreatePaymentMethodResponse} m CreatePaymentMethodResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePaymentMethodResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethodID != null && Object.hasOwnProperty.call(m, "paymentMethodID"))
                w.uint32(10).string(m.paymentMethodID);
            return w;
        };

        /**
         * Decodes a CreatePaymentMethodResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.CreatePaymentMethodResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.CreatePaymentMethodResponse} CreatePaymentMethodResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePaymentMethodResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.CreatePaymentMethodResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentMethodID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreatePaymentMethodResponse;
    })();

    payment3_paymentmethodpb.GetPaymentMethodResponse = (function() {

        /**
         * Properties of a GetPaymentMethodResponse.
         * @memberof payment3_paymentmethodpb
         * @interface IGetPaymentMethodResponse
         * @property {payment3_paymentmethodpb.IPaymentMethod|null} [paymentMethod] GetPaymentMethodResponse paymentMethod
         */

        /**
         * Constructs a new GetPaymentMethodResponse.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a GetPaymentMethodResponse.
         * @implements IGetPaymentMethodResponse
         * @constructor
         * @param {payment3_paymentmethodpb.IGetPaymentMethodResponse=} [p] Properties to set
         */
        function GetPaymentMethodResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GetPaymentMethodResponse paymentMethod.
         * @member {payment3_paymentmethodpb.IPaymentMethod|null|undefined} paymentMethod
         * @memberof payment3_paymentmethodpb.GetPaymentMethodResponse
         * @instance
         */
        GetPaymentMethodResponse.prototype.paymentMethod = null;

        /**
         * Creates a new GetPaymentMethodResponse instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.GetPaymentMethodResponse
         * @static
         * @param {payment3_paymentmethodpb.IGetPaymentMethodResponse=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.GetPaymentMethodResponse} GetPaymentMethodResponse instance
         */
        GetPaymentMethodResponse.create = function create(properties) {
            return new GetPaymentMethodResponse(properties);
        };

        /**
         * Encodes the specified GetPaymentMethodResponse message. Does not implicitly {@link payment3_paymentmethodpb.GetPaymentMethodResponse.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.GetPaymentMethodResponse
         * @static
         * @param {payment3_paymentmethodpb.IGetPaymentMethodResponse} m GetPaymentMethodResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPaymentMethodResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && Object.hasOwnProperty.call(m, "paymentMethod"))
                $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a GetPaymentMethodResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.GetPaymentMethodResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.GetPaymentMethodResponse} GetPaymentMethodResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPaymentMethodResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.GetPaymentMethodResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentMethod = $root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return GetPaymentMethodResponse;
    })();

    payment3_paymentmethodpb.UndeletePaymentMethodRequest = (function() {

        /**
         * Properties of an UndeletePaymentMethodRequest.
         * @memberof payment3_paymentmethodpb
         * @interface IUndeletePaymentMethodRequest
         * @property {Array.<string>|null} [paymentMethodID] UndeletePaymentMethodRequest paymentMethodID
         */

        /**
         * Constructs a new UndeletePaymentMethodRequest.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents an UndeletePaymentMethodRequest.
         * @implements IUndeletePaymentMethodRequest
         * @constructor
         * @param {payment3_paymentmethodpb.IUndeletePaymentMethodRequest=} [p] Properties to set
         */
        function UndeletePaymentMethodRequest(p) {
            this.paymentMethodID = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UndeletePaymentMethodRequest paymentMethodID.
         * @member {Array.<string>} paymentMethodID
         * @memberof payment3_paymentmethodpb.UndeletePaymentMethodRequest
         * @instance
         */
        UndeletePaymentMethodRequest.prototype.paymentMethodID = $util.emptyArray;

        /**
         * Creates a new UndeletePaymentMethodRequest instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.UndeletePaymentMethodRequest
         * @static
         * @param {payment3_paymentmethodpb.IUndeletePaymentMethodRequest=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.UndeletePaymentMethodRequest} UndeletePaymentMethodRequest instance
         */
        UndeletePaymentMethodRequest.create = function create(properties) {
            return new UndeletePaymentMethodRequest(properties);
        };

        /**
         * Encodes the specified UndeletePaymentMethodRequest message. Does not implicitly {@link payment3_paymentmethodpb.UndeletePaymentMethodRequest.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.UndeletePaymentMethodRequest
         * @static
         * @param {payment3_paymentmethodpb.IUndeletePaymentMethodRequest} m UndeletePaymentMethodRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UndeletePaymentMethodRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethodID != null && m.paymentMethodID.length) {
                for (var i = 0; i < m.paymentMethodID.length; ++i)
                    w.uint32(10).string(m.paymentMethodID[i]);
            }
            return w;
        };

        /**
         * Decodes an UndeletePaymentMethodRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.UndeletePaymentMethodRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.UndeletePaymentMethodRequest} UndeletePaymentMethodRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UndeletePaymentMethodRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.UndeletePaymentMethodRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.paymentMethodID && m.paymentMethodID.length))
                        m.paymentMethodID = [];
                    m.paymentMethodID.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return UndeletePaymentMethodRequest;
    })();

    payment3_paymentmethodpb.PaymentMethod = (function() {

        /**
         * Properties of a PaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @interface IPaymentMethod
         * @property {string|null} [paymentMethodID] PaymentMethod paymentMethodID
         * @property {boolean|null} [includedInWallet] PaymentMethod includedInWallet
         * @property {payment3_paymentmethodpb.IPaymentMethodDetails|null} [details] PaymentMethod details
         * @property {payment3_paymentmethodpb.IProcessorPaymentMethod|null} [processor] PaymentMethod processor
         * @property {payment3_paymentmethodpb.IOwner|null} [owner] PaymentMethod owner
         * @property {common.IApplicationSource|null} [source] PaymentMethod source
         * @property {payment3_commonpb.IDatabaseRecord|null} [db] PaymentMethod db
         */

        /**
         * Constructs a new PaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a PaymentMethod.
         * @implements IPaymentMethod
         * @constructor
         * @param {payment3_paymentmethodpb.IPaymentMethod=} [p] Properties to set
         */
        function PaymentMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentMethod paymentMethodID.
         * @member {string} paymentMethodID
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.paymentMethodID = "";

        /**
         * PaymentMethod includedInWallet.
         * @member {boolean} includedInWallet
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.includedInWallet = false;

        /**
         * PaymentMethod details.
         * @member {payment3_paymentmethodpb.IPaymentMethodDetails|null|undefined} details
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.details = null;

        /**
         * PaymentMethod processor.
         * @member {payment3_paymentmethodpb.IProcessorPaymentMethod|null|undefined} processor
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.processor = null;

        /**
         * PaymentMethod owner.
         * @member {payment3_paymentmethodpb.IOwner|null|undefined} owner
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.owner = null;

        /**
         * PaymentMethod source.
         * @member {common.IApplicationSource|null|undefined} source
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.source = null;

        /**
         * PaymentMethod db.
         * @member {payment3_commonpb.IDatabaseRecord|null|undefined} db
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.db = null;

        /**
         * Creates a new PaymentMethod instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IPaymentMethod=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.PaymentMethod} PaymentMethod instance
         */
        PaymentMethod.create = function create(properties) {
            return new PaymentMethod(properties);
        };

        /**
         * Encodes the specified PaymentMethod message. Does not implicitly {@link payment3_paymentmethodpb.PaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IPaymentMethod} m PaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethodID != null && Object.hasOwnProperty.call(m, "paymentMethodID"))
                w.uint32(82).string(m.paymentMethodID);
            if (m.includedInWallet != null && Object.hasOwnProperty.call(m, "includedInWallet"))
                w.uint32(160).bool(m.includedInWallet);
            if (m.details != null && Object.hasOwnProperty.call(m, "details"))
                $root.payment3_paymentmethodpb.PaymentMethodDetails.encode(m.details, w.uint32(802).fork()).ldelim();
            if (m.processor != null && Object.hasOwnProperty.call(m, "processor"))
                $root.payment3_paymentmethodpb.ProcessorPaymentMethod.encode(m.processor, w.uint32(1602).fork()).ldelim();
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                $root.payment3_paymentmethodpb.Owner.encode(m.owner, w.uint32(2402).fork()).ldelim();
            if (m.source != null && Object.hasOwnProperty.call(m, "source"))
                $root.common.ApplicationSource.encode(m.source, w.uint32(6402).fork()).ldelim();
            if (m.db != null && Object.hasOwnProperty.call(m, "db"))
                $root.payment3_commonpb.DatabaseRecord.encode(m.db, w.uint32(7202).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a PaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.PaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.PaymentMethod} PaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.PaymentMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 10:
                    m.paymentMethodID = r.string();
                    break;
                case 20:
                    m.includedInWallet = r.bool();
                    break;
                case 100:
                    m.details = $root.payment3_paymentmethodpb.PaymentMethodDetails.decode(r, r.uint32());
                    break;
                case 200:
                    m.processor = $root.payment3_paymentmethodpb.ProcessorPaymentMethod.decode(r, r.uint32());
                    break;
                case 300:
                    m.owner = $root.payment3_paymentmethodpb.Owner.decode(r, r.uint32());
                    break;
                case 800:
                    m.source = $root.common.ApplicationSource.decode(r, r.uint32());
                    break;
                case 900:
                    m.db = $root.payment3_commonpb.DatabaseRecord.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return PaymentMethod;
    })();

    payment3_paymentmethodpb.PaymentMethodDetails = (function() {

        /**
         * Properties of a PaymentMethodDetails.
         * @memberof payment3_paymentmethodpb
         * @interface IPaymentMethodDetails
         * @property {payment3_paymentmethodpb.PaymentMethodType|null} [type] PaymentMethodDetails type
         * @property {payment3_paymentmethodpb.ICreditCardDetails|null} [cc] PaymentMethodDetails cc
         * @property {payment3_paymentmethodpb.IACHDetails|null} [ach] PaymentMethodDetails ach
         */

        /**
         * Constructs a new PaymentMethodDetails.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a PaymentMethodDetails.
         * @implements IPaymentMethodDetails
         * @constructor
         * @param {payment3_paymentmethodpb.IPaymentMethodDetails=} [p] Properties to set
         */
        function PaymentMethodDetails(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentMethodDetails type.
         * @member {payment3_paymentmethodpb.PaymentMethodType} type
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @instance
         */
        PaymentMethodDetails.prototype.type = 0;

        /**
         * PaymentMethodDetails cc.
         * @member {payment3_paymentmethodpb.ICreditCardDetails|null|undefined} cc
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @instance
         */
        PaymentMethodDetails.prototype.cc = null;

        /**
         * PaymentMethodDetails ach.
         * @member {payment3_paymentmethodpb.IACHDetails|null|undefined} ach
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @instance
         */
        PaymentMethodDetails.prototype.ach = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PaymentMethodDetails details.
         * @member {"cc"|"ach"|undefined} details
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @instance
         */
        Object.defineProperty(PaymentMethodDetails.prototype, "details", {
            get: $util.oneOfGetter($oneOfFields = ["cc", "ach"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PaymentMethodDetails instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @static
         * @param {payment3_paymentmethodpb.IPaymentMethodDetails=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.PaymentMethodDetails} PaymentMethodDetails instance
         */
        PaymentMethodDetails.create = function create(properties) {
            return new PaymentMethodDetails(properties);
        };

        /**
         * Encodes the specified PaymentMethodDetails message. Does not implicitly {@link payment3_paymentmethodpb.PaymentMethodDetails.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @static
         * @param {payment3_paymentmethodpb.IPaymentMethodDetails} m PaymentMethodDetails message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentMethodDetails.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(800).int32(m.type);
            if (m.cc != null && Object.hasOwnProperty.call(m, "cc"))
                $root.payment3_paymentmethodpb.CreditCardDetails.encode(m.cc, w.uint32(810).fork()).ldelim();
            if (m.ach != null && Object.hasOwnProperty.call(m, "ach"))
                $root.payment3_paymentmethodpb.ACHDetails.encode(m.ach, w.uint32(818).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a PaymentMethodDetails message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.PaymentMethodDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.PaymentMethodDetails} PaymentMethodDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentMethodDetails.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.PaymentMethodDetails();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.type = r.int32();
                    break;
                case 101:
                    m.cc = $root.payment3_paymentmethodpb.CreditCardDetails.decode(r, r.uint32());
                    break;
                case 102:
                    m.ach = $root.payment3_paymentmethodpb.ACHDetails.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return PaymentMethodDetails;
    })();

    /**
     * PaymentMethodType enum.
     * @name payment3_paymentmethodpb.PaymentMethodType
     * @enum {number}
     * @property {number} Undefined_PaymentMethodType=0 Undefined_PaymentMethodType value
     * @property {number} CreditCard=1 CreditCard value
     * @property {number} ACH=2 ACH value
     */
    payment3_paymentmethodpb.PaymentMethodType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined_PaymentMethodType"] = 0;
        values[valuesById[1] = "CreditCard"] = 1;
        values[valuesById[2] = "ACH"] = 2;
        return values;
    })();

    payment3_paymentmethodpb.CreditCardDetails = (function() {

        /**
         * Properties of a CreditCardDetails.
         * @memberof payment3_paymentmethodpb
         * @interface ICreditCardDetails
         * @property {string|null} [last4] CreditCardDetails last4
         * @property {common.IDate|null} [expirationDate] CreditCardDetails expirationDate
         * @property {payment3_paymentmethodpb.CreditCardBrand|null} [brand] CreditCardDetails brand
         * @property {payment3_paymentmethodpb.ICreditCardBillingDetails|null} [billingDetails] CreditCardDetails billingDetails
         */

        /**
         * Constructs a new CreditCardDetails.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a CreditCardDetails.
         * @implements ICreditCardDetails
         * @constructor
         * @param {payment3_paymentmethodpb.ICreditCardDetails=} [p] Properties to set
         */
        function CreditCardDetails(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreditCardDetails last4.
         * @member {string} last4
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @instance
         */
        CreditCardDetails.prototype.last4 = "";

        /**
         * CreditCardDetails expirationDate.
         * @member {common.IDate|null|undefined} expirationDate
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @instance
         */
        CreditCardDetails.prototype.expirationDate = null;

        /**
         * CreditCardDetails brand.
         * @member {payment3_paymentmethodpb.CreditCardBrand} brand
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @instance
         */
        CreditCardDetails.prototype.brand = 0;

        /**
         * CreditCardDetails billingDetails.
         * @member {payment3_paymentmethodpb.ICreditCardBillingDetails|null|undefined} billingDetails
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @instance
         */
        CreditCardDetails.prototype.billingDetails = null;

        /**
         * Creates a new CreditCardDetails instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @static
         * @param {payment3_paymentmethodpb.ICreditCardDetails=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.CreditCardDetails} CreditCardDetails instance
         */
        CreditCardDetails.create = function create(properties) {
            return new CreditCardDetails(properties);
        };

        /**
         * Encodes the specified CreditCardDetails message. Does not implicitly {@link payment3_paymentmethodpb.CreditCardDetails.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @static
         * @param {payment3_paymentmethodpb.ICreditCardDetails} m CreditCardDetails message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardDetails.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.last4 != null && Object.hasOwnProperty.call(m, "last4"))
                w.uint32(10).string(m.last4);
            if (m.expirationDate != null && Object.hasOwnProperty.call(m, "expirationDate"))
                $root.common.Date.encode(m.expirationDate, w.uint32(18).fork()).ldelim();
            if (m.brand != null && Object.hasOwnProperty.call(m, "brand"))
                w.uint32(24).int32(m.brand);
            if (m.billingDetails != null && Object.hasOwnProperty.call(m, "billingDetails"))
                $root.payment3_paymentmethodpb.CreditCardBillingDetails.encode(m.billingDetails, w.uint32(34).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CreditCardDetails message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.CreditCardDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.CreditCardDetails} CreditCardDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardDetails.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.CreditCardDetails();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.last4 = r.string();
                    break;
                case 2:
                    m.expirationDate = $root.common.Date.decode(r, r.uint32());
                    break;
                case 3:
                    m.brand = r.int32();
                    break;
                case 4:
                    m.billingDetails = $root.payment3_paymentmethodpb.CreditCardBillingDetails.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreditCardDetails;
    })();

    payment3_paymentmethodpb.CreditCardBillingDetails = (function() {

        /**
         * Properties of a CreditCardBillingDetails.
         * @memberof payment3_paymentmethodpb
         * @interface ICreditCardBillingDetails
         * @property {string|null} [country] CreditCardBillingDetails country
         * @property {string|null} [name] CreditCardBillingDetails name
         * @property {string|null} [city] CreditCardBillingDetails city
         * @property {string|null} [state] CreditCardBillingDetails state
         * @property {string|null} [zip] CreditCardBillingDetails zip
         * @property {string|null} [phone] CreditCardBillingDetails phone
         * @property {string|null} [email] CreditCardBillingDetails email
         * @property {string|null} [addressLine1] CreditCardBillingDetails addressLine1
         * @property {string|null} [addressLine2] CreditCardBillingDetails addressLine2
         */

        /**
         * Constructs a new CreditCardBillingDetails.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a CreditCardBillingDetails.
         * @implements ICreditCardBillingDetails
         * @constructor
         * @param {payment3_paymentmethodpb.ICreditCardBillingDetails=} [p] Properties to set
         */
        function CreditCardBillingDetails(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreditCardBillingDetails country.
         * @member {string} country
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.country = "";

        /**
         * CreditCardBillingDetails name.
         * @member {string} name
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.name = "";

        /**
         * CreditCardBillingDetails city.
         * @member {string} city
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.city = "";

        /**
         * CreditCardBillingDetails state.
         * @member {string} state
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.state = "";

        /**
         * CreditCardBillingDetails zip.
         * @member {string} zip
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.zip = "";

        /**
         * CreditCardBillingDetails phone.
         * @member {string} phone
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.phone = "";

        /**
         * CreditCardBillingDetails email.
         * @member {string} email
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.email = "";

        /**
         * CreditCardBillingDetails addressLine1.
         * @member {string} addressLine1
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.addressLine1 = "";

        /**
         * CreditCardBillingDetails addressLine2.
         * @member {string} addressLine2
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @instance
         */
        CreditCardBillingDetails.prototype.addressLine2 = "";

        /**
         * Creates a new CreditCardBillingDetails instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @static
         * @param {payment3_paymentmethodpb.ICreditCardBillingDetails=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.CreditCardBillingDetails} CreditCardBillingDetails instance
         */
        CreditCardBillingDetails.create = function create(properties) {
            return new CreditCardBillingDetails(properties);
        };

        /**
         * Encodes the specified CreditCardBillingDetails message. Does not implicitly {@link payment3_paymentmethodpb.CreditCardBillingDetails.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @static
         * @param {payment3_paymentmethodpb.ICreditCardBillingDetails} m CreditCardBillingDetails message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardBillingDetails.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(10).string(m.country);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(34).string(m.city);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(42).string(m.state);
            if (m.zip != null && Object.hasOwnProperty.call(m, "zip"))
                w.uint32(50).string(m.zip);
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(58).string(m.phone);
            if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                w.uint32(66).string(m.email);
            if (m.addressLine1 != null && Object.hasOwnProperty.call(m, "addressLine1"))
                w.uint32(74).string(m.addressLine1);
            if (m.addressLine2 != null && Object.hasOwnProperty.call(m, "addressLine2"))
                w.uint32(82).string(m.addressLine2);
            return w;
        };

        /**
         * Decodes a CreditCardBillingDetails message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.CreditCardBillingDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.CreditCardBillingDetails} CreditCardBillingDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardBillingDetails.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.CreditCardBillingDetails();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.country = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 4:
                    m.city = r.string();
                    break;
                case 5:
                    m.state = r.string();
                    break;
                case 6:
                    m.zip = r.string();
                    break;
                case 7:
                    m.phone = r.string();
                    break;
                case 8:
                    m.email = r.string();
                    break;
                case 9:
                    m.addressLine1 = r.string();
                    break;
                case 10:
                    m.addressLine2 = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CreditCardBillingDetails;
    })();

    /**
     * CreditCardBrand enum.
     * @name payment3_paymentmethodpb.CreditCardBrand
     * @enum {number}
     * @property {number} Undefined_Brand=0 Undefined_Brand value
     * @property {number} AmericanExpress=10 AmericanExpress value
     * @property {number} DinersClub=20 DinersClub value
     * @property {number} Discover=30 Discover value
     * @property {number} JCB=40 JCB value
     * @property {number} MasterCard=50 MasterCard value
     * @property {number} UnionPay=60 UnionPay value
     * @property {number} Visa=70 Visa value
     * @property {number} BrandUnknown=200 BrandUnknown value
     */
    payment3_paymentmethodpb.CreditCardBrand = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined_Brand"] = 0;
        values[valuesById[10] = "AmericanExpress"] = 10;
        values[valuesById[20] = "DinersClub"] = 20;
        values[valuesById[30] = "Discover"] = 30;
        values[valuesById[40] = "JCB"] = 40;
        values[valuesById[50] = "MasterCard"] = 50;
        values[valuesById[60] = "UnionPay"] = 60;
        values[valuesById[70] = "Visa"] = 70;
        values[valuesById[200] = "BrandUnknown"] = 200;
        return values;
    })();

    payment3_paymentmethodpb.ACHDetails = (function() {

        /**
         * Properties of a ACHDetails.
         * @memberof payment3_paymentmethodpb
         * @interface IACHDetails
         */

        /**
         * Constructs a new ACHDetails.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a ACHDetails.
         * @implements IACHDetails
         * @constructor
         * @param {payment3_paymentmethodpb.IACHDetails=} [p] Properties to set
         */
        function ACHDetails(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Creates a new ACHDetails instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.ACHDetails
         * @static
         * @param {payment3_paymentmethodpb.IACHDetails=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.ACHDetails} ACHDetails instance
         */
        ACHDetails.create = function create(properties) {
            return new ACHDetails(properties);
        };

        /**
         * Encodes the specified ACHDetails message. Does not implicitly {@link payment3_paymentmethodpb.ACHDetails.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.ACHDetails
         * @static
         * @param {payment3_paymentmethodpb.IACHDetails} m ACHDetails message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ACHDetails.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            return w;
        };

        /**
         * Decodes a ACHDetails message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.ACHDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.ACHDetails} ACHDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ACHDetails.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.ACHDetails();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ACHDetails;
    })();

    payment3_paymentmethodpb.ProcessorPaymentMethod = (function() {

        /**
         * Properties of a ProcessorPaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @interface IProcessorPaymentMethod
         * @property {payment3_commonpb.PaymentProcessor|null} [processor] ProcessorPaymentMethod processor
         * @property {payment3_paymentmethodpb.IStripePaymentMethod|null} [stripe] ProcessorPaymentMethod stripe
         * @property {payment3_paymentmethodpb.IPaymentusPaymentMethod|null} [paymentus] ProcessorPaymentMethod paymentus
         */

        /**
         * Constructs a new ProcessorPaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a ProcessorPaymentMethod.
         * @implements IProcessorPaymentMethod
         * @constructor
         * @param {payment3_paymentmethodpb.IProcessorPaymentMethod=} [p] Properties to set
         */
        function ProcessorPaymentMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ProcessorPaymentMethod processor.
         * @member {payment3_commonpb.PaymentProcessor} processor
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @instance
         */
        ProcessorPaymentMethod.prototype.processor = 0;

        /**
         * ProcessorPaymentMethod stripe.
         * @member {payment3_paymentmethodpb.IStripePaymentMethod|null|undefined} stripe
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @instance
         */
        ProcessorPaymentMethod.prototype.stripe = null;

        /**
         * ProcessorPaymentMethod paymentus.
         * @member {payment3_paymentmethodpb.IPaymentusPaymentMethod|null|undefined} paymentus
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @instance
         */
        ProcessorPaymentMethod.prototype.paymentus = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ProcessorPaymentMethod processorPaymentMethod.
         * @member {"stripe"|"paymentus"|undefined} processorPaymentMethod
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @instance
         */
        Object.defineProperty(ProcessorPaymentMethod.prototype, "processorPaymentMethod", {
            get: $util.oneOfGetter($oneOfFields = ["stripe", "paymentus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProcessorPaymentMethod instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IProcessorPaymentMethod=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.ProcessorPaymentMethod} ProcessorPaymentMethod instance
         */
        ProcessorPaymentMethod.create = function create(properties) {
            return new ProcessorPaymentMethod(properties);
        };

        /**
         * Encodes the specified ProcessorPaymentMethod message. Does not implicitly {@link payment3_paymentmethodpb.ProcessorPaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IProcessorPaymentMethod} m ProcessorPaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessorPaymentMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.processor != null && Object.hasOwnProperty.call(m, "processor"))
                w.uint32(800).int32(m.processor);
            if (m.stripe != null && Object.hasOwnProperty.call(m, "stripe"))
                $root.payment3_paymentmethodpb.StripePaymentMethod.encode(m.stripe, w.uint32(810).fork()).ldelim();
            if (m.paymentus != null && Object.hasOwnProperty.call(m, "paymentus"))
                $root.payment3_paymentmethodpb.PaymentusPaymentMethod.encode(m.paymentus, w.uint32(818).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ProcessorPaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.ProcessorPaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.ProcessorPaymentMethod} ProcessorPaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessorPaymentMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.ProcessorPaymentMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.processor = r.int32();
                    break;
                case 101:
                    m.stripe = $root.payment3_paymentmethodpb.StripePaymentMethod.decode(r, r.uint32());
                    break;
                case 102:
                    m.paymentus = $root.payment3_paymentmethodpb.PaymentusPaymentMethod.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ProcessorPaymentMethod;
    })();

    payment3_paymentmethodpb.StripePaymentMethod = (function() {

        /**
         * Properties of a StripePaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @interface IStripePaymentMethod
         * @property {string|null} [paymentMethodID] StripePaymentMethod paymentMethodID
         * @property {string|null} [setupIntentID] StripePaymentMethod setupIntentID
         */

        /**
         * Constructs a new StripePaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a StripePaymentMethod.
         * @implements IStripePaymentMethod
         * @constructor
         * @param {payment3_paymentmethodpb.IStripePaymentMethod=} [p] Properties to set
         */
        function StripePaymentMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StripePaymentMethod paymentMethodID.
         * @member {string} paymentMethodID
         * @memberof payment3_paymentmethodpb.StripePaymentMethod
         * @instance
         */
        StripePaymentMethod.prototype.paymentMethodID = "";

        /**
         * StripePaymentMethod setupIntentID.
         * @member {string} setupIntentID
         * @memberof payment3_paymentmethodpb.StripePaymentMethod
         * @instance
         */
        StripePaymentMethod.prototype.setupIntentID = "";

        /**
         * Creates a new StripePaymentMethod instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.StripePaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IStripePaymentMethod=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.StripePaymentMethod} StripePaymentMethod instance
         */
        StripePaymentMethod.create = function create(properties) {
            return new StripePaymentMethod(properties);
        };

        /**
         * Encodes the specified StripePaymentMethod message. Does not implicitly {@link payment3_paymentmethodpb.StripePaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.StripePaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IStripePaymentMethod} m StripePaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethodID != null && Object.hasOwnProperty.call(m, "paymentMethodID"))
                w.uint32(10).string(m.paymentMethodID);
            if (m.setupIntentID != null && Object.hasOwnProperty.call(m, "setupIntentID"))
                w.uint32(18).string(m.setupIntentID);
            return w;
        };

        /**
         * Decodes a StripePaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.StripePaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.StripePaymentMethod} StripePaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.StripePaymentMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentMethodID = r.string();
                    break;
                case 2:
                    m.setupIntentID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return StripePaymentMethod;
    })();

    payment3_paymentmethodpb.PaymentusPaymentMethod = (function() {

        /**
         * Properties of a PaymentusPaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @interface IPaymentusPaymentMethod
         */

        /**
         * Constructs a new PaymentusPaymentMethod.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a PaymentusPaymentMethod.
         * @implements IPaymentusPaymentMethod
         * @constructor
         * @param {payment3_paymentmethodpb.IPaymentusPaymentMethod=} [p] Properties to set
         */
        function PaymentusPaymentMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Creates a new PaymentusPaymentMethod instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.PaymentusPaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IPaymentusPaymentMethod=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.PaymentusPaymentMethod} PaymentusPaymentMethod instance
         */
        PaymentusPaymentMethod.create = function create(properties) {
            return new PaymentusPaymentMethod(properties);
        };

        /**
         * Encodes the specified PaymentusPaymentMethod message. Does not implicitly {@link payment3_paymentmethodpb.PaymentusPaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.PaymentusPaymentMethod
         * @static
         * @param {payment3_paymentmethodpb.IPaymentusPaymentMethod} m PaymentusPaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentusPaymentMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            return w;
        };

        /**
         * Decodes a PaymentusPaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.PaymentusPaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.PaymentusPaymentMethod} PaymentusPaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentusPaymentMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.PaymentusPaymentMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return PaymentusPaymentMethod;
    })();

    payment3_paymentmethodpb.Owner = (function() {

        /**
         * Properties of an Owner.
         * @memberof payment3_paymentmethodpb
         * @interface IOwner
         * @property {string|null} [name] Owner name
         * @property {payment3_paymentmethodpb.IOwnerIdentity|null} [ownerIdentity] Owner ownerIdentity
         */

        /**
         * Constructs a new Owner.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents an Owner.
         * @implements IOwner
         * @constructor
         * @param {payment3_paymentmethodpb.IOwner=} [p] Properties to set
         */
        function Owner(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Owner name.
         * @member {string} name
         * @memberof payment3_paymentmethodpb.Owner
         * @instance
         */
        Owner.prototype.name = "";

        /**
         * Owner ownerIdentity.
         * @member {payment3_paymentmethodpb.IOwnerIdentity|null|undefined} ownerIdentity
         * @memberof payment3_paymentmethodpb.Owner
         * @instance
         */
        Owner.prototype.ownerIdentity = null;

        /**
         * Creates a new Owner instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.Owner
         * @static
         * @param {payment3_paymentmethodpb.IOwner=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.Owner} Owner instance
         */
        Owner.create = function create(properties) {
            return new Owner(properties);
        };

        /**
         * Encodes the specified Owner message. Does not implicitly {@link payment3_paymentmethodpb.Owner.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.Owner
         * @static
         * @param {payment3_paymentmethodpb.IOwner} m Owner message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Owner.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(802).string(m.name);
            if (m.ownerIdentity != null && Object.hasOwnProperty.call(m, "ownerIdentity"))
                $root.payment3_paymentmethodpb.OwnerIdentity.encode(m.ownerIdentity, w.uint32(1602).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an Owner message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.Owner
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.Owner} Owner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Owner.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.Owner();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.name = r.string();
                    break;
                case 200:
                    m.ownerIdentity = $root.payment3_paymentmethodpb.OwnerIdentity.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Owner;
    })();

    payment3_paymentmethodpb.OwnerIdentity = (function() {

        /**
         * Properties of an OwnerIdentity.
         * @memberof payment3_paymentmethodpb
         * @interface IOwnerIdentity
         * @property {payment3_paymentmethodpb.OwnerIdentityType|null} [type] OwnerIdentity type
         * @property {payment3_paymentmethodpb.IContractIdentity|null} [contractIdentity] OwnerIdentity contractIdentity
         * @property {payment3_paymentmethodpb.ICustomerIdentity|null} [customerIdentity] OwnerIdentity customerIdentity
         * @property {payment3_paymentmethodpb.IApplianceReplacementIdentity|null} [replacementIdentity] OwnerIdentity replacementIdentity
         * @property {payment3_paymentmethodpb.IDispatchIdentity|null} [dispatchIdentity] OwnerIdentity dispatchIdentity
         */

        /**
         * Constructs a new OwnerIdentity.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents an OwnerIdentity.
         * @implements IOwnerIdentity
         * @constructor
         * @param {payment3_paymentmethodpb.IOwnerIdentity=} [p] Properties to set
         */
        function OwnerIdentity(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OwnerIdentity type.
         * @member {payment3_paymentmethodpb.OwnerIdentityType} type
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @instance
         */
        OwnerIdentity.prototype.type = 0;

        /**
         * OwnerIdentity contractIdentity.
         * @member {payment3_paymentmethodpb.IContractIdentity|null|undefined} contractIdentity
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @instance
         */
        OwnerIdentity.prototype.contractIdentity = null;

        /**
         * OwnerIdentity customerIdentity.
         * @member {payment3_paymentmethodpb.ICustomerIdentity|null|undefined} customerIdentity
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @instance
         */
        OwnerIdentity.prototype.customerIdentity = null;

        /**
         * OwnerIdentity replacementIdentity.
         * @member {payment3_paymentmethodpb.IApplianceReplacementIdentity|null|undefined} replacementIdentity
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @instance
         */
        OwnerIdentity.prototype.replacementIdentity = null;

        /**
         * OwnerIdentity dispatchIdentity.
         * @member {payment3_paymentmethodpb.IDispatchIdentity|null|undefined} dispatchIdentity
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @instance
         */
        OwnerIdentity.prototype.dispatchIdentity = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * OwnerIdentity identity.
         * @member {"contractIdentity"|"customerIdentity"|"replacementIdentity"|"dispatchIdentity"|undefined} identity
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @instance
         */
        Object.defineProperty(OwnerIdentity.prototype, "identity", {
            get: $util.oneOfGetter($oneOfFields = ["contractIdentity", "customerIdentity", "replacementIdentity", "dispatchIdentity"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new OwnerIdentity instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @static
         * @param {payment3_paymentmethodpb.IOwnerIdentity=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.OwnerIdentity} OwnerIdentity instance
         */
        OwnerIdentity.create = function create(properties) {
            return new OwnerIdentity(properties);
        };

        /**
         * Encodes the specified OwnerIdentity message. Does not implicitly {@link payment3_paymentmethodpb.OwnerIdentity.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @static
         * @param {payment3_paymentmethodpb.IOwnerIdentity} m OwnerIdentity message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OwnerIdentity.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(800).int32(m.type);
            if (m.contractIdentity != null && Object.hasOwnProperty.call(m, "contractIdentity"))
                $root.payment3_paymentmethodpb.ContractIdentity.encode(m.contractIdentity, w.uint32(810).fork()).ldelim();
            if (m.customerIdentity != null && Object.hasOwnProperty.call(m, "customerIdentity"))
                $root.payment3_paymentmethodpb.CustomerIdentity.encode(m.customerIdentity, w.uint32(826).fork()).ldelim();
            if (m.replacementIdentity != null && Object.hasOwnProperty.call(m, "replacementIdentity"))
                $root.payment3_paymentmethodpb.ApplianceReplacementIdentity.encode(m.replacementIdentity, w.uint32(882).fork()).ldelim();
            if (m.dispatchIdentity != null && Object.hasOwnProperty.call(m, "dispatchIdentity"))
                $root.payment3_paymentmethodpb.DispatchIdentity.encode(m.dispatchIdentity, w.uint32(962).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an OwnerIdentity message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.OwnerIdentity
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.OwnerIdentity} OwnerIdentity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OwnerIdentity.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.OwnerIdentity();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.type = r.int32();
                    break;
                case 101:
                    m.contractIdentity = $root.payment3_paymentmethodpb.ContractIdentity.decode(r, r.uint32());
                    break;
                case 103:
                    m.customerIdentity = $root.payment3_paymentmethodpb.CustomerIdentity.decode(r, r.uint32());
                    break;
                case 110:
                    m.replacementIdentity = $root.payment3_paymentmethodpb.ApplianceReplacementIdentity.decode(r, r.uint32());
                    break;
                case 120:
                    m.dispatchIdentity = $root.payment3_paymentmethodpb.DispatchIdentity.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return OwnerIdentity;
    })();

    /**
     * OwnerIdentityType enum.
     * @name payment3_paymentmethodpb.OwnerIdentityType
     * @enum {number}
     * @property {number} Undefined_IdentityType=0 Undefined_IdentityType value
     * @property {number} ContractIdentityType=1 ContractIdentityType value
     * @property {number} ReplacementIdentityType=2 ReplacementIdentityType value
     * @property {number} CustomerIdentityType=3 CustomerIdentityType value
     * @property {number} DispatchIdentityType=4 DispatchIdentityType value
     */
    payment3_paymentmethodpb.OwnerIdentityType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined_IdentityType"] = 0;
        values[valuesById[1] = "ContractIdentityType"] = 1;
        values[valuesById[2] = "ReplacementIdentityType"] = 2;
        values[valuesById[3] = "CustomerIdentityType"] = 3;
        values[valuesById[4] = "DispatchIdentityType"] = 4;
        return values;
    })();

    payment3_paymentmethodpb.ContractIdentity = (function() {

        /**
         * Properties of a ContractIdentity.
         * @memberof payment3_paymentmethodpb
         * @interface IContractIdentity
         * @property {string|null} [contractID] ContractIdentity contractID
         * @property {string|null} [contractPaymentID] ContractIdentity contractPaymentID
         */

        /**
         * Constructs a new ContractIdentity.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a ContractIdentity.
         * @implements IContractIdentity
         * @constructor
         * @param {payment3_paymentmethodpb.IContractIdentity=} [p] Properties to set
         */
        function ContractIdentity(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContractIdentity contractID.
         * @member {string} contractID
         * @memberof payment3_paymentmethodpb.ContractIdentity
         * @instance
         */
        ContractIdentity.prototype.contractID = "";

        /**
         * ContractIdentity contractPaymentID.
         * @member {string} contractPaymentID
         * @memberof payment3_paymentmethodpb.ContractIdentity
         * @instance
         */
        ContractIdentity.prototype.contractPaymentID = "";

        /**
         * Creates a new ContractIdentity instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.ContractIdentity
         * @static
         * @param {payment3_paymentmethodpb.IContractIdentity=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.ContractIdentity} ContractIdentity instance
         */
        ContractIdentity.create = function create(properties) {
            return new ContractIdentity(properties);
        };

        /**
         * Encodes the specified ContractIdentity message. Does not implicitly {@link payment3_paymentmethodpb.ContractIdentity.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.ContractIdentity
         * @static
         * @param {payment3_paymentmethodpb.IContractIdentity} m ContractIdentity message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractIdentity.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contractID != null && Object.hasOwnProperty.call(m, "contractID"))
                w.uint32(10).string(m.contractID);
            if (m.contractPaymentID != null && Object.hasOwnProperty.call(m, "contractPaymentID"))
                w.uint32(18).string(m.contractPaymentID);
            return w;
        };

        /**
         * Decodes a ContractIdentity message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.ContractIdentity
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.ContractIdentity} ContractIdentity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractIdentity.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.ContractIdentity();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contractID = r.string();
                    break;
                case 2:
                    m.contractPaymentID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ContractIdentity;
    })();

    payment3_paymentmethodpb.CustomerIdentity = (function() {

        /**
         * Properties of a CustomerIdentity.
         * @memberof payment3_paymentmethodpb
         * @interface ICustomerIdentity
         * @property {string|null} [customerID] CustomerIdentity customerID
         */

        /**
         * Constructs a new CustomerIdentity.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a CustomerIdentity.
         * @implements ICustomerIdentity
         * @constructor
         * @param {payment3_paymentmethodpb.ICustomerIdentity=} [p] Properties to set
         */
        function CustomerIdentity(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CustomerIdentity customerID.
         * @member {string} customerID
         * @memberof payment3_paymentmethodpb.CustomerIdentity
         * @instance
         */
        CustomerIdentity.prototype.customerID = "";

        /**
         * Creates a new CustomerIdentity instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.CustomerIdentity
         * @static
         * @param {payment3_paymentmethodpb.ICustomerIdentity=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.CustomerIdentity} CustomerIdentity instance
         */
        CustomerIdentity.create = function create(properties) {
            return new CustomerIdentity(properties);
        };

        /**
         * Encodes the specified CustomerIdentity message. Does not implicitly {@link payment3_paymentmethodpb.CustomerIdentity.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.CustomerIdentity
         * @static
         * @param {payment3_paymentmethodpb.ICustomerIdentity} m CustomerIdentity message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerIdentity.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.customerID != null && Object.hasOwnProperty.call(m, "customerID"))
                w.uint32(10).string(m.customerID);
            return w;
        };

        /**
         * Decodes a CustomerIdentity message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.CustomerIdentity
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.CustomerIdentity} CustomerIdentity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerIdentity.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.CustomerIdentity();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.customerID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CustomerIdentity;
    })();

    payment3_paymentmethodpb.ApplianceReplacementIdentity = (function() {

        /**
         * Properties of an ApplianceReplacementIdentity.
         * @memberof payment3_paymentmethodpb
         * @interface IApplianceReplacementIdentity
         * @property {string|null} [replacementID] ApplianceReplacementIdentity replacementID
         */

        /**
         * Constructs a new ApplianceReplacementIdentity.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents an ApplianceReplacementIdentity.
         * @implements IApplianceReplacementIdentity
         * @constructor
         * @param {payment3_paymentmethodpb.IApplianceReplacementIdentity=} [p] Properties to set
         */
        function ApplianceReplacementIdentity(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ApplianceReplacementIdentity replacementID.
         * @member {string} replacementID
         * @memberof payment3_paymentmethodpb.ApplianceReplacementIdentity
         * @instance
         */
        ApplianceReplacementIdentity.prototype.replacementID = "";

        /**
         * Creates a new ApplianceReplacementIdentity instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.ApplianceReplacementIdentity
         * @static
         * @param {payment3_paymentmethodpb.IApplianceReplacementIdentity=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.ApplianceReplacementIdentity} ApplianceReplacementIdentity instance
         */
        ApplianceReplacementIdentity.create = function create(properties) {
            return new ApplianceReplacementIdentity(properties);
        };

        /**
         * Encodes the specified ApplianceReplacementIdentity message. Does not implicitly {@link payment3_paymentmethodpb.ApplianceReplacementIdentity.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.ApplianceReplacementIdentity
         * @static
         * @param {payment3_paymentmethodpb.IApplianceReplacementIdentity} m ApplianceReplacementIdentity message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceReplacementIdentity.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.replacementID != null && Object.hasOwnProperty.call(m, "replacementID"))
                w.uint32(10).string(m.replacementID);
            return w;
        };

        /**
         * Decodes an ApplianceReplacementIdentity message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.ApplianceReplacementIdentity
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.ApplianceReplacementIdentity} ApplianceReplacementIdentity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceReplacementIdentity.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.ApplianceReplacementIdentity();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.replacementID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ApplianceReplacementIdentity;
    })();

    payment3_paymentmethodpb.DispatchIdentity = (function() {

        /**
         * Properties of a DispatchIdentity.
         * @memberof payment3_paymentmethodpb
         * @interface IDispatchIdentity
         * @property {string|null} [dispatchID] DispatchIdentity dispatchID
         */

        /**
         * Constructs a new DispatchIdentity.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a DispatchIdentity.
         * @implements IDispatchIdentity
         * @constructor
         * @param {payment3_paymentmethodpb.IDispatchIdentity=} [p] Properties to set
         */
        function DispatchIdentity(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DispatchIdentity dispatchID.
         * @member {string} dispatchID
         * @memberof payment3_paymentmethodpb.DispatchIdentity
         * @instance
         */
        DispatchIdentity.prototype.dispatchID = "";

        /**
         * Creates a new DispatchIdentity instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.DispatchIdentity
         * @static
         * @param {payment3_paymentmethodpb.IDispatchIdentity=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.DispatchIdentity} DispatchIdentity instance
         */
        DispatchIdentity.create = function create(properties) {
            return new DispatchIdentity(properties);
        };

        /**
         * Encodes the specified DispatchIdentity message. Does not implicitly {@link payment3_paymentmethodpb.DispatchIdentity.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.DispatchIdentity
         * @static
         * @param {payment3_paymentmethodpb.IDispatchIdentity} m DispatchIdentity message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DispatchIdentity.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.dispatchID != null && Object.hasOwnProperty.call(m, "dispatchID"))
                w.uint32(10).string(m.dispatchID);
            return w;
        };

        /**
         * Decodes a DispatchIdentity message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.DispatchIdentity
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.DispatchIdentity} DispatchIdentity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DispatchIdentity.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.DispatchIdentity();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.dispatchID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return DispatchIdentity;
    })();

    payment3_paymentmethodpb.QueryPaymentMethodsRequest = (function() {

        /**
         * Properties of a QueryPaymentMethodsRequest.
         * @memberof payment3_paymentmethodpb
         * @interface IQueryPaymentMethodsRequest
         * @property {payment3_paymentmethodpb.IQueryPaymentMethodsCriteria|null} [criteria] QueryPaymentMethodsRequest criteria
         */

        /**
         * Constructs a new QueryPaymentMethodsRequest.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a QueryPaymentMethodsRequest.
         * @implements IQueryPaymentMethodsRequest
         * @constructor
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsRequest=} [p] Properties to set
         */
        function QueryPaymentMethodsRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QueryPaymentMethodsRequest criteria.
         * @member {payment3_paymentmethodpb.IQueryPaymentMethodsCriteria|null|undefined} criteria
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsRequest
         * @instance
         */
        QueryPaymentMethodsRequest.prototype.criteria = null;

        /**
         * Creates a new QueryPaymentMethodsRequest instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsRequest
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsRequest=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsRequest} QueryPaymentMethodsRequest instance
         */
        QueryPaymentMethodsRequest.create = function create(properties) {
            return new QueryPaymentMethodsRequest(properties);
        };

        /**
         * Encodes the specified QueryPaymentMethodsRequest message. Does not implicitly {@link payment3_paymentmethodpb.QueryPaymentMethodsRequest.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsRequest
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsRequest} m QueryPaymentMethodsRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryPaymentMethodsRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.criteria != null && Object.hasOwnProperty.call(m, "criteria"))
                $root.payment3_paymentmethodpb.QueryPaymentMethodsCriteria.encode(m.criteria, w.uint32(82).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a QueryPaymentMethodsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsRequest} QueryPaymentMethodsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryPaymentMethodsRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.QueryPaymentMethodsRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 10:
                    m.criteria = $root.payment3_paymentmethodpb.QueryPaymentMethodsCriteria.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return QueryPaymentMethodsRequest;
    })();

    payment3_paymentmethodpb.QueryPaymentMethodsCriteria = (function() {

        /**
         * Properties of a QueryPaymentMethodsCriteria.
         * @memberof payment3_paymentmethodpb
         * @interface IQueryPaymentMethodsCriteria
         * @property {payment3_paymentmethodpb.IQueryPaymentMethodsOwner|null} [owner] QueryPaymentMethodsCriteria owner
         */

        /**
         * Constructs a new QueryPaymentMethodsCriteria.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a QueryPaymentMethodsCriteria.
         * @implements IQueryPaymentMethodsCriteria
         * @constructor
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsCriteria=} [p] Properties to set
         */
        function QueryPaymentMethodsCriteria(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QueryPaymentMethodsCriteria owner.
         * @member {payment3_paymentmethodpb.IQueryPaymentMethodsOwner|null|undefined} owner
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsCriteria
         * @instance
         */
        QueryPaymentMethodsCriteria.prototype.owner = null;

        /**
         * Creates a new QueryPaymentMethodsCriteria instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsCriteria
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsCriteria=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsCriteria} QueryPaymentMethodsCriteria instance
         */
        QueryPaymentMethodsCriteria.create = function create(properties) {
            return new QueryPaymentMethodsCriteria(properties);
        };

        /**
         * Encodes the specified QueryPaymentMethodsCriteria message. Does not implicitly {@link payment3_paymentmethodpb.QueryPaymentMethodsCriteria.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsCriteria
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsCriteria} m QueryPaymentMethodsCriteria message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryPaymentMethodsCriteria.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                $root.payment3_paymentmethodpb.QueryPaymentMethodsOwner.encode(m.owner, w.uint32(82).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a QueryPaymentMethodsCriteria message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsCriteria
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsCriteria} QueryPaymentMethodsCriteria
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryPaymentMethodsCriteria.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.QueryPaymentMethodsCriteria();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 10:
                    m.owner = $root.payment3_paymentmethodpb.QueryPaymentMethodsOwner.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return QueryPaymentMethodsCriteria;
    })();

    payment3_paymentmethodpb.QueryPaymentMethodsOwner = (function() {

        /**
         * Properties of a QueryPaymentMethodsOwner.
         * @memberof payment3_paymentmethodpb
         * @interface IQueryPaymentMethodsOwner
         * @property {Array.<string>|null} [contractID] QueryPaymentMethodsOwner contractID
         * @property {Array.<string>|null} [customerID] QueryPaymentMethodsOwner customerID
         * @property {Array.<string>|null} [applianceReplacementID] QueryPaymentMethodsOwner applianceReplacementID
         * @property {Array.<string>|null} [dispatchID] QueryPaymentMethodsOwner dispatchID
         */

        /**
         * Constructs a new QueryPaymentMethodsOwner.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a QueryPaymentMethodsOwner.
         * @implements IQueryPaymentMethodsOwner
         * @constructor
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsOwner=} [p] Properties to set
         */
        function QueryPaymentMethodsOwner(p) {
            this.contractID = [];
            this.customerID = [];
            this.applianceReplacementID = [];
            this.dispatchID = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QueryPaymentMethodsOwner contractID.
         * @member {Array.<string>} contractID
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @instance
         */
        QueryPaymentMethodsOwner.prototype.contractID = $util.emptyArray;

        /**
         * QueryPaymentMethodsOwner customerID.
         * @member {Array.<string>} customerID
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @instance
         */
        QueryPaymentMethodsOwner.prototype.customerID = $util.emptyArray;

        /**
         * QueryPaymentMethodsOwner applianceReplacementID.
         * @member {Array.<string>} applianceReplacementID
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @instance
         */
        QueryPaymentMethodsOwner.prototype.applianceReplacementID = $util.emptyArray;

        /**
         * QueryPaymentMethodsOwner dispatchID.
         * @member {Array.<string>} dispatchID
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @instance
         */
        QueryPaymentMethodsOwner.prototype.dispatchID = $util.emptyArray;

        /**
         * Creates a new QueryPaymentMethodsOwner instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsOwner=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsOwner} QueryPaymentMethodsOwner instance
         */
        QueryPaymentMethodsOwner.create = function create(properties) {
            return new QueryPaymentMethodsOwner(properties);
        };

        /**
         * Encodes the specified QueryPaymentMethodsOwner message. Does not implicitly {@link payment3_paymentmethodpb.QueryPaymentMethodsOwner.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsOwner} m QueryPaymentMethodsOwner message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryPaymentMethodsOwner.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contractID != null && m.contractID.length) {
                for (var i = 0; i < m.contractID.length; ++i)
                    w.uint32(82).string(m.contractID[i]);
            }
            if (m.customerID != null && m.customerID.length) {
                for (var i = 0; i < m.customerID.length; ++i)
                    w.uint32(162).string(m.customerID[i]);
            }
            if (m.applianceReplacementID != null && m.applianceReplacementID.length) {
                for (var i = 0; i < m.applianceReplacementID.length; ++i)
                    w.uint32(242).string(m.applianceReplacementID[i]);
            }
            if (m.dispatchID != null && m.dispatchID.length) {
                for (var i = 0; i < m.dispatchID.length; ++i)
                    w.uint32(322).string(m.dispatchID[i]);
            }
            return w;
        };

        /**
         * Decodes a QueryPaymentMethodsOwner message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsOwner
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsOwner} QueryPaymentMethodsOwner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryPaymentMethodsOwner.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.QueryPaymentMethodsOwner();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 10:
                    if (!(m.contractID && m.contractID.length))
                        m.contractID = [];
                    m.contractID.push(r.string());
                    break;
                case 20:
                    if (!(m.customerID && m.customerID.length))
                        m.customerID = [];
                    m.customerID.push(r.string());
                    break;
                case 30:
                    if (!(m.applianceReplacementID && m.applianceReplacementID.length))
                        m.applianceReplacementID = [];
                    m.applianceReplacementID.push(r.string());
                    break;
                case 40:
                    if (!(m.dispatchID && m.dispatchID.length))
                        m.dispatchID = [];
                    m.dispatchID.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return QueryPaymentMethodsOwner;
    })();

    payment3_paymentmethodpb.QueryPaymentMethodsResponse = (function() {

        /**
         * Properties of a QueryPaymentMethodsResponse.
         * @memberof payment3_paymentmethodpb
         * @interface IQueryPaymentMethodsResponse
         * @property {Array.<payment3_paymentmethodpb.IPaymentMethod>|null} [paymentMethod] QueryPaymentMethodsResponse paymentMethod
         */

        /**
         * Constructs a new QueryPaymentMethodsResponse.
         * @memberof payment3_paymentmethodpb
         * @classdesc Represents a QueryPaymentMethodsResponse.
         * @implements IQueryPaymentMethodsResponse
         * @constructor
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsResponse=} [p] Properties to set
         */
        function QueryPaymentMethodsResponse(p) {
            this.paymentMethod = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QueryPaymentMethodsResponse paymentMethod.
         * @member {Array.<payment3_paymentmethodpb.IPaymentMethod>} paymentMethod
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsResponse
         * @instance
         */
        QueryPaymentMethodsResponse.prototype.paymentMethod = $util.emptyArray;

        /**
         * Creates a new QueryPaymentMethodsResponse instance using the specified properties.
         * @function create
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsResponse
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsResponse=} [properties] Properties to set
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsResponse} QueryPaymentMethodsResponse instance
         */
        QueryPaymentMethodsResponse.create = function create(properties) {
            return new QueryPaymentMethodsResponse(properties);
        };

        /**
         * Encodes the specified QueryPaymentMethodsResponse message. Does not implicitly {@link payment3_paymentmethodpb.QueryPaymentMethodsResponse.verify|verify} messages.
         * @function encode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsResponse
         * @static
         * @param {payment3_paymentmethodpb.IQueryPaymentMethodsResponse} m QueryPaymentMethodsResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryPaymentMethodsResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentMethod != null && m.paymentMethod.length) {
                for (var i = 0; i < m.paymentMethod.length; ++i)
                    $root.payment3_paymentmethodpb.PaymentMethod.encode(m.paymentMethod[i], w.uint32(82).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a QueryPaymentMethodsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_paymentmethodpb.QueryPaymentMethodsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_paymentmethodpb.QueryPaymentMethodsResponse} QueryPaymentMethodsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryPaymentMethodsResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_paymentmethodpb.QueryPaymentMethodsResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 10:
                    if (!(m.paymentMethod && m.paymentMethod.length))
                        m.paymentMethod = [];
                    m.paymentMethod.push($root.payment3_paymentmethodpb.PaymentMethod.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return QueryPaymentMethodsResponse;
    })();

    return payment3_paymentmethodpb;
})();

export const payment3_stripe_gatewaypb = $root.payment3_stripe_gatewaypb = (() => {

    /**
     * Namespace payment3_stripe_gatewaypb.
     * @exports payment3_stripe_gatewaypb
     * @namespace
     */
    const payment3_stripe_gatewaypb = {};

    payment3_stripe_gatewaypb.StripeRegistrationInternal = (function() {

        /**
         * Properties of a StripeRegistrationInternal.
         * @memberof payment3_stripe_gatewaypb
         * @interface IStripeRegistrationInternal
         * @property {string|null} [setupIntent] StripeRegistrationInternal setupIntent
         */

        /**
         * Constructs a new StripeRegistrationInternal.
         * @memberof payment3_stripe_gatewaypb
         * @classdesc Represents a StripeRegistrationInternal.
         * @implements IStripeRegistrationInternal
         * @constructor
         * @param {payment3_stripe_gatewaypb.IStripeRegistrationInternal=} [p] Properties to set
         */
        function StripeRegistrationInternal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StripeRegistrationInternal setupIntent.
         * @member {string} setupIntent
         * @memberof payment3_stripe_gatewaypb.StripeRegistrationInternal
         * @instance
         */
        StripeRegistrationInternal.prototype.setupIntent = "";

        /**
         * Creates a new StripeRegistrationInternal instance using the specified properties.
         * @function create
         * @memberof payment3_stripe_gatewaypb.StripeRegistrationInternal
         * @static
         * @param {payment3_stripe_gatewaypb.IStripeRegistrationInternal=} [properties] Properties to set
         * @returns {payment3_stripe_gatewaypb.StripeRegistrationInternal} StripeRegistrationInternal instance
         */
        StripeRegistrationInternal.create = function create(properties) {
            return new StripeRegistrationInternal(properties);
        };

        /**
         * Encodes the specified StripeRegistrationInternal message. Does not implicitly {@link payment3_stripe_gatewaypb.StripeRegistrationInternal.verify|verify} messages.
         * @function encode
         * @memberof payment3_stripe_gatewaypb.StripeRegistrationInternal
         * @static
         * @param {payment3_stripe_gatewaypb.IStripeRegistrationInternal} m StripeRegistrationInternal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripeRegistrationInternal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.setupIntent != null && Object.hasOwnProperty.call(m, "setupIntent"))
                w.uint32(802).string(m.setupIntent);
            return w;
        };

        /**
         * Decodes a StripeRegistrationInternal message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_stripe_gatewaypb.StripeRegistrationInternal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_stripe_gatewaypb.StripeRegistrationInternal} StripeRegistrationInternal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripeRegistrationInternal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_stripe_gatewaypb.StripeRegistrationInternal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.setupIntent = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return StripeRegistrationInternal;
    })();

    payment3_stripe_gatewaypb.StripeRegistration = (function() {

        /**
         * Properties of a StripeRegistration.
         * @memberof payment3_stripe_gatewaypb
         * @interface IStripeRegistration
         * @property {string|null} [publicKey] StripeRegistration publicKey
         * @property {string|null} [setupIntentClientSecret] StripeRegistration setupIntentClientSecret
         * @property {string|null} [setupIntentID] StripeRegistration setupIntentID
         */

        /**
         * Constructs a new StripeRegistration.
         * @memberof payment3_stripe_gatewaypb
         * @classdesc Represents a StripeRegistration.
         * @implements IStripeRegistration
         * @constructor
         * @param {payment3_stripe_gatewaypb.IStripeRegistration=} [p] Properties to set
         */
        function StripeRegistration(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StripeRegistration publicKey.
         * @member {string} publicKey
         * @memberof payment3_stripe_gatewaypb.StripeRegistration
         * @instance
         */
        StripeRegistration.prototype.publicKey = "";

        /**
         * StripeRegistration setupIntentClientSecret.
         * @member {string} setupIntentClientSecret
         * @memberof payment3_stripe_gatewaypb.StripeRegistration
         * @instance
         */
        StripeRegistration.prototype.setupIntentClientSecret = "";

        /**
         * StripeRegistration setupIntentID.
         * @member {string} setupIntentID
         * @memberof payment3_stripe_gatewaypb.StripeRegistration
         * @instance
         */
        StripeRegistration.prototype.setupIntentID = "";

        /**
         * Creates a new StripeRegistration instance using the specified properties.
         * @function create
         * @memberof payment3_stripe_gatewaypb.StripeRegistration
         * @static
         * @param {payment3_stripe_gatewaypb.IStripeRegistration=} [properties] Properties to set
         * @returns {payment3_stripe_gatewaypb.StripeRegistration} StripeRegistration instance
         */
        StripeRegistration.create = function create(properties) {
            return new StripeRegistration(properties);
        };

        /**
         * Encodes the specified StripeRegistration message. Does not implicitly {@link payment3_stripe_gatewaypb.StripeRegistration.verify|verify} messages.
         * @function encode
         * @memberof payment3_stripe_gatewaypb.StripeRegistration
         * @static
         * @param {payment3_stripe_gatewaypb.IStripeRegistration} m StripeRegistration message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripeRegistration.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.publicKey != null && Object.hasOwnProperty.call(m, "publicKey"))
                w.uint32(802).string(m.publicKey);
            if (m.setupIntentClientSecret != null && Object.hasOwnProperty.call(m, "setupIntentClientSecret"))
                w.uint32(1602).string(m.setupIntentClientSecret);
            if (m.setupIntentID != null && Object.hasOwnProperty.call(m, "setupIntentID"))
                w.uint32(2402).string(m.setupIntentID);
            return w;
        };

        /**
         * Decodes a StripeRegistration message from the specified reader or buffer.
         * @function decode
         * @memberof payment3_stripe_gatewaypb.StripeRegistration
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {payment3_stripe_gatewaypb.StripeRegistration} StripeRegistration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripeRegistration.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.payment3_stripe_gatewaypb.StripeRegistration();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 100:
                    m.publicKey = r.string();
                    break;
                case 200:
                    m.setupIntentClientSecret = r.string();
                    break;
                case 300:
                    m.setupIntentID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return StripeRegistration;
    })();

    /**
     * PaymentIntentStatus enum.
     * @name payment3_stripe_gatewaypb.PaymentIntentStatus
     * @enum {number}
     * @property {number} Unknown_PaymentIntentStatus=0 Unknown_PaymentIntentStatus value
     * @property {number} succeeded=900 succeeded value
     * @property {number} requiresPaymentMethod=901 requiresPaymentMethod value
     * @property {number} requiresConfirmation=902 requiresConfirmation value
     * @property {number} requiresAction=903 requiresAction value
     * @property {number} requiresCapture=904 requiresCapture value
     * @property {number} canceled=905 canceled value
     * @property {number} processing=906 processing value
     */
    payment3_stripe_gatewaypb.PaymentIntentStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Unknown_PaymentIntentStatus"] = 0;
        values[valuesById[900] = "succeeded"] = 900;
        values[valuesById[901] = "requiresPaymentMethod"] = 901;
        values[valuesById[902] = "requiresConfirmation"] = 902;
        values[valuesById[903] = "requiresAction"] = 903;
        values[valuesById[904] = "requiresCapture"] = 904;
        values[valuesById[905] = "canceled"] = 905;
        values[valuesById[906] = "processing"] = 906;
        return values;
    })();

    return payment3_stripe_gatewaypb;
})();

export { $root as default };
