import { AnyAction } from "@reduxjs/toolkit";
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import {
  FailureResponseType,
  MethodSetupRequest,
  MethodSetupResponse,
  PaymentMethodClient,
  RegistrationRequest,
  RegistrationResponse,
} from "../../types";
import {
  useCreatePaymentMethod,
  useCreatePaymentMethodRegistration,
} from "./hooks";
import { StripePaymentsStateContextProvider } from "./stripe-payments-state-context";
import { initialState, stripePaymentsClientSetupSlice } from "./state";

export type StripePaymentsClientContextProps = {
  client?: PaymentMethodClient | undefined;
  providerState?: StripePaymentsClientContextValue;
  children: ReactNode;
};

export type CreatePaymentMethodResultType =
  | {
      result?: MethodSetupResponse;
      error?: FailureResponseType;
    }
  | undefined;

export type StripePaymentsClientContextValue = {
  client?: PaymentMethodClient;
  createPaymentMethodRegistration: (
    requestData: RegistrationRequest
  ) => Promise<RegistrationResponse | undefined>;
  createPaymentMethod: (
    requestData: MethodSetupRequest
  ) => Promise<CreatePaymentMethodResultType>;
  dispatch: Dispatch<AnyAction>;
};

export const StripePaymentsClientContextInitialState: StripePaymentsClientContextValue = {
  createPaymentMethodRegistration: () => Promise.resolve(undefined),
  createPaymentMethod: () => Promise.resolve(undefined),
  dispatch: () => undefined,
};

const StripePaymentsContext = createContext<StripePaymentsClientContextValue>(
  StripePaymentsClientContextInitialState
);
export const {
  Provider: StripePaymentsClientContextProvider,
} = StripePaymentsContext;

export const StripePaymentsClientContext: FC<StripePaymentsClientContextProps> = ({
  client,
  children,
  providerState,
}) => {
  const [state, dispatch] = useReducer(
    stripePaymentsClientSetupSlice.reducer,
    initialState
  );

  const createPaymentMethodRegistration = useCreatePaymentMethodRegistration(
    dispatch,
    client
  );
  const createPaymentMethod = useCreatePaymentMethod(dispatch, client);

  return (
    <StripePaymentsClientContextProvider
      value={
        providerState
          ? providerState
          : {
              client,
              createPaymentMethodRegistration,
              createPaymentMethod,
              dispatch,
            }
      }
    >
      <StripePaymentsStateContextProvider value={{ state }}>
        {children}
      </StripePaymentsStateContextProvider>
    </StripePaymentsClientContextProvider>
  );
};

export const useStripePaymentsClientContext = (): StripePaymentsClientContextValue =>
  useContext<StripePaymentsClientContextValue>(StripePaymentsContext);
