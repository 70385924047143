import {
  Notification,
  ProgressIndicator,
} from "@ftdr/blueprint-components-react";
import { payment3_paymentmethodpb } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { Stripe } from "@stripe/stripe-js";
import React, { forwardRef, useEffect } from "react";
import {
  StripePaymentsClientContext,
  useStripePaymentsClientContext,
  useStripePaymentsStateContext,
} from "../../../contexts/stripe-payments-client-context";
import { PaymentsComponentOwnProps } from "../../payments-component";
import {
  PaymentProcessorClientInternalRef,
  ProcessPaymentStripeRequestData,
} from "../types";
import { StripeForm } from "./components";

const StripeCardProcessorInternal = forwardRef<
  PaymentProcessorClientInternalRef<
    ProcessPaymentStripeRequestData,
    Stripe | null
  >
>((_props, ref) => {
  const { createPaymentMethodRegistration } = useStripePaymentsClientContext();
  const { state } = useStripePaymentsStateContext();

  useEffect(() => {
    createPaymentMethodRegistration({
      paymentMethod: {
        details: {
          type: payment3_paymentmethodpb.PaymentMethodType.CreditCard,
        },
      },
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="flex justify-center flex-col">
      {state?.isLoading ? (
        <ProgressIndicator className="my-4" size="medium" color="primary" />
      ) : (
        <>
          {state.error.isError ? (
            <Notification
              variant="inline"
              status="error"
              size="medium"
              showStatusLabel={true}
              inline={true}
            >
              <>{state.error.message}</>
            </Notification>
          ) : (
            <StripeForm ref={ref} />
          )}
        </>
      )}
    </div>
  );
});

StripeCardProcessorInternal.displayName = "StripeCardProcessorInternal";

export const StripeCardProcessor = forwardRef<
  PaymentProcessorClientInternalRef<
    ProcessPaymentStripeRequestData,
    Stripe | null
  >,
  Pick<PaymentsComponentOwnProps, "paymentMethodCoordinatorClient">
>(({ paymentMethodCoordinatorClient }, ref) => {
  return (
    <StripePaymentsClientContext client={paymentMethodCoordinatorClient}>
      <StripeCardProcessorInternal ref={ref} />
    </StripePaymentsClientContext>
  );
});

StripeCardProcessor.displayName = "StripeCardProcessor";
