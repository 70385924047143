import { useAppContext } from "@ftdr/blueprint-components-react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { useCallback } from "react";
import { usePaymentContext } from "../../../../../../contexts/payment-context";
import { setIsPending } from "../../../../../../contexts/payment-context/state";
import { useStripePaymentsClientContext } from "../../../../../../contexts/stripe-payments-client-context";
import { setError } from "../../../../../../contexts/stripe-payments-client-context/state";
import {
  AgregatedProcessPaymentResponse,
  FailureTypeValue,
  ProcessPaymentStripeRequestData,
} from "../../../../types";
import { StripeFormDetails } from "../../stripe-form";
import { StripeElementsFormProps } from "../stripe-elements";
import { useRetrieveSetupIntent } from "./use-retrive-setup-intent";
import { useStripeElementsValidations } from "./use-stripe-elements-validation";
import { useFormContext } from "react-hook-form";

type UseStripeElementsReturnValues = {
  submitCardSetup: SubmitCardSetup;
  stripe: Stripe | null;
};

type SubmitCardSetup = (
  requestData: ProcessPaymentStripeRequestData,
  formData: StripeFormDetails,
  resolve: (result: AgregatedProcessPaymentResponse) => void
) => void;

export const useStripeElements = ({
  stripeSetup: {
    SetupIntent: { client_secret },
  },
}: StripeElementsFormProps): UseStripeElementsReturnValues => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const { onFailure, dispatch: contextDispatch } = usePaymentContext();
  const { dispatch } = useStripePaymentsClientContext();
  const stripe = useStripe();
  const elements = useElements();
  const { handleValidationResult } = useStripeElementsValidations();
  const { handleSetupIntent } = useRetrieveSetupIntent();
  const { reset } = useFormContext<StripeFormDetails>();

  const submitCardSetup: SubmitCardSetup = useCallback(
    async (
      requestData,
      {
        city,
        postal_code,
        state,
        line1,
        line2,
        email,
        name,
        phone,
        saveInWallet,
      },
      resolveStripePayment
    ) => {
      const cardElement = elements?.getElement(CardNumberElement);
      dispatch(setError({}));
      contextDispatch(setIsPending(true));
      if (!stripe || !elements || !cardElement) {
        dispatch(setError({ message: localizedText(`STRIPE_LOAD_ERROR`) }));
        const errorResult = {
          type: FailureTypeValue.STRIPE_FAILURE,
          stripeFailureResponse: {
            message: localizedText(`STRIPE_LOAD_ERROR`),
          },
        };
        onFailure(errorResult);
        return resolveStripePayment({
          stripe: { ...errorResult },
        });
      }

      try {
        const result = await stripe.confirmCardSetup(client_secret as string, {
          payment_method: {
            card: cardElement,
            billing_details: {
              ...(name ? { name } : {}),
              ...(phone ? { phone } : {}),
              ...(email ? { email } : {}),
              address: {
                city: city,
                postal_code: postal_code,
                state: state,
                line1: line1,
                line2: line2,
              },
            },
          },
        });

        if (handleValidationResult(result)) {
          const setupIntentResponse = await handleSetupIntent(
            client_secret as string,
            {
              ...requestData,
              stripe: {
                ...requestData?.stripe,
                includedInWallet:
                  saveInWallet === undefined
                    ? requestData?.stripe?.includedInWallet
                    : saveInWallet,
              },
            }
          );
          elements?.getElement(CardNumberElement)?.clear();
          elements?.getElement(CardExpiryElement)?.clear();
          elements?.getElement(CardCvcElement)?.clear();
          reset();
          return resolveStripePayment({ ...setupIntentResponse });
        }

        return resolveStripePayment({
          stripe: {
            type: FailureTypeValue.STRIPE_FAILURE,
            stripeFailureResponse: {
              message: result?.error?.message,
              rawError: result,
            },
          },
        });
      } catch (e) {
        const errorResult = {
          type: FailureTypeValue.STRIPE_FAILURE,
          stripeFailureResponse: {
            message: localizedText(`STRIPE_UNKNOWN_ERROR`),
          },
        };
        onFailure(errorResult);
        dispatch(setError({ message: localizedText(`STRIPE_UNKNOWN_ERROR`) }));

        return resolveStripePayment({
          stripe: {
            ...errorResult,
          },
        });
      } finally {
        contextDispatch(setIsPending(false));
      }
    },
    [
      client_secret,
      elements,
      stripe,
      handleValidationResult,
      handleSetupIntent,
      onFailure,
      localizedText,
      dispatch,
      contextDispatch,
      reset,
    ]
  );
  return {
    submitCardSetup,
    stripe,
  };
};
