import { CardExpiryElement } from "@stripe/react-stripe-js";
// import { useStripePaymentsStateContext } from "../../../../../../contexts/stripe-payments-client-context";
import { StripeElementStyle } from "@stripe/stripe-js";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { StripeFormDetails } from "../../stripe-form";

export interface ExpirationDateInputProps {
  styles?: StripeElementStyle;
}

export const ExpirationDateInput: FC<ExpirationDateInputProps> = ({
  styles,
}) => {
  const { setError, clearErrors } = useFormContext<StripeFormDetails>();
  return (
    <CardExpiryElement
      data-testid={`Payment__Type__StripeElement_CardExpiry`}
      options={{
        style: {
          ...styles,
        },
        disabled: false,
      }}
      onChange={(event) => {
        event.error?.message
          ? setError("cardDateErrorInput", {
              type: event.error?.type,
              message: event.error?.message,
            })
          : clearErrors(["cardDateErrorInput"]);
      }}
    />
  );
};
