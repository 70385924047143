import React, { ReactElement } from "react";

export const BankAccountIcon = (): ReactElement => {
  return (
    <div data-testid="Bank_Processor_Icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="32"
        viewBox="0 0 31 32"
      >
        <path d="M14.073.39a2.805 2.805 0 012.854 0l13.58 7.976c.305.18.493.515.493.88v3.577c0 .557-.434 1.009-.969 1.009h-1.938v12.112h.97c1.07 0 1.937.904 1.937 2.019V32H0v-4.037c0-1.115.867-2.019 1.938-2.019h.968V13.832H.969c-.497 0-.907-.39-.962-.892L0 12.823V9.246c0-.365.188-.7.493-.88zm14.99 27.573H1.938v2.018h27.125v-2.018zm-16.47-14.131H8.718v12.112h3.875V13.832zm9.688 0h-3.875v12.112h3.875V13.832zm-15.5 0H4.844v12.112H6.78V13.832zm9.688 1.01H14.53v11.102h1.938V14.841zm9.687-1.01H24.22v12.112h1.937V13.832zM15.976 2.149a.935.935 0 00-.952 0L1.938 9.835v1.978h27.125V9.836zM15.5 4.748c1.605 0 2.906 1.356 2.906 3.028s-1.3 3.028-2.906 3.028c-1.605 0-2.906-1.356-2.906-3.028s1.3-3.028 2.906-3.028zm0 2.019c-.535 0-.969.452-.969 1.009 0 .557.434 1.01.969 1.01.535 0 .969-.453.969-1.01 0-.557-.434-1.01-.969-1.01z"></path>
      </svg>
    </div>
  );
};
