import { useAppContext } from "@ftdr/blueprint-components-react";
import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch, useCallback } from "react";
import {
  FailureTypeValue,
  PaymentMethodClient,
  RegistrationRequest,
  RegistrationResponse,
  SuccessTypeValue,
} from "../../../types";
import { usePaymentContext } from "../../payment-context";
import { setError, setIsLoading, setStripePaymentsClientState } from "../state";
import { decodeCommonError } from "../../../utils";

export const useCreatePaymentMethodRegistration = (
  dispatch: Dispatch<AnyAction>,
  client: PaymentMethodClient | undefined
): ((
  requestData: RegistrationRequest
) => Promise<RegistrationResponse | undefined>) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const { onFailure, onResponse } = usePaymentContext();

  const createPaymentMethodRegistration = useCallback(
    async (requestData: RegistrationRequest) => {
      let result = undefined;
      try {
        result = await client?.createPaymentMethodRegistration(requestData);
        dispatch(setStripePaymentsClientState(result?.data));
        onResponse({
          type: SuccessTypeValue.REGISTRATION_SUCCESS,
          registrationSuccessResponse: result?.data,
        });
        return result?.data;
      } catch (ex) {
        const decodedError = decodeCommonError(ex);
        const errorStatusCode = ex?.lastError?.response?.status;
        const errorObject = {
          isError: true,
          message: errorStatusCode
            ? localizedText(`ERROR_STATUS_CODE_${errorStatusCode}`)
            : localizedText(`NETWORK_ERROR`),
        };
        dispatch(setError(errorObject));
        onFailure({
          type: FailureTypeValue.STRIPE_FAILURE,
          stripeFailureResponse: {
            message: errorObject.message,
            decodedError: decodedError,
            rawError: ex,
          },
        });
      } finally {
        dispatch(setIsLoading(false));
      }
      return result?.data;
    },
    [client, dispatch, onFailure, onResponse, localizedText]
  );
  return createPaymentMethodRegistration;
};
