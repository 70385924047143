import { Stripe, StripeElementStyle } from "@stripe/stripe-js";
import { cardStyle } from "./stripe-elements.styles";

export type SetupItemTypeName =
  | "cardNumber"
  | "expirationDate"
  | "cvc"
  | "postal_code"
  | "city"
  | "state"
  | "line1"
  | "line2"
  | "name"
  | "email"
  | "phone"
  | "saveInWallet";

export type SetupItemTypeInternal<ValueType> = {
  /**
   * Property to set a row index to the specific element
   */
  row: number;
  /**
   * Title for specific input
   */
  label: string;
  /**
   * Name for specific input
   */
  name?: SetupItemTypeName;
  /**
   * The className to be used for set styles for non stripe elements
   */
  className?: string;
  /**
   * Property for set if input is required or not
   */
  required?: boolean | string;
  /**
   * The styles property for stripe elements input
   */
  styles?: StripeElementStyle;
  /**
   * Property for set default input value
   */
  defaultValue?: ValueType;
  /**
   * Validation message to be used after getting validation error
   */
  validationMessage?: string;
  /**
   * Validation callback function to validate input
   */
  validate?: (value: ValueType) => boolean;
};

export type SetupItemType<ValueType> = ValueType extends string
  ? SetupItemTypeInternal<string>
  : SetupItemTypeInternal<boolean>;

/**
 * StripeSetupType
 */
export type StripeSetupType = {
  /**
   * Object represents cardNumber stripe input
   */
  cardNumber: SetupItemType<string>;
  /**
   * Object represents expirationDate stripe input
   */
  expirationDate: SetupItemType<string>;
  /**
   * Object represents cvc stripe input
   */
  cvc?: SetupItemType<string>;
  /**
   * Object represents postal_code input
   */
  postal_code?: SetupItemType<string>;
  /**
   * Object represents city input
   */
  city?: SetupItemType<string>;
  /**
   * Object represents state input
   */
  state?: SetupItemType<string>;
  /**
   * Object represents line1 input
   */
  line1?: SetupItemType<string>;
  /**
   * Object represents line2 input
   */
  line2?: SetupItemType<string>;
  /**
   * Object represents name input
   */
  name?: SetupItemType<string>;
  /**
   * Object represents email input
   */
  email?: SetupItemType<string>;
  /**
   * Object represents phone input
   */
  phone?: SetupItemType<string>;
  /**
   * Object represents saveInWallet checkbox input
   */
  saveInWallet?: SetupItemType<boolean>;
};

export type InputName = keyof StripeSetupType;

export const InitialSetupItem: SetupItemType<string> = {
  row: -1,
  label: "",
};

export const DefaultSetup: StripeSetupType = {
  cardNumber: {
    row: 0,
    label: "Card Number",
    styles: cardStyle,
    className: "sm:col-start-1 sm:col-span-4 col-start-1 col-end-7 sm:pr-4",
  },
  expirationDate: {
    row: 0,
    label: "Expiration Date",
    className: "sm:col-span-2 sm:col-end-7 col-start-1 col-span-7",
    styles: cardStyle,
  },
};

export type StripeSetup = {
  PublicKey: string;
  SetupIntent: Record<string, unknown>;
};

export type InternalStripeClient = {
  client: Stripe | null;
  processPayment: () => void;
};
