import { CardCvcElement } from "@stripe/react-stripe-js";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { StripeFormDetails } from "../../stripe-form";
import { StripeElementStyle } from "@stripe/stripe-js";

export interface CvcInputProps {
  styles?: StripeElementStyle;
}

export const CvcInput: FC<CvcInputProps> = ({ styles }) => {
  const { setError, clearErrors } = useFormContext<StripeFormDetails>();
  return (
    <CardCvcElement
      data-testid={`Payment__Type__StripeElement_CardCvc`}
      options={{
        style: { ...styles },
        disabled: false,
      }}
      onChange={(event) => {
        event.error?.message
          ? setError("cvcErrorInput", {
              type: event.error?.type,
              message: event.error?.message,
            })
          : clearErrors(["cvcErrorInput"]);
      }}
    />
  );
};
