import {
  AvailablePaymentMethods,
  defaultAvailablePaymentMethods,
  PaymentMethods,
} from "../const";

type ReturnType = {
  name: PaymentMethods;
  paymentProcessor: JSX.Element;
  icon: JSX.Element;
}[];

type RenderItems = Record<
  PaymentMethods,
  { paymentProcessor: JSX.Element; icon: JSX.Element }
>;

export const generateAvailablePaymentMethodsArray = (
  paymentMethods: AvailablePaymentMethods = defaultAvailablePaymentMethods,
  renderItems: RenderItems
): ReturnType => {
  const paymentMethodsKeys = Object.keys(paymentMethods) as Array<
    keyof typeof paymentMethods
  >;

  return paymentMethodsKeys.reduce<ReturnType>((acc, key, index) => {
    paymentMethods[key] &&
      (acc[index] = {
        ...renderItems[key],
        name: key,
      });
    return acc;
  }, []);
};
