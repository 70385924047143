import {
  ProgressIndicator,
  Notification,
} from "@ftdr/blueprint-components-react";
import { Stripe } from "@stripe/stripe-js";
import classNames from "classnames";
import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { usePaymentContext } from "../../../../../contexts/payment-context";
import { useStripePaymentsStateContext } from "../../../../../contexts/stripe-payments-client-context";
import {
  AgregatedProcessPaymentResponse,
  PaymentProcessorClientInternalRef,
  FailureTypeValue,
  ProcessPaymentStripeRequestData,
} from "../../../types";
import { GenericInput } from "./components";
import { DefaultSetup, StripeSetup } from "./consts";
import { useStripeElements } from "./hooks";
import { generateSetupArray } from "./utils";
import { StripeFormDetails } from "../stripe-form";

export type StripeElementsFormProps = {
  stripeSetup: StripeSetup;
};

export const StripeElements = forwardRef<
  PaymentProcessorClientInternalRef<
    ProcessPaymentStripeRequestData,
    Stripe | null
  >
>((_props, ref) => {
  const { state } = useStripePaymentsStateContext();
  const { stripeSetup, state: contextState, onFailure } = usePaymentContext();
  const { handleSubmit } = useFormContext<StripeFormDetails>();

  const { submitCardSetup, stripe } = useStripeElements({
    stripeSetup: {
      SetupIntent: {
        client_secret:
          state.clientSetup.registration?.stripe?.setupIntentClientSecret || "",
      },
      PublicKey: state.clientSetup.registration?.stripe?.publicKey || "",
    },
  });

  const elementsArray = useMemo(() => {
    try {
      return generateSetupArray(stripeSetup);
    } catch (e) {
      console.error(
        "Wrong Config provided, using default one for Stripe Elements."
      );
      return generateSetupArray(DefaultSetup);
    }
  }, [stripeSetup]);

  useImperativeHandle(ref, () => ({
    processPayment: (requestData) => {
      return new Promise<AgregatedProcessPaymentResponse>(
        (resolveStripePayment) => {
          handleSubmit(
            (formData: StripeFormDetails) => {
              submitCardSetup(requestData, formData, resolveStripePayment);
            },
            (err) => {
              onFailure({
                type: FailureTypeValue.NOT_VALID_STRIPE_FORM,
                stripeFormErrors: err,
              });
              resolveStripePayment({
                stripe: {
                  type: FailureTypeValue.NOT_VALID_STRIPE_FORM,
                  stripeFormErrors: err,
                },
              });
            }
          )();
        }
      );
    },
    getClient: () => stripe,
  }));

  return (
    <div
      data-testid={`Payment__Type__StripeElement`}
      className={classNames("flex flex-col w-full")}
    >
      {state.error?.message && (
        <div className={"mb-6"}>
          <Notification
            variant="inline"
            status="error"
            size="medium"
            showStatusLabel={true}
            inline={true}
            className={"w-full"}
            data-testid="stripe-elements-error-notification"
          >
            {state.error.message}
          </Notification>
        </div>
      )}
      <div className={"flex w-full lg:justify-between flex-col relative"}>
        {contextState.isPending && (
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center z-10">
            <ProgressIndicator size="medium" color="primary" />
          </div>
        )}
        {/* Render Elements */}
        <div>
          {elementsArray.map((row, index) => {
            return (
              <div key={index} className="grid grid-cols-6">
                {row.map((item) => (
                  <GenericInput<typeof item.defaultValue>
                    key={item.name}
                    {...item}
                  />
                ))}
              </div>
            );
          })}
        </div>
        {/* Render Elements */}
      </div>
    </div>
  );
});

StripeElements.displayName = "StripeElements";
