import { SetupIntentResult } from "@stripe/stripe-js";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { usePaymentContext } from "../../../../../../contexts/payment-context";
import { useStripePaymentsClientContext } from "../../../../../../contexts/stripe-payments-client-context";
import { setError } from "../../../../../../contexts/stripe-payments-client-context/state";
import { FailureTypeValue } from "../../../../types";
import { StripeElementsValidationMap } from "../../stripe-elements/components/generic-input";
import { StripeFormDetails } from "../../stripe-form";
import { SetupItemTypeName } from "../consts";

export type UseStripeElementsValidationReturnType = {
  handleValidationResult: (result: SetupIntentResult) => boolean;
};

export const useStripeElementsValidations = (): UseStripeElementsValidationReturnType => {
  const { dispatch } = useStripePaymentsClientContext();
  const { onFailure } = usePaymentContext();

  const { setError: setFormError } = useFormContext<StripeFormDetails>();

  const parseCustomStripeError = useCallback((result?: string) => {
    try {
      const parseResult = result?.split("][")[1]?.replace("]", "");
      if (parseResult) {
        return StripeElementsValidationMap[parseResult as SetupItemTypeName];
      }
    } catch (ex) {
      return undefined;
    }
    return undefined;
  }, []);

  const handleValidationResult = useCallback(
    (result: SetupIntentResult) => {
      if (result?.error && result?.error.type) {
        onFailure({
          type: FailureTypeValue.STRIPE_FAILURE,
          stripeFailureResponse: {
            message: result.error.message,
            rawError: result,
          },
        });
        const parsedFieldName = parseCustomStripeError(result?.error?.param);
        switch (`${result?.error.type}-${result?.error.code}`) {
          case "validation_error-incomplete_number":
          case "validation_error-invalid_number":
            setFormError("cardNumberErrorInput", {
              type: result.error.type,
              message: result.error.message,
            });
            return false;
          case "validation_error-incomplete_expiry":
            setFormError("cardDateErrorInput", {
              type: result.error.type,
              message: result.error.message,
            });
            return false;
          case "invalid_request_error-email_invalid":
            setFormError("email", {
              type: result.error.type,
              message: result.error.message,
            });
            return false;
          case "invalid_request_error-parameter_invalid_empty":
            parsedFieldName &&
              setFormError(parsedFieldName, {
                type: result.error.type,
                message: `You passed an empty string for ${parsedFieldName} field.`,
              });
            return false;
          case "invalid_request_error-parameter_unknown":
            parsedFieldName &&
              setFormError(parsedFieldName, {
                type: result.error.type,
                message: `You passed unknown parameter for ${parsedFieldName} field.`,
              });
            return false;
          default:
            dispatch(setError(result?.error));
            return false;
        }
      }
      return true;
    },
    [onFailure, setFormError, dispatch, parseCustomStripeError]
  );
  return {
    handleValidationResult,
  };
};
