import { ValidationRoutingNumberResponse } from "@ftdr/cscpayments-js-client/lib/types/types";
import {
  common,
  payment3_paymentmethodpb,
} from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { SetupIntentResult } from "@stripe/stripe-js";
import { FieldErrors } from "react-hook-form";
import { MethodSetupResponse, RegistrationResponse } from "../../types";
import { StripeFormDetails } from "./stripe-card-processor/components";

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type PaymentProcessorApi = {
  processPayment: () => void;
};

export type PaymentProcessorProps<
  Config extends Record<string, unknown> = Record<string, unknown>
> = {
  config: Config;
};

export type PaymentProcessorResponse = {
  onSuccess: (response: SuccessResponseType) => void;
  onFailure: (response: FailureResponseType) => void;
  onResponse: (response: ResponseType) => void;
};

export type SuccessType =
  | "stripe_success"
  | "ach_success"
  | "registration_success"
  | "ach_valid_number"
  | "valid_routing_number"
  | "not_valid_routing_number";

export const SuccessTypeValue: Record<string, SuccessType> = {
  STRIPE_SUCCESS: "stripe_success",
  REGISTRATION_SUCCESS: "registration_success",
  ACH_SUCCESS: "ach_success",
  VALID_ROUTING_NUMBER: "valid_routing_number",
  NOT_VALID_ROUTING_NUMBER: "not_valid_routing_number",
};

export type AchSuccessResponse = {
  bankAccountNumber: string;
  bankRoutingNumber: string;
};

export type SuccessResponseType = {
  type: SuccessType;
  stripeSuccessResponse?: MethodSetupResponse;
  achSuccessResponse?: AchSuccessResponse;
};

export type FailureType =
  | "stripe_failure"
  | "ach_failure"
  | "not_valid_routing_number"
  | "not_valid_ach_form"
  | "not_valid_stripe_form";

export const FailureTypeValue: Record<string, FailureType> = {
  STRIPE_FAILURE: "stripe_failure",
  ACH_FAILURE: "ach_failure",
  NOT_VALID_ROUTING_NUMBER: "not_valid_routing_number",
  NOT_VALID_ACH_FORM: "not_valid_ach_form",
  NOT_VALID_STRIPE_FORM: "not_valid_stripe_form",
};

export type DecodedErrorType = string[] | common.IError[];

export type StripeErrorType = {
  message?: string;
  decodedError?: DecodedErrorType;
  rawError?: Record<string, unknown>;
};

export interface IFormInput {
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountNumberConfirmation: string;
}

export type FailureResponseType = {
  type: FailureType;
  stripeFailureResponse?: StripeErrorType;
  failureValidationResponse?: ValidationRoutingNumberResponse;
  achFormErrors?: FieldErrors<IFormInput>;
  stripeFormErrors?: FieldErrors<StripeFormDetails>;
};

export type OnResponseType =
  | "registration_success"
  | "setup_card_success"
  | "setup_card_intent"
  | SuccessType
  | FailureType;

export const OnResponseTypeValue: Record<string, OnResponseType> = {
  REGISTRATION_SUCCESS: "registration_success",
  SETUP_CARD_SUCCESS: "setup_card_success",
  SETUP_CARD_INTENT: "setup_card_intent",
  ...SuccessTypeValue,
};

export type ResponseType = {
  type: OnResponseType;
  registrationSuccessResponse?: RegistrationResponse;
  setupCardSuccessResponse?: Partial<SetupIntentResult>;
  successValidationResponse?: ValidationRoutingNumberResponse;
} & Partial<
  Pick<SuccessResponseType, "stripeSuccessResponse" | "achSuccessResponse">
>;

export type PaymentProcessorClientInternalRef<
  RequestType,
  Client = Record<string, unknown> | undefined
> = {
  getClient: () => Client;
  processPayment: ProcessPayment<RequestType>;
};

export type PaymentProcessorClientRef<
  RequestType,
  Client = Record<string, unknown>
> = {
  client?: Client;
  processPayment: ProcessPayment<RequestType>;
};

export type ProcessPaymentRequestData =
  | ProcessPaymentStripeRequestData
  | ProcessPaymentACHRequestData;

interface IIdentity {
  contractIdentity?: RequireAtLeastOne<
    payment3_paymentmethodpb.IContractIdentity,
    "contractID" | "contractPaymentID"
  >;
  customerIdentity?: payment3_paymentmethodpb.ICustomerIdentity;
  replacementIdentity?: payment3_paymentmethodpb.IApplianceReplacementIdentity;
  dispatchIdentity?: payment3_paymentmethodpb.IDispatchIdentity;
}

export type ProcessPaymentStripeRequestData = {
  stripe: {
    owner: {
      ownerIdentity: {
        type: payment3_paymentmethodpb.OwnerIdentityType | null;
      } & RequireAtLeastOne<
        IIdentity,
        | "contractIdentity"
        | "customerIdentity"
        | "replacementIdentity"
        | "dispatchIdentity"
      >;
    };
  } & Pick<
    payment3_paymentmethodpb.IPaymentMethod,
    "owner" | "includedInWallet"
  >;
};
export type ProcessPaymentACHRequestData = {
  ach?: Record<string, unknown>;
};

export type AgregatedProcessPaymentResponse = {
  stripe?: {
    type?: SuccessType | FailureType;
  } & Pick<SuccessResponseType, "stripeSuccessResponse"> &
    Pick<FailureResponseType, "stripeFailureResponse" | "stripeFormErrors">;
  ach?: {
    type?: SuccessType | FailureType;
  } & Pick<SuccessResponseType, "achSuccessResponse"> &
    Pick<FailureResponseType, "achFormErrors">;
};

export type ProcessPayment<T> = (
  requestData: T,
  resolve?: (result: AgregatedProcessPaymentResponse) => void
) => Promise<AgregatedProcessPaymentResponse | undefined> | undefined;
