import { Input, useAppContext } from "@ftdr/blueprint-components-react";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { achError } from "../ach-form";

export const AchAccountNumberConfirmationInput: FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const {
    formState: { errors },
    getValues,
    control,
  } = useFormContext();

  return (
    <Controller
      name="bankAccountNumberConfirmation"
      control={control}
      rules={{
        required: true,
        validate: (value) => {
          return value === getValues("bankAccountNumber") || "confirm";
        },
      }}
      render={(field) => (
        <Input
          {...field}
          required
          label={localizedText("ACCOUNT_NUMBER_CONFIRMATION_LABEL")}
          labelProps={{ className: "bold" }}
          formFieldClassName="mb-2"
          inputClassName="bg-white"
          formField
          type="text"
          inputMode="numeric"
          placeholder="00000000000"
          error={localizedText(
            achError[
              errors?.bankAccountNumberConfirmation?.message ||
                errors?.bankAccountNumberConfirmation?.type ||
                ""
            ]
          )}
          data-testid={"Payment__Type__ACH_Account_Number_Confirmation_Input"}
        />
      )}
    />
  );
};
