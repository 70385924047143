export type CardProcessorType = "stripe";
export type PaymentMethods = "CREDIT_CARD" | "BANK_ACCOUNT";

export const PAYMENTS_METHODS: Record<PaymentMethods, PaymentMethods> = {
  CREDIT_CARD: "CREDIT_CARD",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};

export type AvailablePaymentMethods = Record<PaymentMethods, boolean>;

export const defaultAvailablePaymentMethods: AvailablePaymentMethods = {
  CREDIT_CARD: true,
  BANK_ACCOUNT: true,
};
