import { JsonHttpClient } from "@ftdr/http-utils";
import { MicroService } from "@ftdr/micro-services-shared";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { retry } from "ts-retry-promise";
import {
  CreateCscPaymentRequest,
  CreateCscPaymentResponse,
  ValidationRoutingNumberRequest,
  ValidationRoutingNumberResponse,
} from "./types";

export class CscPaymentsJSClient extends MicroService {
  static serviceName = "csc-payments";

  /**
   * The use of the _definite assignment assertion_ (!) is required here
   * because we're neither initializing this property here, not is it apparent
   * to TS that is it being initialized in the constructor body.
   *
   * @see {@link https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-7.html#strict-class-initialization}
   */

  protected httpClient: JsonHttpClient;

  constructor(baseURL: string, httpClient: JsonHttpClient) {
    super(baseURL, httpClient);
    this.httpClient = httpClient;
  }

  validationRoutingNumber = async (
    requestData: ValidationRoutingNumberRequest,
    retryCount = 0,
    backoff = 0
  ): Promise<AxiosResponse<ValidationRoutingNumberResponse>> => {
    const requestPrams: AxiosRequestConfig = {
      params: requestData,
    };
    return retry(
      () =>
        Promise.resolve(
          this.httpClient.get(
            this.makeServiceUrl("/achroutingnumberisvalid"),
            requestPrams
          )
        ),
      { retries: retryCount, delay: backoff }
    );
  };

  createCscPaymentsMethod = async (
    requestData: CreateCscPaymentRequest,
    retryCount = 0,
    backoff = 0
  ): Promise<AxiosResponse<CreateCscPaymentResponse>> => {
    return retry(
      () =>
        Promise.resolve(
          this.httpClient.post(this.makeServiceUrl(""), requestData)
        ),
      { retries: retryCount, delay: backoff }
    );
  };
}
