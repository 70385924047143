import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MethodSetupResponse, RegistrationResponse } from "../../../types";
import { StripeError } from "@stripe/stripe-js";

export type StripePaymentsClientState = {
  isLoading?: boolean;
  clientSetup: RegistrationResponse;
  paymentMethodSetup: MethodSetupResponse;
  error: {
    isError?: boolean;
    message?: string;
    stripeError?: Partial<StripeError>;
  };
  isComplete?: boolean;
};

export const initialState: StripePaymentsClientState = {
  clientSetup: {},
  paymentMethodSetup: {},
  isLoading: true,
  error: { isError: false, message: "", stripeError: {} },
  isComplete: false,
};

export const stripePaymentsClientSetupSlice = createSlice({
  name: "stripePaymentsClientSetupState",
  initialState,
  reducers: {
    setStripePaymentsClientState(
      state,
      { payload }: PayloadAction<RegistrationResponse | undefined>
    ) {
      state.error = { message: "", isError: false };
      state.clientSetup = payload || initialState.clientSetup;
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setIsComplete(state, { payload }: PayloadAction<boolean>) {
      state.isComplete = payload;
    },
    setStripePaymentsMethodState(
      state,
      { payload }: PayloadAction<MethodSetupResponse | undefined>
    ) {
      state.error = { message: "", isError: false };
      state.paymentMethodSetup = payload || initialState.paymentMethodSetup;
    },
    setError(
      state,
      {
        payload,
      }: PayloadAction<{
        isError?: boolean;
        message?: string;
        stripeError?: Partial<StripeError>;
      }>
    ) {
      state.error = payload;
    },
  },
});

export const {
  setError,
  setStripePaymentsClientState,
  setStripePaymentsMethodState,
  setIsLoading,
  setIsComplete,
} = stripePaymentsClientSetupSlice.actions;
