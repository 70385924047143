import { common } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { DecodedErrorType } from "../components/payment-processors/types";

type ResponseErrorType = {
  lastError: {
    response: {
      data: Uint8Array;
    };
    message: string;
  };
  message: string;
};

export const decodeCommonError = (
  error: ResponseErrorType
): DecodedErrorType => {
  if (error.lastError?.response) {
    try {
      const errorResponse = common.ErrorResponse.decode(
        new Uint8Array(error.lastError?.response?.data)
      );
      return errorResponse.errors;
    } catch (ex) {
      return [error.message || error.lastError?.message];
    }
  }
  return [];
};
