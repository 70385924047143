import React, { useRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { Payment3_paymentmethod_coordinatorJSClient } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import {
  ProtobufHttpClient,
  TokenHandlingHttpClient,
  JsonHttpClient,
} from "@ftdr/http-utils";
import { CscPaymentsJSClient } from "@ftdr/cscpayments-js-client";
import { App } from "@ftdr/blueprint-components-react";
import { enTextTemplates } from "./assets/i18n/en-text-templates";
import {
  PaymentsComponent,
  PaymentsComponentExposedApi,
  PaymentsComponentProps,
} from "./components";
import { PaymentProcessorResponse } from "./components/payment-processors/types";
import {
  PaymentContextValueProps,
  PaymentsContext,
} from "./contexts/payment-context";
import { PaymentMethods } from "./const";

export type PaymentsMethodMicroFrontendOwnProps = {
  /**
   * The baseURL to be used for the PaymentsMethodMicroService.for
   */
  baseURL: string;
  /**
   * The token to be used for the PaymentsMethodMicroService.
   */
  token: string;
  /**
   * The hostJsClient to be used for the PaymentsMethodMicroService, when it is passed in props by host .
   */
  hostJsClient?: Payment3_paymentmethod_coordinatorJSClient;
};

export type PaymentsComponentPassthroughProps = Pick<
  PaymentsComponentProps,
  "className" | "cardPaymentProcessor"
> &
  Partial<
    Pick<PaymentProcessorResponse, "onSuccess" | "onFailure" | "onResponse">
  > &
  PaymentContextValueProps;

export type PaymentMethodMicroFrontendProps = PaymentsMethodMicroFrontendOwnProps &
  PaymentsComponentPassthroughProps;

// MFE exposed imperative Api
export type PaymentsMethodMfeExposedApi = PaymentsComponentExposedApi;

export const PaymentMethodMicroFrontend = forwardRef<
  PaymentsMethodMfeExposedApi | undefined,
  PaymentMethodMicroFrontendProps
>(
  (
    {
      // Own prop
      baseURL,
      token,
      hostJsClient,
      // IQ Component passthrough props
      applicationName,
      initialState,
      state,
      className,
      onStateChange,
      cardPaymentProcessor,
      onFailure,
      onResponse,
      onSuccess,
      stripeSetup,
      defaultOpenPaymentMethod,
      availablePaymentMethods,
    },
    ref
  ) => {
    const paymentsMethodRef = useRef<PaymentsComponentExposedApi>(null);
    useImperativeHandle(ref, () => ({
      processPayment: (requestData) =>
        paymentsMethodRef?.current?.processPayment(requestData),
      getClient: (paymentMethod) =>
        paymentsMethodRef?.current?.getClient(paymentMethod),
      getCurrentActivePaymentMethod: () =>
        paymentsMethodRef?.current?.getCurrentActivePaymentMethod(),
      setOpenPaymentMethod: (activePaymentMethod?: PaymentMethods) =>
        paymentsMethodRef?.current?.setOpenPaymentMethod(activePaymentMethod),
    }));

    const cscPaymentsJsClient = useMemo(() => {
      const tokenHttpClient = new TokenHandlingHttpClient(token);
      return new CscPaymentsJSClient(
        baseURL,
        new JsonHttpClient(tokenHttpClient)
      );
    }, [baseURL, token]);

    const paymentsTokenCoordinatorJSClient = useMemo(() => {
      if (hostJsClient) {
        return hostJsClient;
      }
      const tokenHttpClient = new TokenHandlingHttpClient(token);
      const protoBuffHttpClient = new ProtobufHttpClient(tokenHttpClient);
      return new Payment3_paymentmethod_coordinatorJSClient(
        baseURL,
        protoBuffHttpClient
      );
    }, [baseURL, hostJsClient, token]);

    const contextValue = useMemo<
      PaymentProcessorResponse & PaymentContextValueProps
    >(
      () => ({
        applicationName,
        onSuccess: onSuccess ? onSuccess : () => undefined,
        onFailure: onFailure ? onFailure : () => undefined,
        onResponse: onResponse ? onResponse : () => undefined,
        state,
        initialState,
        onStateChange,
        stripeSetup,
        defaultOpenPaymentMethod,
        availablePaymentMethods,
      }),
      [
        applicationName,
        onSuccess,
        onFailure,
        onResponse,
        state,
        onStateChange,
        initialState,
        stripeSetup,
        defaultOpenPaymentMethod,
        availablePaymentMethods,
      ]
    );

    return (
      <App
        className="payment-method-micro-frontend"
        appSettings={{
          language: "en_US",
          textTemplatesByLanguage: {
            en_US: enTextTemplates,
          },
        }}
      >
        {
          // This container div allow us to encapsulate styles in angular applications which is not using custom elements
          // The usage of custom element is not available for this mfe since stripe-elements not support rendering inside shadow root
        }
        <PaymentsContext value={contextValue}>
          <PaymentsComponent
            // props based on internal state/ref
            paymentMethodCoordinatorClient={paymentsTokenCoordinatorJSClient}
            cscJsClient={cscPaymentsJsClient}
            // passthrough props
            className={className}
            cardPaymentProcessor={cardPaymentProcessor}
            ref={paymentsMethodRef}
          />
        </PaymentsContext>
      </App>
    );
  }
);

PaymentMethodMicroFrontend.displayName = "PaymentMethodMicroFrontend";
