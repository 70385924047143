import { Radio } from "@ftdr/blueprint-components-react";
import React, { FC } from "react";
import { PaymentMethods } from "../../const";
import { usePaymentContext } from "../../contexts/payment-context";
import { setInternalContextState } from "../../contexts/payment-context/state";

export interface PaymentTypeItemProps {
  activePaymentMethod?: PaymentMethods;
  icon?: JSX.Element;
  paymentTypeRenderItem?: JSX.Element;
  paymentTypeLabel: string;
  paymentType: PaymentMethods;
  handleOnChange: (activePaymentMethod: PaymentMethods) => void;
}

export const PaymentTypeItem: FC<PaymentTypeItemProps> = ({
  activePaymentMethod,
  icon,
  paymentTypeRenderItem,
  paymentType,
  paymentTypeLabel,
  handleOnChange,
}) => {
  const { state, dispatch } = usePaymentContext();

  return (
    <div className="border-solid border-1 border-gray-300 rounded-3 ">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex justify-between shadow-raised px-6 py-6 cursor-pointer"
        onClick={() =>
          dispatch(
            setInternalContextState({
              ...state.internalState,
              activePaymentMethod: paymentType,
            })
          )
        }
      >
        <Radio
          id={`Payment__Type__Radio_${paymentType}`}
          label={paymentTypeLabel}
          value={paymentType}
          disabled={state.isPending}
          checked={activePaymentMethod === paymentType}
          onChange={() => handleOnChange(paymentType)}
          className="mr-2"
          data-testid={`Payment__Type__Radio_${paymentType}`}
        />
        {icon}
      </div>
      {activePaymentMethod === paymentType && (
        <div className="sm:px-8 px-4 py-4 bg-gray-100">
          {paymentTypeRenderItem}
        </div>
      )}
    </div>
  );
};
