import {
  DefaultSetup,
  InitialSetupItem,
  SetupItemType,
  SetupItemTypeName,
  StripeSetupType,
} from "../consts";
import { cardStyle } from "../stripe-elements.styles";
import { StripeFormDetails } from "../../stripe-form";

type ReturnType = Array<Array<SetupItemType<boolean> | SetupItemType<string>>>;

const REQUIRED_PROPERTIES: SetupItemTypeName[] = [
  "cardNumber",
  "expirationDate",
];

export const generateSetupArray = (
  setup: StripeSetupType = DefaultSetup
): ReturnType => {
  const setupKeys = Object.keys(setup) as Array<keyof typeof setup>;
  if (
    !REQUIRED_PROPERTIES.every((requiredProperty) =>
      setupKeys.includes(requiredProperty)
    )
  ) {
    throw Error("Required properties missing (cardNumber,expirationDate)");
  }

  return setupKeys.reduce<ReturnType>((acc, key) => {
    const defaultSetupItemValue = setup[key]?.defaultValue || "";
    const setupItem: SetupItemType<typeof defaultSetupItemValue> =
      setup[key] || InitialSetupItem;
    acc[setupItem?.row] = [
      ...(acc[setupItem?.row] || []),
      {
        ...setupItem,
        name: key,
        styles: { ...cardStyle, ...setupItem.styles },
      },
    ];
    return acc;
  }, []);
};

export const generateDefaultValueObject = (
  setup: StripeSetupType = DefaultSetup
): Partial<StripeFormDetails> => {
  const setupKeys = Object.keys(setup) as Array<
    keyof Omit<typeof setup, "cardNumber" | "expirationDate" | "cvc">
  >;
  return setupKeys.reduce<StripeFormDetails>((acc: StripeFormDetails, key) => {
    if (key === "saveInWallet") {
      acc[key] = !!setup[key]?.defaultValue;
    } else {
      acc[key] = setup[key]?.defaultValue || "";
    }
    return acc;
  }, {});
};
