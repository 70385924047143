import { useAppContext } from "@ftdr/blueprint-components-react";
import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch, useCallback } from "react";
import {
  FailureTypeValue,
  MethodSetupRequest,
  PaymentMethodClient,
  SuccessTypeValue,
} from "../../../types";
import { usePaymentContext } from "../../payment-context";
import { setError, setStripePaymentsMethodState } from "../state";
import { CreatePaymentMethodResultType } from "../stripe-payments-client-context";
import { setIsPending } from "../../payment-context/state";
import { decodeCommonError } from "../../../utils";

export const useCreatePaymentMethod = (
  dispatch: Dispatch<AnyAction>,
  client: PaymentMethodClient | undefined
): ((
  requestData: MethodSetupRequest
) => Promise<CreatePaymentMethodResultType>) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const {
    onFailure,
    onSuccess,
    onResponse,
    dispatch: contextDispatch,
  } = usePaymentContext();
  const createPaymentMethod = useCallback(
    async (
      requestData: MethodSetupRequest
    ): Promise<CreatePaymentMethodResultType> => {
      contextDispatch(setIsPending(true));
      let result = undefined;
      try {
        result = await client?.createPaymentMethod(requestData);
        dispatch(setStripePaymentsMethodState(result?.data));
        const resultObject = {
          type: SuccessTypeValue.STRIPE_SUCCESS,
          stripeSuccessResponse: result?.data,
        };
        onSuccess(resultObject);
        onResponse(resultObject);
        return { result: result?.data };
      } catch (ex) {
        const decodedError = decodeCommonError(ex);
        const errorStatusCode = ex?.lastError?.response?.status;
        const errorObject = {
          isError: true,
          message: errorStatusCode
            ? localizedText(`ERROR_STATUS_CODE_${errorStatusCode}`)
            : localizedText(`NETWORK_ERROR`),
        };
        const errorResult = {
          type: FailureTypeValue.STRIPE_FAILURE,
          stripeFailureResponse: {
            message: errorObject.message,
            decodedError: decodedError,
            rawError: ex,
          },
        };
        dispatch(setError(errorObject));
        onFailure(errorResult);
        return { error: errorResult };
      } finally {
        contextDispatch(setIsPending(false));
      }
    },

    [
      client,
      onSuccess,
      onResponse,
      dispatch,
      localizedText,
      onFailure,
      contextDispatch,
    ]
  );
  return createPaymentMethod;
};
