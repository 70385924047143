import {
  Checkbox,
  FormField,
  Input,
  Message,
  useAppContext,
} from "@ftdr/blueprint-components-react";
import React, { ReactElement, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StripeFormDetails, stripeError } from "../../stripe-form";
import { SetupItemType, SetupItemTypeName } from "../consts";
import { CardNumberInput, CvcInput, ExpirationDateInput } from "./";

export const StripeElementsNames: Array<SetupItemTypeName> = [
  "cardNumber",
  "expirationDate",
  "cvc",
];

export const StripeElementsValidationMap: Record<
  SetupItemTypeName,
  keyof StripeFormDetails
> = {
  cardNumber: "cardNumberErrorInput",
  expirationDate: "cardDateErrorInput",
  cvc: "cvcErrorInput",
  postal_code: "postal_code",
  city: "city",
  state: "state",
  line1: "line1",
  line2: "line2",
  name: "name",
  email: "email",
  phone: "phone",
  saveInWallet: "saveInWallet",
};

export const GenericInput = <D extends unknown>({
  label,
  name,
  className,
  styles,
  required,
  defaultValue,
  validate,
  validationMessage,
}: SetupItemType<D>): ReactElement => {
  const {
    formState: { errors },
    control,
  } = useFormContext<StripeFormDetails>();
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const isStripeElement = useMemo(
    () => !!StripeElementsNames.includes(name || ("" as SetupItemTypeName)),
    [name]
  );
  const isTextElement = useMemo(
    () => name && name !== "saveInWallet" && !isStripeElement,
    [isStripeElement, name]
  );
  return (
    <div className={`w-full ${className}`}>
      {name && isStripeElement && (
        <FormField
          label={label}
          error={errors[StripeElementsValidationMap[name]]?.message}
          className="mb-2"
        >
          {() => {
            switch (name) {
              case "cardNumber":
                return <CardNumberInput styles={styles} />;
              case "expirationDate":
                return <ExpirationDateInput styles={styles} />;
              case "cvc":
                return <CvcInput styles={styles} />;
              default:
                return <Message status="error">Input Error</Message>;
            }
          }}
        </FormField>
      )}
      {name && isTextElement && !isStripeElement && (
        <Controller
          name={StripeElementsValidationMap[name]}
          rules={{
            required: required && localizedText(stripeError.required),
            validate: (value) => {
              if (validate && !validate(value)) {
                return validationMessage || false;
              } else {
                return true;
              }
            },
          }}
          render={(field) => (
            <Input
              {...field}
              label={label}
              labelProps={{ className: "bold" }}
              inputClassName="bg-white"
              formFieldClassName="mb-2"
              formField
              type="text"
              data-testid={`Payment__Type__Stripe__${name}`}
              error={
                errors[StripeElementsValidationMap[name]]?.message ||
                localizedText(
                  stripeError[
                    errors[StripeElementsValidationMap[name]]?.type || ""
                  ] || ""
                )
              }
            />
          )}
        />
      )}
      {name && !isTextElement && !isStripeElement && (
        <Controller
          name={StripeElementsValidationMap[name]}
          control={control}
          defaultValue={defaultValue || false}
          render={(field) => (
            <Checkbox
              name={name}
              label={label}
              checked={!!field.value}
              data-testid={`Payment__Type__Stripe__saveInWallet`}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      )}
    </div>
  );
};
