import { createContext, useContext } from "react";
import { initialState, StripePaymentsClientState } from "./state";

export type StripePaymentsStateContextValue = {
  state: StripePaymentsClientState;
};

export const StripePaymentsClientStateInitialState: StripePaymentsStateContextValue = {
  state: initialState,
};

export const StripePaymentsStateContext = createContext<StripePaymentsStateContextValue>(
  StripePaymentsClientStateInitialState
);

export const {
  Provider: StripePaymentsStateContextProvider,
} = StripePaymentsStateContext;

export const useStripePaymentsStateContext = (): StripePaymentsStateContextValue =>
  useContext<StripePaymentsStateContextValue>(StripePaymentsStateContext);
