import React, { forwardRef } from "react";
import { PaymentsComponentOwnProps } from "../../payments-component";
import {
  PaymentProcessorClientInternalRef,
  PaymentProcessorProps,
  ProcessPaymentACHRequestData,
} from "../types";
import { AchForm } from "./components/ach-form";
import {
  CscPaymentsClientContext,
  useCscPaymentClientContext,
} from "../../../contexts/csc-payments-client-context";
import { Notification } from "@ftdr/blueprint-components-react";

export type AchBankProcessorProps = PaymentProcessorProps;
const AchBankProcessorInternal = forwardRef<
  PaymentProcessorClientInternalRef<ProcessPaymentACHRequestData>,
  Pick<PaymentsComponentOwnProps, "cscJsClient"> & AchBankProcessorProps
>(({ config }, ref) => {
  const { state } = useCscPaymentClientContext();

  return (
    <>
      {state.error.isError ? (
        <Notification
          variant="inline"
          status="error"
          size="medium"
          showStatusLabel={true}
          inline={true}
        >
          <>{state.error.message}</>
        </Notification>
      ) : (
        <AchForm
          config={config}
          ref={ref}
          data-testid={"Payment__Type__Ach_AchForm"}
        />
      )}
    </>
  );
});

AchBankProcessorInternal.displayName = "AchBankProcessorInternal";

export const AchBankProcessor = forwardRef<
  PaymentProcessorClientInternalRef<ProcessPaymentACHRequestData>,
  Pick<PaymentsComponentOwnProps, "cscJsClient"> & AchBankProcessorProps
>(({ cscJsClient, config }, ref) => {
  return (
    <CscPaymentsClientContext client={cscJsClient}>
      <AchBankProcessorInternal config={config} ref={ref} />
    </CscPaymentsClientContext>
  );
});

AchBankProcessor.displayName = "BankProcessor";
