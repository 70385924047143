import { Input, useAppContext } from "@ftdr/blueprint-components-react";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { achError } from "../ach-form";
import { useRoutingNumberValidation } from "../hooks";

export const AchRoutingNumberInput: FC = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const {
    appSettings: { localizedText },
  } = useAppContext();

  const { routingNumberValidation } = useRoutingNumberValidation();

  return (
    <div>
      <Controller
        name="bankRoutingNumber"
        control={control}
        rules={{
          required: true,
          minLength: 9,
          maxLength: 9,
          validate: routingNumberValidation,
        }}
        render={(field) => (
          <Input
            {...field}
            required
            label={localizedText("ROUTING_NUMBER_LABEL")}
            labelProps={{ className: "bold" }}
            inputClassName="bg-white"
            formFieldClassName="mb-2"
            formField
            type="text"
            inputMode="numeric"
            placeholder="00000000000"
            error={localizedText(
              achError[errors?.bankRoutingNumber?.type || ""]
            )}
            data-testid={"Payment__Type__ACH_Routing_Number_Input"}
          />
        )}
      />
    </div>
  );
};
