import { Payment3_paymentmethod_coordinatorJSClient } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { CscPaymentsJSClient } from "@ftdr/cscpayments-js-client";
import { Stripe } from "@stripe/stripe-js";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import { CardProcessorType, PaymentMethods, PAYMENTS_METHODS } from "../const";
import { usePaymentContext } from "../contexts/payment-context";
import { setInternalContextState } from "../contexts/payment-context/state";
import { AchBankProcessor } from "./payment-processors/ach-bank-processor";
import { StripeCardProcessor } from "./payment-processors/stripe-card-processor";
import {
  PaymentProcessorClientInternalRef,
  ProcessPayment,
  ProcessPaymentACHRequestData,
  ProcessPaymentStripeRequestData,
  ProcessPaymentRequestData,
} from "./payment-processors/types";
import { PaymentsUI, PaymentsUIProps } from "./payments-ui";

export type PaymentComponentState = {
  activePaymentMethod?: PaymentMethods;
  cardProcessorResponse?: Record<string, unknown>;
  bankProcessorResponse?: Record<string, unknown>;
};

export type PaymentsComponentOwnProps = {
  // ------------------- Backend Integration Related Props -------------------
  /**
   * Backend client for integrating with Payment method MS
   */
  paymentMethodCoordinatorClient?: Payment3_paymentmethod_coordinatorJSClient;
  /**
   * Backend client for integrating with csc payment method MS
   */
  cscJsClient?: CscPaymentsJSClient;
  // ------------------- Business Logic Related Value Props -------------------
  /**
   * @deprecated
   * When using this component you can set what payment processor will be used for
   * the card payments
   */
  cardPaymentProcessor?: CardProcessorType;
  // ------------------- Business Logic Related Event Props -------------------
};

export type PaymentsComponentToIQUIPassthroughProps = Pick<
  PaymentsUIProps,
  | "className"
  | "bankProcessorIcon"
  | "cardProcessorIcon"
  | "paymentMethodContainerClassName"
>;

export type PaymentsComponentProps = PaymentsComponentOwnProps &
  PaymentsComponentToIQUIPassthroughProps;

export type PaymentsComponentExposedApi = {
  processPayment: ProcessPayment<ProcessPaymentRequestData>;
  getClient: (
    paymentMethod: PaymentMethods
  ) => Record<string, unknown> | undefined;
  getCurrentActivePaymentMethod: () => PaymentMethods | undefined;
  setOpenPaymentMethod: (
    activePaymentMethod?: PaymentMethods
  ) => void | undefined;
};

export const PaymentsComponent = forwardRef<
  PaymentsComponentExposedApi,
  PaymentsComponentProps
>(
  (
    {
      // IQ UI passthrough props
      className,
      paymentMethodCoordinatorClient,
      cscJsClient,
      paymentMethodContainerClassName,
      bankProcessorIcon,
      cardProcessorIcon,
    },
    ref
  ) => {
    const achBankProcessorRef = useRef<
      PaymentProcessorClientInternalRef<ProcessPaymentACHRequestData>
    >(null);
    const stripeProcessorRef = useRef<
      PaymentProcessorClientInternalRef<ProcessPaymentStripeRequestData, Stripe>
    >(null);

    /**
     * Internal state
     */
    const { state, dispatch } = usePaymentContext();

    const handlePaymentTypeChange = useCallback(
      (activePaymentMethod: PaymentMethods) => {
        dispatch(
          setInternalContextState({
            ...state.internalState,
            activePaymentMethod: activePaymentMethod,
          })
        );
      },
      [state.internalState, dispatch]
    );

    useImperativeHandle(ref, () => ({
      processPayment: (requestData) => {
        if (
          state.internalState?.activePaymentMethod ===
          PAYMENTS_METHODS.CREDIT_CARD
        ) {
          return stripeProcessorRef.current?.processPayment(
            requestData as ProcessPaymentStripeRequestData
          );
        } else {
          return achBankProcessorRef.current?.processPayment(
            requestData as ProcessPaymentACHRequestData
          );
        }
      },
      getClient: () => {
        return achBankProcessorRef.current?.getClient();
      },
      getCurrentActivePaymentMethod: () =>
        state.internalState?.activePaymentMethod,
      setOpenPaymentMethod: (activePaymentMethod?: PaymentMethods) => {
        dispatch(
          setInternalContextState({
            ...state.internalState,
            activePaymentMethod,
          })
        );
      },
    }));
    return (
      <PaymentsUI
        // passthrough props
        className={className}
        handlePaymentTypeChange={handlePaymentTypeChange}
        bankProcessorIcon={bankProcessorIcon}
        cardProcessorIcon={cardProcessorIcon}
        paymentMethodContainerClassName={paymentMethodContainerClassName}
        // props based by internal state
        internalState={state.internalState}
        cardProcessor={
          <StripeCardProcessor
            data-testid={`Stripe_Card_Processor`}
            ref={stripeProcessorRef}
            paymentMethodCoordinatorClient={paymentMethodCoordinatorClient}
          />
        }
        bankProcessor={
          <AchBankProcessor
            data-testid={`Ach_Bank_Processor`}
            config={{}}
            ref={achBankProcessorRef}
            cscJsClient={cscJsClient}
          />
        }
      />
    );
  }
);

PaymentsComponent.displayName = "PaymentsComponent";
