import {
  ProtobufAxiosRequestConfig,
  ProtobufHttpClient,
  REQUEST_CONFIG_NAMESPACE,
} from "@ftdr/http-utils";
import { MicroService } from "@ftdr/micro-services-shared";
import { AxiosResponse } from "axios";
import { retry } from "ts-retry-promise";
import { payment3_paymentmethod_coordinatorpb } from "./protobuf-models/payment3_paymentmethod_coordinator-ms-protobuf-models-es6";

export class Payment3_paymentmethod_coordinatorJSClient extends MicroService {
  static serviceName = "payment-method-coordinator";

  /**
   * The use of the _definite assignment assertion_ (!) is required here
   * because we're neither initializing this property here, not is it apparent
   * to TS that is it being initialized in the constructor body.
   *
   * @see {@link https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-7.html#strict-class-initialization}
   */
  protected httpClient!: ProtobufHttpClient;

  constructor(baseUrl: string, httpClient: ProtobufHttpClient) {
    super(baseUrl, httpClient);
    this.httpClient = httpClient;
  }

  /**
   * Create a payment method
   *
   * @param requestData  Object with CreatePaymentMethodRequest fields to be created
   * @returns Object with CreatePaymentMethodResponse fields
   */
  createPaymentMethod = async (
    requestData: payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest,
    retryCount = 0,
    backoff = 0
  ): Promise<
    AxiosResponse<payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodResponse>
  > => {
    const requestMessage = payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest.create(
      requestData
    );
    const requestConfig: ProtobufAxiosRequestConfig<
      payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest,
      payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse
    > = {
      [REQUEST_CONFIG_NAMESPACE]: {
        requestProtobufModel:
          payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRequest,
        responseProtobufModel:
          payment3_paymentmethod_coordinatorpb.CreatePaymentMethodResponse,
      },
    };
    return retry(
      () =>
        Promise.resolve(
          this.httpClient.post(
            this.makeServiceUrl("/v1/payment-method"),
            requestMessage,
            requestConfig
          )
        ),
      { retries: retryCount, delay: backoff }
    );
  };

  /**
   * Create payment method registration.
   *
   * @param requestData  Object with CreatePaymentMethodRegistrationRequest fields to be created
   * @returns Object with CreatePaymentMethodRegistrationResponse fields
   */
  createPaymentMethodRegistration = async (
    requestData: payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest,
    retryCount = 0,
    backoff = 0
  ): Promise<
    AxiosResponse<payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationResponse>
  > => {
    const requestMessage = payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest.create(
      requestData
    );
    const requestConfig: ProtobufAxiosRequestConfig<
      payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest,
      payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse
    > = {
      [REQUEST_CONFIG_NAMESPACE]: {
        requestProtobufModel:
          payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationRequest,
        responseProtobufModel:
          payment3_paymentmethod_coordinatorpb.CreatePaymentMethodRegistrationResponse,
      },
    };
    return retry(
      () =>
        Promise.resolve(
          this.httpClient.post(
            this.makeServiceUrl("/v1/payment-method-registration"),
            requestMessage,
            requestConfig
          )
        ),
      { retries: retryCount, delay: backoff }
    );
  };
}
