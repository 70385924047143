import {
  IconCreditCard,
  useAppContext,
} from "@ftdr/blueprint-components-react";
import React, { useMemo } from "react";
import { BankAccountIcon } from "../assets/icons";
import {
  AvailablePaymentMethods,
  defaultAvailablePaymentMethods,
  PaymentMethods,
  PAYMENTS_METHODS,
} from "../const";
import { PaymentTypeItem } from "./payment-type-item";
import { PaymentComponentState } from "./payments-component";
import { usePaymentContext } from "../contexts/payment-context";
import { generateAvailablePaymentMethodsArray } from "../utils";

export type PaymentsUIProps = {
  // ------------------------- UI Related Value Props -------------------------

  /**
   * Use this prop to apply custom CSS styling to the root element of the MFE.
   */
  className?: string;
  paymentMethodContainerClassName?: string;
  /**
   * Payments children props
   */
  cardProcessor: JSX.Element;
  bankProcessor: JSX.Element;

  cardProcessorIcon?: JSX.Element;
  bankProcessorIcon?: JSX.Element;
  // ----------------------- UI Related Event Props -----------------------
  internalState?: PaymentComponentState;

  handlePaymentTypeChange: (activePaymentMethod: PaymentMethods) => void;
  availablePaymentMethods?: AvailablePaymentMethods;
};
export const PaymentsUI: React.FC<PaymentsUIProps> = ({
  className,
  paymentMethodContainerClassName,
  cardProcessor,
  bankProcessor,
  cardProcessorIcon = (
    <IconCreditCard data-testid={`Card_Processor_Icon`} size="32" />
  ),
  bankProcessorIcon = <BankAccountIcon />,
  internalState,
  handlePaymentTypeChange,
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();

  const { availablePaymentMethods } = usePaymentContext();

  const renderItems = useMemo(
    () => ({
      CREDIT_CARD: {
        paymentProcessor: cardProcessor,
        icon: cardProcessorIcon,
      },
      BANK_ACCOUNT: {
        paymentProcessor: bankProcessor,
        icon: bankProcessorIcon,
      },
    }),
    [cardProcessor, cardProcessorIcon, bankProcessor, bankProcessorIcon]
  );

  const availablePaymentMethodsArray = useMemo(() => {
    try {
      return generateAvailablePaymentMethodsArray(
        availablePaymentMethods,
        renderItems
      );
    } catch (e) {
      console.error("Wrong availablePaymentMethods property is passed");
      return generateAvailablePaymentMethodsArray(
        defaultAvailablePaymentMethods,
        renderItems
      );
    }
  }, [availablePaymentMethods, renderItems]);

  return (
    <div className={`block ${className}`}>
      <div className={`flex flex-col ${paymentMethodContainerClassName}`}>
        {availablePaymentMethodsArray.map((paymentMethod, index) => (
          <PaymentTypeItem
            key={index}
            activePaymentMethod={internalState?.activePaymentMethod}
            paymentTypeLabel={localizedText(
              `PAYMENT_TYPE_LABEL_${paymentMethod.name}`
            )}
            icon={paymentMethod.icon}
            paymentType={PAYMENTS_METHODS[paymentMethod.name]}
            handleOnChange={handlePaymentTypeChange}
            paymentTypeRenderItem={paymentMethod.paymentProcessor}
            data-testid={`Payment__Type__${paymentMethod}`}
          />
        ))}
      </div>
    </div>
  );
};
