import { CardNumberElement } from "@stripe/react-stripe-js";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { StripeFormDetails } from "../../stripe-form";
import { StripeElementStyle } from "@stripe/stripe-js";

export interface CardNumberInputProps {
  styles?: StripeElementStyle;
}

export const CardNumberInput: FC<CardNumberInputProps> = ({ styles }) => {
  const { setError, clearErrors } = useFormContext<StripeFormDetails>();
  return (
    <CardNumberElement
      data-testid={`Payment__Type__StripeElement_CardNumber`}
      options={{
        style: { ...styles },
        disabled: false,
        showIcon: true,
        iconStyle: "solid",
      }}
      onChange={(event) => {
        event.error?.message
          ? setError("cardNumberErrorInput", {
              type: event.error?.type,
              message: event.error?.message,
            })
          : clearErrors(["cardNumberErrorInput"]);
      }}
    />
  );
};
