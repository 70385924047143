import { StripeElementStyle } from "@stripe/stripe-js";

export const cardStyle: StripeElementStyle = {
  base: {
    "color": "#32325d",
    "fontFamily": "proxima-nova, sans-serif",
    "fontSize": "16px",
    "backgroundColor": "white",
    "::placeholder": {
      color: "#aaaaaa",
    },
    ":disabled": {
      color: "#9E9E9E",
    },
  },
  invalid: {
    color: "#EF372A",
    iconColor: "#EF372A",
  },
};
